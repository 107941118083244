import { TextField } from '@mui/material';
import { useField } from 'formik';
import { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';

interface EasySuggestOption {
  label: string;
  value: string;
}

export const EasySuggest = ({
  name,
  options,
  label,
  required,
}: {
  name: string;
  options?: EasySuggestOption[];
  label: string;
  required?: boolean;
}) => {
  const [inputValue, setInputValue] = useState('');

  const [field, meta, helpers] = useField(name);
  const { error } = meta;
  const { setValue, setTouched } = helpers;
  const { value } = field;

  return (
    <Autocomplete
      disableClearable
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue?.value, true);
        setTouched(true);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onClose={() => {
        setTouched(true);
      }}
      getOptionLabel={optionOrValue => {
        if (typeof optionOrValue === 'string') {
          return (
            options?.find(option => option.value === optionOrValue)?.label || ''
          );
        } else {
          return optionOrValue?.label || '';
        }
      }}
      isOptionEqualToValue={(one, other) => {
        return one.value === other;
      }}
      options={options || []}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          required={required}
          fullWidth
          margin="normal"
          error={Boolean(error)}
          helperText={error}
        />
      )}
    />
  );
};

import { Collapse, List, ListItem, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { niceFormat } from '../../../utils/formatter';
import { ProgressCycleInnerProps } from '../types';

const useStyles = makeStyles(theme => ({
  nested: { borderLeft: '1px solid grey', background: 'eee' },
  icon: { height: 13, verticalAlign: 'text-top' },
}));

export const ProgressCycle = ({ cycle, round }: ProgressCycleInnerProps) => {
  const classes = useStyles();
  const [listOpen, setListOpen] = useState(false);

  const showExpand =
    cycle!.requestedAt ||
    (cycle.published && cycle!.publishedAt) ||
    Boolean(cycle!.closedAt);

  return (
    <>
      <ListItem disableGutters onClick={() => setListOpen(!listOpen)}>
        <ListItemText
          primary={
            <>
              {niceFormat(cycle!.createdAt)} | {round}
              {showExpand ? (
                listOpen ? (
                  <ExpandLessIcon className={classes.icon} />
                ) : (
                  <ExpandMoreIcon className={classes.icon} />
                )
              ) : (
                ''
              )}
            </>
          }
        />
      </ListItem>
      {showExpand && (
        <Collapse
          in={listOpen}
          timeout="auto"
          unmountOnExit
          className={classes.nested}
        >
          <List component="span" dense disablePadding>
            {cycle!.requestedAt && (
              <ListItem>
                <ListItemText
                  primary={`${niceFormat(cycle!.requestedAt)} | nachgefordert`}
                />
              </ListItem>
            )}
            {cycle!.published && (
              <ListItem>
                <ListItemText
                  primary={`${niceFormat(cycle!.publishedAt)} | gestartet`}
                />
              </ListItem>
            )}
            {cycle!.closedAt && (
              <ListItem>
                <ListItemText
                  primary={`${niceFormat(cycle!.closedAt)} | ${
                    cycle!.score > 0
                      ? 'positiv'
                      : cycle!.score < 0
                        ? 'negativ'
                        : 'beendet'
                  }`}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}
    </>
  );
};

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Formik, FormikConfig, FormikProps } from 'formik';
import { EasyTextfield } from '../../../components/Fields/EasyTextfield';
import { ContactInput, Enum_Contact_Gender } from 'gql/graphql';
import { ContactFormInnerProps, ContactFormValues } from '../types';
import { ContactFormSchema } from './validation';

const useStyles = makeStyles(theme => ({
  subHeadline: { margin: '50px 0 20px 0' },
  form: {},
  textField: {},
  contactContainer: { margin: '60px 0 50px 0' },
  confirmationContainer: { marginTop: 40 },
  confirmationButton: { marginRight: 8 },
}));

export const ContactForm = ({
  open,
  handleClose,
  ...rest
}: ContactFormInnerProps & FormikConfig<ContactFormValues>) => {
  const classes = useStyles();

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={ContactFormSchema}
      validateOnChange={false}
      {...rest}
    >
      {({ handleSubmit }: FormikProps<ContactInput>) => (
        <Dialog open={open} onClose={() => handleClose()}>
          <DialogTitle>Bearbeiten</DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <EasyTextfield
                  name="gender"
                  label="Anrede"
                  margin="normal"
                  fullWidth
                  select
                >
                  <MenuItem value={Enum_Contact_Gender.Male}>Herr</MenuItem>
                  <MenuItem value={Enum_Contact_Gender.Female}>Frau</MenuItem>
                </EasyTextfield>
              </Grid>
              <Grid item xs={12} sm={6}>
                <EasyTextfield
                  name="title"
                  label="Titel"
                  margin="normal"
                  fullWidth
                  select
                >
                  <MenuItem value="none">
                    <em>-</em>
                  </MenuItem>
                  <MenuItem value="dr">Dr.</MenuItem>
                  <MenuItem value="prof">Prof.</MenuItem>
                </EasyTextfield>
              </Grid>
              <Grid item xs={12} sm={6}>
                <EasyTextfield
                  id="form-firstname"
                  name="firstname"
                  label="Vorname"
                  className={classes.textField}
                  required
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <EasyTextfield
                  id="form-lastname"
                  name="lastname"
                  label="Nachname"
                  className={classes.textField}
                  margin="normal"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <EasyTextfield
                  id="form-email"
                  name="email"
                  label="E-Mail"
                  type="email"
                  className={classes.textField}
                  margin="normal"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <EasyTextfield
                  id="form-phone"
                  name="phone"
                  label="Telefon"
                  className={classes.textField}
                  margin="normal"
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleSubmit();
              }}
              color="primary"
              variant="contained"
            >
              Speichern
            </Button>
            <Button onClick={handleClose} variant="contained">
              Abbrechen
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

import axios from 'axios';
import { LoginFormValues } from '../../components/Auth/types';
import { HistoryItem } from '../../components/History/types';
import { LOCAL_STORAGE_TOKEN } from '../constants';

const storageToken = localStorage.getItem(LOCAL_STORAGE_TOKEN);

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
if (storageToken) {
  axios.defaults.headers.common.Authorization = `Bearer ${storageToken}`;
}

export const addApiToken = (token: string) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const removeApiToken = () => {
  delete axios.defaults.headers.common.Authorization;
};

export const api = {
  updateAllStates: () => {
    return axios.post('/courses/update-state', { all: true });
  },
  login: (loginValues: LoginFormValues) => {
    return axios.post('/auth/local', loginValues);
  },
  getHistory: (courseId: string) => {
    return axios.get<HistoryItem[]>(`/courses/${courseId}/history`);
  },
  loadFile: (path: string) => {
    return axios.get<Blob>(`${path}`, { responseType: 'blob' });
  },
  getMe: () => {
    return axios.get<any>(`/users/me`);
  },
};

import { IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { formatToName } from '../../utils/formatter';
import { useAuth } from '../Auth/Auth';

const useStyles = makeStyles(theme => ({
  userContainer: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
      marginBottom: 20,
    },
  },
  user: {
    display: 'inline',
    marginRight: 5,
  },
}));

export const User = () => {
  const classes = useStyles();
  const auth = useAuth();

  return (
    <div className={classes.userContainer}>
      <Typography component="span" className={classes.user}>
        {formatToName(auth.me.info)}
      </Typography>
      <IconButton
        onClick={() => auth.logout()}
        data-testid="logout"
        size="large"
      >
        <ExitToAppIcon />
      </IconButton>
    </div>
  );
};

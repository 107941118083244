import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { client } from './apollo-client';
import { theme } from './theme';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface ProviderProps {
  children: React.ReactNode;
}

export const Provider = ({ children }: ProviderProps) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </ThemeProvider>
  </StyledEngineProvider>
);

import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  subheadline: { display: 'inline', paddingLeft: 10 },
  providerHeadline: {
    marginBottom: 30,
  },
}));

export const ProviderHeadline = ({
  name,
  category,
}: {
  name: string;
  category: string;
}) => {
  const classes = useStyles();
  return (
    <Typography
      variant="h3"
      color="primary"
      className={classes.providerHeadline}
      data-testid="headline"
    >
      {name}
      <Typography
        variant="h6"
        color="textPrimary"
        component="span"
        className={classes.subheadline}
      >
        {category}
      </Typography>
    </Typography>
  );
};

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import cx from 'classnames';
import { EasyCheckbox } from '../../../components/Fields/EasyCheckbox';
import { EasyTextfield } from '../../../components/Fields/EasyTextfield';
import { formatToName } from '../../../utils/formatter';
import { toggleInArray } from '../../../utils/misc/list';
import { EditCourseGeneralInnerProps } from '../types';

const useStyles = makeStyles(theme => ({
  confirmationContainer: { marginTop: 40 },
  confirmationButton: { marginRight: 8 },
  nameGroup: { flexDirection: 'row' },
  nameControl: { minWidth: 285 },
  active: { color: theme.palette.secondary.main },
}));

export const EditCourseGeneral = ({
  courseData,
  evaluators,
  formik: { values, setFieldValue },
}: EditCourseGeneralInnerProps) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <EasyCheckbox name="course.enabled" label="Kurs ist aktiv" />
      </Grid>

      {courseData.provider &&
        courseData.provider.contacts &&
        courseData.provider.contacts.length > 0 && (
          <Grid item xs={12}>
            <EasyTextfield
              name="course.contact"
              label="Ansprechpartner"
              fullWidth
              select
              margin="normal"
            >
              {courseData.provider.contacts.map(contact => (
                <MenuItem key={contact.id} value={contact.id}>
                  {formatToName(contact)}
                </MenuItem>
              ))}
            </EasyTextfield>
          </Grid>
        )}

      <Grid item xs={12}>
        <EasyTextfield
          id="form-comment"
          name="course.comment"
          label="Kommentar"
          multiline
          rows={3}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl margin="normal">
          <FormLabel>Prüfer</FormLabel>
          <FormGroup className={classes.nameGroup}>
            {evaluators &&
              [...evaluators]
                .sort((x, y) =>
                  x.user!.info!.lastname > y.user!.info!.lastname ? 1 : -1,
                )
                .map(evaluator => {
                  const isCandidate = evaluator.categories!.some(
                    category => category!.id === courseData.category!.id,
                  );

                  return (
                    <FormControlLabel
                      id={`form-evaluator_${evaluator.id}`}
                      key={evaluator.id}
                      classes={{
                        label: cx({
                          [classes.active]: isCandidate,
                        }),
                      }}
                      control={
                        <Checkbox
                          checked={
                            values.course.evaluators!.indexOf(evaluator.id) > -1
                          }
                          name="course.evaluators"
                          color={isCandidate ? 'secondary' : 'default'}
                          onChange={e => {
                            setFieldValue(
                              'course.evaluators',
                              toggleInArray(
                                values.course.evaluators!,
                                evaluator.id,
                              ),
                            );
                          }}
                        />
                      }
                      label={formatToName(evaluator.user!.info!)}
                      className={classes.nameControl}
                    />
                  );
                })}
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Theme,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { CriterionCycle } from '../../pages/course/types';
import { formatToName } from '../../utils/formatter';
import { InfoAvatar } from '../InfoAvatar/InfoAvatar';

interface ProtocolCriterionInnerProps extends Omit<AccordionProps, 'children'> {
  label: string;
  cycles: CriterionCycle[];
}

const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
  gap: {
    marginLeft: spacing(1),
  },
}));

export const ProtocolCriterion = ({
  label,
  cycles,
  ...other
}: ProtocolCriterionInnerProps) => {
  const classes = useStyles();

  return (
    <Accordion {...other}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{label}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List disablePadding dense>
          {cycles.map((cycle, cycleIndex) => (
            <React.Fragment key={cycle.id}>
              <ListSubheader disableGutters data-testid="protocolCycle">
                {cycleIndex + 1}. Runde
              </ListSubheader>
              {cycle.criterionEvaluations.length === 0 && (
                <ListItem>
                  <ListItemText primary="Aktuell gibt es noch keine Bewertungen" />
                </ListItem>
              )}
              {cycle.criterionEvaluations
                .sort((first, next) =>
                  first.evaluator!.user!.info!.lastname <
                  next.evaluator!.user!.info!.lastname
                    ? -1
                    : 1,
                )
                .map(evaluation => (
                  <ListItem
                    key={evaluation.evaluator.id}
                    disableGutters
                    data-testid="protocolEvaluation"
                  >
                    <InfoAvatar
                      status={
                        evaluation.score === 1
                          ? 'yes'
                          : evaluation.score === -1
                            ? 'no'
                            : 'open'
                      }
                      label={evaluation.evaluator!.short}
                      data-testid="protocolAvatar"
                    />
                    <ListItemText
                      className={classes.gap}
                      primary={formatToName(evaluation.evaluator!.user!.info!)}
                      secondary={evaluation.comment}
                      secondaryTypographyProps={
                        {
                          'data-testid': 'protocolComment',
                          style: { whiteSpace: 'pre-wrap' },
                        } as any
                      }
                    />
                  </ListItem>
                ))}
            </React.Fragment>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

import { Avatar, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React from 'react';
import { InfoAvatarProps } from './types';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: 2,
    display: 'inline-flex',
  },
  avatarYes: {
    background: theme.palette.yes.main,
  },
  avatarNo: {
    background: theme.palette.no.main,
  },
}));

export const InfoAvatar = ({
  status,
  label,
  tooltip,
  ...other
}: InfoAvatarProps) => {
  const classes = useStyles();

  const avatar = (
    <Avatar
      {...other}
      component="span"
      className={classnames(
        {
          [classes.avatarYes]: status === 'yes',
          [classes.avatarNo]: status === 'no',
        },
        classes.avatar,
      )}
    >
      {label}
    </Avatar>
  );

  return tooltip ? <Tooltip title={tooltip}>{avatar}</Tooltip> : avatar;
};

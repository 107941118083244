import { CircularProgress, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Base } from '../../components/Base/Base';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { ContactInput } from 'gql/graphql';
import { handleErrors } from '../../utils/formatter';
import { ProviderForm } from './helper/ProviderForm';
import { ProviderFormValues } from './types';
import { CUSTOM_UPDATE_PROVIDER_MUTATION, GET_PROVIDER_QUERY } from './graphql';
import { useMutation, useQuery } from '@apollo/client';

export const ProviderEditPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data, loading, error } = useQuery(GET_PROVIDER_QUERY, {
    // @ts-ignore
    variables: { id },
    skip: !id,
  });

  const [customUpdateProvider] = useMutation(CUSTOM_UPDATE_PROVIDER_MUTATION);
  const [errorMutation, setErrorMutation] = useState('');

  const initialValues: ProviderFormValues | undefined | null = data &&
    data.provider && {
      provider: {
        city: data.provider.city,
        enabled: data.provider.enabled,
        homepage: data.provider.homepage,
        name: data.provider.name,
        phone: data.provider.phone,
        street: data.provider.street,
        streetnumber: data.provider.streetnumber,
        zip: data.provider.zip,
      },
      contacts: data.provider.contacts
        ? data.provider.contacts.reduce<ContactInput[]>(
            (acc, contact) =>
              contact
                ? [
                    ...acc,
                    {
                      email: contact.email,
                      phone: contact.phone,
                      title: contact.title,
                      gender: contact.gender,
                      firstname: contact.firstname,
                      lastname: contact.lastname,
                      helperId: contact.id,
                    },
                  ]
                : acc,
            [],
          )
        : [],
    };

  return (
    <Base>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <>
          <h1>ERROR</h1>
          <Typography>{error.message}</Typography>
        </>
      ) : (
        initialValues && (
          <>
            <PageHeader>Kursanbieter bearbeiten</PageHeader>
            <ProviderForm
              type="edit"
              initialValues={initialValues}
              onSubmit={async (values, { setSubmitting }) => {
                if (id) {
                  try {
                    await customUpdateProvider({
                      variables: {
                        id,
                        provider: values.provider,
                        contacts: values.contacts,
                      },
                    });
                    navigate('/provider');
                  } catch (e) {
                    const errorMessage = handleErrors(e);
                    setErrorMutation(errorMessage);
                  }
                }
              }}
            />
            {errorMutation && (
              <Typography color="error">{errorMutation}</Typography>
            )}
          </>
        )
      )}
    </Base>
  );
};

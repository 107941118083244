import React from 'react';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { IconButtonProps } from '@mui/material/IconButton';

interface IconButtonLinkProps extends IconButtonProps {
  to: string;
  replace?: boolean;
}

export const IconButtonLink = (props: IconButtonLinkProps) => (
  <IconButton {...props} component={Link as any} size="large" />
);

import { CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import MaterialTable, { MTableBodyRow } from '@material-table/core';
import React from 'react';
import { Base } from '../../components/Base/Base';
import { FabLink } from '../../components/FabButtonLink/FabLink';
import { IconButtonLink } from '../../components/IconButtonLink/IconButtonLink';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { GET_PROVIDERS_QUERY } from './graphql';
import { useQuery } from '@apollo/client';

export const ProviderOverviewPage = () => {
  const { data, loading, error } = useQuery(GET_PROVIDERS_QUERY);

  return (
    <Base>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <h1>ERROR</h1>
      ) : (
        data &&
        data.providers && (
          <>
            <PageHeader>Kursanbieter</PageHeader>
            <MaterialTable
              columns={[
                {
                  title: 'Name',
                  field: 'name',
                  cellStyle: (data, rowData) => ({
                    textDecoration: rowData.enabled ? 'none' : 'line-through',
                  }),
                  defaultSort: 'asc',
                  customSort: (a, b) =>
                    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
                },
                {
                  filtering: false,
                  cellStyle: { textAlign: 'right', width: 100 },
                  render: rowData => (
                    <IconButtonLink
                      to={`/provider/edit/${rowData.id}`}
                      data-testid="editProvider"
                    >
                      <EditIcon fontSize="small" />
                    </IconButtonLink>
                  ),
                },
              ]}
              data={data.providers}
              localization={{
                body: { emptyDataSourceMessage: 'Keine Anbieter vorhanden' },
              }}
              options={{
                paging: true,
                pageSize: 50,
                pageSizeOptions: [],
                filtering: true,
                toolbar: false,
                emptyRowsWhenPaging: false,
                sorting: true,
              }}
              components={{
                Row: props => <MTableBodyRow {...props} />,
                Container: props => (
                  <div style={{ position: 'relative' }}>
                    <FabLink
                      to="/provider/add"
                      size="small"
                      color="primary"
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        zIndex: 11,
                      }}
                      data-testid="addProvider"
                    >
                      <AddIcon />
                    </FabLink>
                    <div {...props} />
                  </div>
                ),
              }}
            />
          </>
        )
      )}
    </Base>
  );
};

import { List, ListItemButton, ListItemText, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { api } from '../../utils/api/api';
import { stateToString } from '../../utils/formatter';
import { HistoryItem } from './types';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  subHeadline: { margin: '50px 0 20px 0' },
}));

export const History = ({ courseId }: { courseId: string }) => {
  const [history, setHistory] = useState<HistoryItem[] | undefined>(undefined);
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    api
      .getHistory(courseId)
      .then(response => {
        setHistory(response.data);
      })
      .catch(error => {
        console.log('error', error);
      });
  }, [courseId]);

  if (history === undefined || (history && history.length <= 1)) {
    return null;
  }

  return (
    <>
      <Typography component="h2" variant="h5" className={classes.subHeadline}>
        Historie
      </Typography>
      <List dense>
        {history.map(historyItem => (
          <ListItemButton
            onClick={() => navigate(`/course/view/${historyItem.id}`)}
            key={historyItem.id}
            selected={historyItem.id === courseId}
            data-testid="history"
          >
            <ListItemText
              primary={`${historyItem.provider.name} - ${historyItem.category.name}`}
              secondary={stateToString(historyItem.state)}
            />
          </ListItemButton>
        ))}
      </List>
    </>
  );
};

import { graphql } from 'gql/gql';

export const GET_COURSES_QUERY = graphql(`
  query GetCourses {
    courses(limit: 500) {
      id
      state
      enabled
      processes {
        id
        confirmedAt
      }
      provider {
        id
        name
      }
      category {
        id
        name
      }
    }
  }
`);

export const GET_COURSE_QUERY = graphql(`
  query GetCourse($id: ID!) {
    documenttypes {
      id
      name
    }
    course(id: $id) {
      id
      enabled
      createdAt
      comment
      state
      contact {
        id
      }
      provider {
        id
        name
        enabled
        street
        city
        zip
        streetnumber
        homepage
        contacts {
          id
          firstname
          lastname
          phone
          email
          title
          gender
        }
      }
      processes {
        id
        createdAt
        paidAt
        confirmedAt
        certification
        cycles {
          id
          createdAt
          requestedAt
          published
          publishedAt
          cancelled
          closedAt
          score
          cycleevaluations {
            id
            score
            comment
            closedAt
            criterionevaluations
            evaluator {
              id
              short
              user {
                id
                email
                info {
                  id
                  firstname
                  lastname
                  title
                  gender
                }
              }
            }
          }
          documents {
            id
            file {
              id
              url
              name
              updatedAt
            }
            documenttype {
              id
              name
            }
          }
        }
      }
      evaluators {
        id
        short
        user {
          id
          email
          info {
            id
            firstname
            lastname
            title
            gender
          }
        }
      }
      category {
        id
        name
        criteria {
          id
          name
        }
      }
    }
    criteria {
      id
      name
    }
  }
`);

export const GET_CYCLEEVALUATION_QUERY = graphql(`
  query GetCycleevaluation($id: ID!) {
    cycleevaluation(id: $id) {
      id
      score
      evaluator {
        id
      }
      cycle {
        id
        published
        cancelled
        closedAt
        score
        process {
          id
          course {
            id
          }
        }
      }
    }
  }
`);

//  TODO: (where: { user: { blocked_ne: true } }) hack, because blocked: false does not work yet
// TODO: where: { user: { blocked: false } } does not work on this GraphQL Version oviously
export const GET_ENABLED_EVALUATORS_QUERY = graphql(`
  query GetEnabledEvaluators {
    evaluators(limit: 500, where: { user: { blocked: false } }) {
      id
      categories {
        id
      }
      user {
        id
        blocked
        info {
          firstname
          lastname
          title
          gender
        }
      }
    }
  }
`);

export const GET_CREATE_COURSE_QUERY = graphql(`
  query GetCreateCourse {
    providers(limit: 500, where: { enabled: true }) {
      id
      name
    }
    categories {
      id
      name
    }
  }
`);

export const GET_CYCLEEVALUATIONS = graphql(`
  query GetCycleevaluations($evaluatorId: ID!) {
    cycleevaluations(limit: 500, where: { evaluator: $evaluatorId }) {
      id
      closedAt
      cycle {
        id
        process {
          id
          course {
            id
          }
        }
      }
    }
  }
`);

export const GET_MY_EVALUATIONS = graphql(`
  query GetMyEvaluations {
    myEvaluations {
      cycleevaluation
      open
      course
    }
  }
`);

export const UPDATE_COURSE_MUTATION = graphql(`
  mutation UpdateCourse($id: ID!, $course: editCourseInput) {
    updateCourse(input: { where: { id: $id }, data: $course }) {
      course {
        id
      }
    }
  }
`);

export const CREATE_COURSE_MUTATION = graphql(`
  mutation CreateCourse($course: CourseInput) {
    createCourse(input: { data: $course }) {
      course {
        id
      }
    }
  }
`);

export const UPLOAD_FILE_MUTATION = graphql(`
  mutation UploadFile($file: Upload!, $ref: String) {
    upload(file: $file, ref: $ref) {
      id
      name
      mime
      url
      __typename
    }
  }
`);

export const UPDATE_PROCESS_MUTATION = graphql(`
  mutation UpdateProcess($id: ID!, $process: editProcessInput) {
    updateProcess(input: { where: { id: $id }, data: $process }) {
      process {
        id
      }
    }
  }
`);

export const UPDATE_CYCLE_MUTATION = graphql(`
  mutation UpdateCycle($id: ID!, $cycle: editCycleInput) {
    updateCycle(input: { where: { id: $id }, data: $cycle }) {
      cycle {
        id
      }
    }
  }
`);

export const CREATE_PROCESS_MUTATION = graphql(`
  mutation CreateProcess($process: ProcessInput) {
    createProcess(input: { data: $process }) {
      process {
        id
      }
    }
  }
`);

export const CREATE_CYCLE_MUTATION = graphql(`
  mutation CreateCycle($cycle: CycleInput) {
    createCycle(input: { data: $cycle }) {
      cycle {
        id
      }
    }
  }
`);

export const UPDATE_CYCLEEVALUATION_MUTATION = graphql(`
  mutation UpdateCycleevaluation(
    $id: ID!
    $cycleevaluation: editCycleevaluationInput
  ) {
    updateCycleevaluation(
      input: { where: { id: $id }, data: $cycleevaluation }
    ) {
      cycleevaluation {
        id
      }
    }
  }
`);

import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles({
  header: {
    margin: '30px 0 30px 0',
  },
});

type PageHeaderProps = {
  children: React.ReactNode;
};

export const PageHeader = ({ children }: PageHeaderProps) => {
  const classes = useStyles();

  return (
    <Typography component="h1" variant="h4" className={classes.header}>
      {children}
    </Typography>
  );
};

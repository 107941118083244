import * as Yup from 'yup';
import { email, mandatory } from '../../../utils/formatter';

export const ProviderFormSchema = Yup.object().shape({
  provider: Yup.object().shape({
    city: Yup.string().required(mandatory),
    name: Yup.string().required(mandatory),
    street: Yup.string().required(mandatory),
    streetnumber: Yup.string().required(mandatory),
    zip: Yup.string().required(mandatory),
  }),
});

export const ContactFormSchema = Yup.object().shape({
  firstname: Yup.string().required(mandatory),
  lastname: Yup.string().required(mandatory),
  email: Yup.string().email(email).required(mandatory),
});

import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material';
import { Form, Formik, FormikConfig, FormikProps } from 'formik';
import { ButtonLink } from '../../../components/ButtonLink/ButtonLink';
import { EasyCheckbox } from '../../../components/Fields/EasyCheckbox';
import { EasyTextfield } from '../../../components/Fields/EasyTextfield';
import { toggleInArray } from '../../../utils/misc/list';
import { EvaluatorFormProps, EvaluatorFormValues } from '../types';
import { GET_CATEGORIES } from '../graphql';
import { useQuery } from '@apollo/client';
import { Enum_Contact_Gender } from 'gql/graphql';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  subHeadline: { margin: '50px 0 20px 0' },
  confirmationContainer: { marginTop: 40 },
  confirmationButton: { marginRight: 8 },
  checkboxControl: { marginTop: 30 },
}));

export const EvaluatorForm = ({
  type,
  ...rest
}: EvaluatorFormProps & FormikConfig<EvaluatorFormValues>) => {
  const classes = useStyles();
  const { data } = useQuery(GET_CATEGORIES);

  return (
    <Formik validateOnChange={false} isInitialValid {...rest}>
      {({
        values,
        setFieldValue,
        isValid,
        submitCount,
      }: FormikProps<EvaluatorFormValues>) => (
        <Form noValidate autoComplete="off">
          <Typography
            component="h2"
            variant="h5"
            className={classes.subHeadline}
          >
            Allgemeine Informationen
          </Typography>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <EasyCheckbox name="user.blocked" label="Prüfer ist gesperrt" />
            </Grid>
            <Grid item xs={12} sm={3}>
              <EasyTextfield
                name="userinfo.gender"
                label="Anrede"
                margin="normal"
                fullWidth
                select
              >
                <MenuItem value={Enum_Contact_Gender.Male}>Herr</MenuItem>
                <MenuItem value={Enum_Contact_Gender.Female}>Frau</MenuItem>
              </EasyTextfield>
            </Grid>
            <Grid item xs={12} sm={3}>
              <EasyTextfield
                name="userinfo.title"
                label="Titel"
                margin="normal"
                fullWidth
                select
              >
                <MenuItem value="none">
                  <em>-</em>
                </MenuItem>
                <MenuItem value="dr">Dr.</MenuItem>
                <MenuItem value="prof">Prof.</MenuItem>
              </EasyTextfield>
            </Grid>
            <Grid item xs={12} sm={6}>
              <EasyTextfield
                id="form-short"
                name="evaluator.short"
                label="Initialien"
                margin="normal"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <EasyTextfield
                id="form-firstname"
                name="userinfo.firstname"
                label="Vorname"
                margin="normal"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <EasyTextfield
                id="form-lastname"
                name="userinfo.lastname"
                label="Nachname"
                margin="normal"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <EasyTextfield
                id="form-email"
                name="user.email"
                type="email"
                label="E-Mail"
                margin="normal"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <EasyTextfield
                name="evaluator.phone"
                label="Telefon"
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <EasyTextfield
                id="form-username"
                name="user.username"
                label="Benutzername"
                margin="normal"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <EasyTextfield
                id="form-password"
                name="user.password"
                label="Passwort"
                margin="normal"
                type="password"
                required={type === 'add'}
                fullWidth
              />
            </Grid>
          </Grid>
          <Typography
            component="h2"
            variant="h5"
            className={classes.subHeadline}
          >
            Zertifizierungskategorien
          </Typography>

          <FormGroup className={classes.checkboxControl}>
            {data &&
              data.categories &&
              data.categories.map(category => {
                return (
                  <FormControlLabel
                    key={category!.id}
                    control={
                      <Checkbox
                        checked={
                          values.evaluator.categories!.indexOf(category!.id) >
                          -1
                        }
                        name="evaluator.categories"
                        onChange={e => {
                          setFieldValue(
                            'evaluator.categories',
                            toggleInArray(
                              values.evaluator.categories!,
                              category!.id,
                            ),
                          );
                        }}
                      />
                    }
                    label={category!.name}
                  />
                );
              })}
          </FormGroup>

          <div className={classes.confirmationContainer}>
            {!isValid && submitCount > 0 && (
              <Typography color="error" gutterBottom>
                Es ist ein Fehler aufgetreten, bitte prüfen Sie ihre Eingaben
              </Typography>
            )}
            <Button
              variant="contained"
              type="submit"
              color="primary"
              className={classes.confirmationButton}
            >
              Speichern
            </Button>

            <ButtonLink
              variant="contained"
              to={'/evaluator'}
              className={classes.confirmationButton}
            >
              Abbrechen
            </ButtonLink>
          </div>
        </Form>
      )}
    </Formik>
  );
};

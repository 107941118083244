import CssBaseline from '@mui/material/CssBaseline';
import { ReactElement } from 'react';
import { Route, Routes, Navigate } from 'react-router';
import { HashRouter } from 'react-router-dom';
import { useAuth, Auth, AuthContext } from './components/Auth/Auth';
import { CourseAddPage } from './pages/course/CourseAddPage';
import { CourseEditPage } from './pages/course/CourseEditPage';
import { CourseEvaluatePage } from './pages/course/CourseEvaluatePage';
import { CourseOverviewAdminPage } from './pages/course/CourseOverviewAdminPage';
import { CourseOverviewEvaluatorPage } from './pages/course/CourseOverviewEvaluatorPage';
import { CourseViewPage } from './pages/course/CourseViewPage';
import { DashboardPage } from './pages/dashboard/DashboardPage';
import { EvaluatorAddPage } from './pages/evaluator/EvaluatorAddPage';
import { EvaluatorEditPage } from './pages/evaluator/EvaluatorEditPage';
import { EvaluatorOverviewPage } from './pages/evaluator/EvaluatorOverviewPage';
import { FallbackPage } from './pages/fallback/FallbackPage';
import { ProviderAddPage } from './pages/provider/ProviderAddPage';
import { ProviderEditPage } from './pages/provider/ProviderEditPage';
import { ProviderOverviewPage } from './pages/provider/ProviderOverviewPage';

export const App = () => (
  <Auth>
    <CssBaseline />
    <AuthContext.Consumer>
      {({ me }) => (
        <HashRouter>
          <Routes>
            <Route
              path="/"
              element={
                me.isAdministrator ? (
                  <DashboardPage />
                ) : (
                  <Navigate to="/course" />
                )
              }
            />
            <Route
              path="/course/evaluate/:id"
              element={<CourseEvaluatePage />}
            />
            <Route path="/course/view/:id" element={<CourseViewPage />} />
            <Route
              path="/course/add"
              element={<AdminGuard render={() => <CourseAddPage />} />}
            />
            <Route
              path="/course/edit/:id"
              element={<AdminGuard render={() => <CourseEditPage />} />}
            />
            <Route
              path="/course"
              element={
                me.isAdministrator ? (
                  <CourseOverviewAdminPage />
                ) : (
                  <CourseOverviewEvaluatorPage />
                )
              }
            />

            <Route
              path="/provider/add"
              element={<AdminGuard render={() => <ProviderAddPage />} />}
            />
            <Route
              path="/provider/edit/:id"
              element={<AdminGuard render={() => <ProviderEditPage />} />}
            />
            <Route
              path="/provider"
              element={<AdminGuard render={() => <ProviderOverviewPage />} />}
            />

            <Route
              path="/evaluator/add"
              element={<AdminGuard render={() => <EvaluatorAddPage />} />}
            />
            <Route
              path="/evaluator/edit/:id"
              element={<AdminGuard render={() => <EvaluatorEditPage />} />}
            />
            <Route
              path="/evaluator"
              element={<AdminGuard render={() => <EvaluatorOverviewPage />} />}
            />

            <Route element={<FallbackPage />} />
          </Routes>
        </HashRouter>
      )}
    </AuthContext.Consumer>
  </Auth>
);

interface AdminGuardProps {
  render: () => ReactElement;
}

const AdminGuard = ({ render }: AdminGuardProps) => {
  const auth = useAuth();

  if (auth.me.isAdministrator) {
    return render();
  } else {
    return (
      <Navigate
        to={{
          pathname: '/course',
        }}
      />
    );
  }
};

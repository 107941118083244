import {
  Button,
  Fab,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { FieldArray, Form, Formik, FormikConfig, FormikProps } from 'formik';
import React, { useState } from 'react';
import { ButtonLink } from '../../../components/ButtonLink/ButtonLink';
import { EasyCheckbox } from '../../../components/Fields/EasyCheckbox';
import { EasyTextfield } from '../../../components/Fields/EasyTextfield';
import {
  ContactInput,
  Enum_Contact_Gender,
  Enum_Contact_Title,
} from 'gql/graphql';
import { formatToName } from '../../../utils/formatter';
import { ProviderFormInnerProps, ProviderFormValues } from '../types';
import { ContactForm } from './ContactForm';
import { ProviderFormSchema } from './validation';

export const emptyContact: ContactInput = {
  firstname: '',
  lastname: '',
  gender: Enum_Contact_Gender.Male,
  title: Enum_Contact_Title.None,
  phone: '',
  email: '',
};

const useStyles = makeStyles(theme => ({
  subHeadline: { margin: '50px 0 20px 0' },
  form: {},
  textField: {},
  contactContainer: { margin: '60px 0 50px 0' },
  confirmationContainer: { marginTop: 40 },
  confirmationButton: { marginRight: 8 },
}));

export const ProviderForm = ({
  type,
  ...rest
}: ProviderFormInnerProps & FormikConfig<ProviderFormValues>) => {
  const classes = useStyles();

  const [currentIndex, setCurrentIndex] = useState<number | null>(null);

  return (
    <Formik
      validationSchema={ProviderFormSchema}
      validateOnChange={false}
      isInitialValid
      {...rest}
    >
      {({
        values,
        setFieldValue,
        isValid,
        submitCount,
      }: FormikProps<ProviderFormValues>) => (
        <Form className={classes.form} noValidate autoComplete="off">
          <Typography
            component="h2"
            variant="h5"
            className={classes.subHeadline}
          >
            Allgemeine Informationen
          </Typography>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <EasyCheckbox
                id="form-enabled"
                name="provider.enabled"
                label="Kursanbieter ist aktiv"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <EasyTextfield
                id="form-name"
                name="provider.name"
                label="Institution"
                className={classes.textField}
                margin="normal"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <EasyTextfield
                id="form-homepage"
                name="provider.homepage"
                label="Homepage"
                placeholder="http(s)://www.homepage.de"
                className={classes.textField}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <EasyTextfield
                id="form-street"
                name="provider.street"
                label="Straße"
                className={classes.textField}
                margin="normal"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <EasyTextfield
                id="form-streetnumber"
                name="provider.streetnumber"
                label="Hausnummer"
                className={classes.textField}
                margin="normal"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <EasyTextfield
                id="form-zip"
                name="provider.zip"
                label="Postleitzahl"
                className={classes.textField}
                margin="normal"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <EasyTextfield
                id="form-city"
                name="provider.city"
                label="Ort"
                className={classes.textField}
                margin="normal"
                required
                fullWidth
              />
            </Grid>
          </Grid>

          <Typography
            component="h2"
            variant="h5"
            className={classes.subHeadline}
          >
            Ansprechpartner
          </Typography>

          <FieldArray
            name="contacts"
            render={arrayHelpers => (
              <>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>E-Mail</TableCell>
                      <TableCell>Telefon</TableCell>
                      <TableCell align="right">
                        <Fab
                          size="small"
                          color="primary"
                          onClick={() => {
                            setFieldValue('temp', { ...emptyContact });
                            setCurrentIndex(-1);
                          }}
                        >
                          <AddIcon />
                        </Fab>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!values.contacts ||
                      (values.contacts.length === 0 && (
                        <TableRow>
                          <TableCell component="th" scope="row" colSpan={4}>
                            Keine Ansprechpartner vorhanden
                          </TableCell>
                        </TableRow>
                      ))}
                    {values.contacts &&
                      values.contacts.length > 0 &&
                      values.contacts.map((contact, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {formatToName(contact)}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {contact.email}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {contact.phone}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              onClick={() => {
                                setFieldValue('temp', {
                                  ...values.contacts[index],
                                });
                                setCurrentIndex(index);
                              }}
                              size="large"
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                              size="large"
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <ContactForm
                  initialValues={
                    currentIndex === null || currentIndex < 0
                      ? emptyContact
                      : values.contacts[currentIndex]
                  }
                  onSubmit={(subValues, actions) => {
                    if (currentIndex === null) {
                      return;
                    }
                    if (currentIndex < 0) {
                      arrayHelpers.push({ ...subValues });
                    } else {
                      arrayHelpers.replace(currentIndex, {
                        ...subValues,
                      });
                    }
                    setCurrentIndex(null);
                    actions.resetForm();
                  }}
                  handleClose={() => {
                    setCurrentIndex(null);
                  }}
                  open={currentIndex !== null}
                />
              </>
            )}
          />

          <div className={classes.confirmationContainer}>
            {!isValid && submitCount > 0 && (
              <Typography color="error" gutterBottom>
                Es ist ein Fehler aufgetreten, bitte prüfen Sie ihre Eingaben
              </Typography>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.confirmationButton}
            >
              Speichern
            </Button>

            <ButtonLink
              variant="contained"
              to={'/provider'}
              className={classes.confirmationButton}
            >
              Abbrechen
            </ButtonLink>
          </div>
        </Form>
      )}
    </Formik>
  );
};

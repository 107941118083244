import { List, ListItem, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { InfoAvatar } from '../../../components/InfoAvatar/InfoAvatar';
import { GetCourseQueryEvaluator } from '../../../types/api';
import { formatToName } from '../../../utils/formatter';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  item: {
    width: 'auto',
  },
  gap: {
    marginLeft: 4,
  },
}));

export const Evaluators = ({
  evaluators,
}: {
  evaluators: GetCourseQueryEvaluator[];
}) => {
  const classes = useStyles();
  return (
    <List dense className={classes.container}>
      {evaluators.map(evaluator => (
        <ListItem
          key={evaluator.id}
          data-testid="evaluator"
          className={classes.item}
        >
          <InfoAvatar label={evaluator.short} />
          <ListItemText
            className={classes.gap}
            primary={formatToName(evaluator.user!.info!)}
          />
        </ListItem>
      ))}
      {evaluators.length === 0 && (
        <ListItem data-testid="evaluator">
          <ListItemText primary="Keine Prüfer angegeben" />
        </ListItem>
      )}
    </List>
  );
};

import { Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Base } from '../../components/Base/Base';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { handleErrors } from '../../utils/formatter';
import { ProviderForm } from './helper/ProviderForm';
import { ProviderFormValues } from './types';
import { CUSTOM_CREATE_PROVIDER_MUTATION } from './graphql';
import { useMutation } from '@apollo/client';

const initialValues: ProviderFormValues = {
  provider: {
    enabled: true,
    name: '',
    homepage: '',
    street: '',
    streetnumber: '',
    zip: '',
    city: '',
  },
  contacts: [],
};

export const ProviderAddPage = () => {
  const [customUpdateProvider] = useMutation(CUSTOM_CREATE_PROVIDER_MUTATION);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  return (
    <Base>
      <PageHeader>Kursanbieter hinzufügen</PageHeader>
      <ProviderForm
        type="add"
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await customUpdateProvider({
              variables: {
                provider: values.provider,
                contacts: values.contacts,
              },
            });
            navigate('/provider');
          } catch (e) {
            setError(handleErrors(e));
          }
        }}
      />
      {error && <Typography>{error}</Typography>}
    </Base>
  );
};

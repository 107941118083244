import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import ViewIcon from '@mui/icons-material/Visibility';
import { addYears, endOfDay, isFuture } from 'date-fns';
import MaterialTable, { MTableBodyRow } from '@material-table/core';
import { useMemo, useState } from 'react';
import { Base } from '../../components/Base/Base';
import { IconButtonLink } from '../../components/IconButtonLink/IconButtonLink';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { CERTIFICATION_YEARS } from '../../utils/constants';
import { stateToString } from '../../utils/formatter';
import { FilteredCourse } from './types';
import { GET_COURSES_QUERY, GET_MY_EVALUATIONS } from './graphql';
import { useQuery } from '@apollo/client';

export const CourseOverviewEvaluatorPage = () => {
  const {
    data: courseData,
    loading: loadingCourses,
    error: errorCourses,
  } = useQuery(GET_COURSES_QUERY);

  const {
    data: myEvaluationData,
    loading: loadingMyEvaluations,
    error: errorMyEvaluations,
  } = useQuery(GET_MY_EVALUATIONS);

  const [showOnlyOpen, setShowOnlyOpen] = useState(true);

  const courses = useMemo(() => {
    if (
      courseData &&
      courseData.courses &&
      myEvaluationData &&
      myEvaluationData.myEvaluations
    ) {
      return courseData.courses.reduce<FilteredCourse[]>(
        (filteredCourses, course) => {
          const myCycleEvaluations = myEvaluationData.myEvaluations.filter(
            myEvaluation => myEvaluation!.course === course!.id,
          );
          const myOpenCycleEvaluations = myCycleEvaluations
            .filter(myCycleEvaluation => myCycleEvaluation!.open)
            .map(
              myOpenCycleEvaluation => myOpenCycleEvaluation!.cycleevaluation,
            );

          if (
            course.enabled &&
            myCycleEvaluations.length > 0 &&
            (!showOnlyOpen || myOpenCycleEvaluations.length > 0)
          ) {
            const filteredCourse: FilteredCourse = {
              category: course!.category!.name,
              provider: course!.provider!.name,
              state: course!.state,
              certified:
                Boolean(course!.processes![0].confirmedAt) &&
                isFuture(
                  endOfDay(
                    addYears(
                      new Date(course!.processes![0].confirmedAt),
                      CERTIFICATION_YEARS,
                    ),
                  ),
                ),
              id: course!.id,
              open: myOpenCycleEvaluations,
            };

            return [...filteredCourses, filteredCourse];
          } else {
            return filteredCourses;
          }
        },
        [],
      );
    } else {
      return null;
    }
  }, [courseData, myEvaluationData, showOnlyOpen]);

  return (
    <Base>
      {loadingCourses || loadingMyEvaluations ? (
        <CircularProgress />
      ) : errorCourses || errorMyEvaluations ? (
        <>
          <h1>ERROR</h1>
          {errorCourses && (
            <Typography>{JSON.stringify(errorCourses)}</Typography>
          )}
          {errorMyEvaluations && (
            <Typography>{JSON.stringify(errorMyEvaluations)}</Typography>
          )}
        </>
      ) : (
        courses && (
          <>
            <PageHeader>Kursübersicht</PageHeader>

            <FormControlLabel
              control={
                <Checkbox
                  checked={showOnlyOpen}
                  onChange={event => {
                    setShowOnlyOpen(event.target.checked);
                  }}
                  value="checkedB"
                  color="primary"
                />
              }
              label="Nur offene Kurse anzeigen"
            />

            <MaterialTable
              columns={[
                {
                  title: 'Zertifiziert',
                  field: 'certified',
                  type: 'boolean',
                  render: rowData =>
                    rowData.certified ? (
                      <CheckIcon style={{ marginLeft: 10 }} />
                    ) : (
                      ''
                    ),
                },
                {
                  title: 'Anbieter',
                  field: 'provider',
                  customSort: (a, b) =>
                    a.provider.toLowerCase() > b.provider.toLowerCase()
                      ? 1
                      : -1,
                },
                {
                  title: 'Zertifizierungskategorie',
                  field: 'category',
                },
                {
                  title: 'Status',
                  field: 'state',
                  defaultSort: 'desc',
                  customFilterAndSearch: (term, rowData) =>
                    rowData.state.name.includes(term),
                  customSort: (a, b) => {
                    return a.state.date > b.state.date ? 1 : -1;
                  },
                  render: rowData => stateToString(rowData.state),
                },
                {
                  filtering: false,
                  cellStyle: { textAlign: 'right', width: 130 },
                  render: rowData => (
                    <>
                      {rowData.open.map(openCycleevaluation => (
                        <IconButtonLink
                          key={openCycleevaluation}
                          to={`/course/evaluate/${openCycleevaluation}`}
                          data-testid="evaluateCourse"
                        >
                          <EditIcon fontSize="small" />
                        </IconButtonLink>
                      ))}

                      <IconButtonLink
                        to={`/course/view/${rowData!.id}`}
                        data-testid="viewCourse"
                      >
                        <ViewIcon fontSize="small" />
                      </IconButtonLink>
                    </>
                  ),
                },
              ]}
              data={courses}
              localization={{
                body: {
                  emptyDataSourceMessage: showOnlyOpen
                    ? 'Keine offenen Kurse'
                    : 'Keine Kurse vorhanden',
                },
              }}
              options={{
                paging: true,
                pageSize: 50,
                pageSizeOptions: [],
                filtering: true,
                toolbar: false,
                emptyRowsWhenPaging: false,
                sorting: true,
              }}
              components={{
                Row: props => <MTableBodyRow {...props} />,
                Container: props => <div {...props} />,
              }}
            />
          </>
        )
      )}
    </Base>
  );
};

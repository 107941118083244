import Button, { ButtonProps } from '@mui/material/Button';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface ButtonLinkProps extends ButtonProps {
  to: LinkProps['to'];
  replace?: boolean;
}

export const ButtonLink = (props: ButtonLinkProps) => (
  <Button {...props} component={Link as any} />
);

import { CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../components/Auth/Auth';
import { Base } from '../../components/Base/Base';
import { ButtonLink } from '../../components/ButtonLink/ButtonLink';
import { Evaluation } from './helper/Evaluation';
import { TargetEvaluation } from './types';
import { GET_COURSE_QUERY, GET_CYCLEEVALUATION_QUERY } from './graphql';
import { useQuery } from '@apollo/client';

const useStyles = makeStyles(theme => ({
  blocking: { marginBottom: 20 },
}));

export const CourseEvaluatePage = () => {
  const classes = useStyles();
  const { id } = useParams();

  const [targetEvaluation, setTargetEvaluation] =
    useState<TargetEvaluation | null>(null);

  const {
    data: cycleevaluationData,
    loading: cycleevaluationLoading,
    error: cycleevaluationError,
  } = useQuery(GET_CYCLEEVALUATION_QUERY, {
    // @ts-ignore
    variables: { id },
    skip: !id,
  });

  const { data, loading, error } = useQuery(GET_COURSE_QUERY, {
    skip: !targetEvaluation,
    variables: { id: (targetEvaluation && targetEvaluation.courseId) || '' },
  });

  const auth = useAuth();

  useEffect(() => {
    let evaluation = null;
    if (cycleevaluationData && cycleevaluationData.cycleevaluation) {
      const cycleevaluationId = cycleevaluationData.cycleevaluation.id;
      const evaluatorId = cycleevaluationData.cycleevaluation.evaluator!.id;
      const cycleId = cycleevaluationData.cycleevaluation.cycle!.id;
      const processId = cycleevaluationData.cycleevaluation.cycle!.process!.id;
      const courseId =
        cycleevaluationData.cycleevaluation.cycle!.process!.course!.id;
      evaluation = {
        cycleevaluationId,
        evaluatorId,
        cycleId,
        processId,
        courseId,
      };
    }
    setTargetEvaluation(evaluation);
  }, [cycleevaluationData]);

  const blockingMessage = useMemo(() => {
    if (
      targetEvaluation &&
      targetEvaluation.evaluatorId !== auth.me.evaluator
    ) {
      return 'Sie sind für diese Prüfung nicht berechtigt.';
    }
    if (cycleevaluationData && cycleevaluationData.cycleevaluation) {
      return cycleevaluationData.cycleevaluation.score !== 0
        ? 'Sie haben diese Prüfrunde bereits bewertet.'
        : !cycleevaluationData.cycleevaluation.cycle!.published
          ? 'Diese Prüfungsrunde ist noch nicht freigegeben.'
          : cycleevaluationData.cycleevaluation.cycle!.score !== 0
            ? 'Sie müssen diese Runde nicht mehr bewerten, da es bereits eine Mehrheitsentscheidung gab.'
            : cycleevaluationData.cycleevaluation.cycle!.closedAt
              ? 'Die Prüfrunde wurde geschlossen.'
              : '';
    }
    return '';
  }, [cycleevaluationData, targetEvaluation, auth.me.evaluator]);

  return (
    <Base>
      {loading || cycleevaluationLoading ? (
        <CircularProgress />
      ) : error || cycleevaluationError ? (
        <>
          <Typography>Keinen passenden Kurs gefunden.</Typography>
          <Typography gutterBottom>
            ({error && error.message}
            {cycleevaluationError && cycleevaluationError.message})
          </Typography>
          <ButtonLink variant="contained" to="/course">
            Zur Kursübersicht
          </ButtonLink>
        </>
      ) : blockingMessage ? (
        <>
          <Typography className={classes.blocking} gutterBottom>
            {blockingMessage}
          </Typography>
          <ButtonLink variant="contained" to="/course">
            Zur Kursübersicht
          </ButtonLink>
        </>
      ) : data && data.course && targetEvaluation ? (
        <Evaluation
          course={data.course}
          target={targetEvaluation}
          criteria={data.criteria!}
          documenttypes={data.documenttypes}
        />
      ) : (
        <>
          <Typography gutterBottom>Keinen passenden Kurs gefunden</Typography>
          <ButtonLink variant="contained" to="/course">
            Zur Kursübersicht
          </ButtonLink>
        </>
      )}
    </Base>
  );
};

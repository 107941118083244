import { useQuery } from '@apollo/client';
import { CircularProgress, Typography } from '@mui/material';
import { addYears, differenceInCalendarDays, endOfDay } from 'date-fns';
import { useMemo } from 'react';
import { Base } from '../../components/Base/Base';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import {
  CERTIFICATION_REMINDER_DAYS,
  CERTIFICATION_YEARS,
} from '../../utils/constants';
import { DashboardPanel } from './helper/DashboardPanel';
import { GET_COURSES_FOR_DASHBOARD } from './graphql';

export const DashboardPage = () => {
  const { data, loading, error } = useQuery(GET_COURSES_FOR_DASHBOARD);

  const filteredCourses = useMemo(() => {
    const calling =
      (data &&
        data.courses &&
        data.courses
          .filter(course => course!.state && course!.state.type === 'calling')
          .sort((a, b) => (a.state.date < b.state.date ? 1 : -1))) ||
      [];

    const evaluating =
      (data &&
        data.courses &&
        data.courses
          .filter(
            course => course!.state && course!.state.type === 'evaluating',
          )
          .sort((a, b) => (a.state.date < b.state.date ? 1 : -1))) ||
      [];

    const waiting =
      (data &&
        data.courses &&
        data.courses
          .filter(course => course!.state && course!.state.type === 'waiting')
          .sort((a, b) => (a.state.date < b.state.date ? 1 : -1))) ||
      [];

    const expiring =
      (data &&
        data.courses &&
        data.courses
          .filter(course => {
            if (
              course.processes &&
              course.processes[0] &&
              course.processes[0].confirmedAt
            ) {
              const diff = differenceInCalendarDays(
                endOfDay(
                  addYears(
                    new Date(course.processes[0].confirmedAt),
                    CERTIFICATION_YEARS,
                  ),
                ),
                new Date(),
              );
              return diff >= 0 && diff < CERTIFICATION_REMINDER_DAYS;
            }
            return false;
          })
          .sort((a, b) =>
            a.processes![0]!.confirmedAt! > b.processes![0]!.confirmedAt!
              ? 1
              : -1,
          )) ||
      [];

    return { calling, evaluating, waiting, expiring };
  }, [data]);

  return (
    <Base>
      <PageHeader>Dashboard</PageHeader>

      {loading ? (
        <CircularProgress />
      ) : error ? (
        <>
          <h1>ERROR</h1>
          <Typography>{error.message}</Typography>
        </>
      ) : (
        data &&
        data.courses && (
          <>
            <DashboardPanel
              courses={filteredCourses.calling}
              data-testid="callingContainer"
              label="Offene Aufgaben"
            />

            <DashboardPanel
              courses={filteredCourses.evaluating}
              data-testid="evaluatingContainer"
              label="Aktuelle Prüfungen"
            />

            <DashboardPanel
              courses={filteredCourses.waiting}
              data-testid="waitingContainer"
              label="Warten auf Feedback"
            />

            <DashboardPanel
              courses={filteredCourses.expiring}
              data-testid="expiringContainer"
              label="Demnächst ablaufende Zertifizierungen"
            />
          </>
        )
      )}
    </Base>
  );
};

import {
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { EasyTextfield } from '../../../components/Fields/EasyTextfield';
import { ProtocolCriterion } from '../../../components/Protocol/ProtocolCriterion';
import { CriterionInnerProps } from '../types';

const useStyles = makeStyles(theme => ({
  evaluationRadioGroup: { flexDirection: 'row' },
  evaluationWrapper: { marginTop: 30 },
  evaluationContainer: { padding: '10px 24px' },
  evaluationHeader: { background: '#eee', padding: '10px 20px' },
}));

export const Criterion = ({
  index,
  values,
  criterionData,
  setFieldValue,
  handleBlur,
}: CriterionInnerProps) => {
  const classes = useStyles();

  return (
    <Paper
      data-testid="criterion"
      elevation={1}
      className={classes.evaluationWrapper}
    >
      <Typography
        variant="h6"
        component="h3"
        className={classes.evaluationHeader}
      >
        {`${index + 1}. ${criterionData.criterion.name}`}
      </Typography>

      <div className={classes.evaluationContainer}>
        <RadioGroup
          name={`criterionevaluations.${index}.score`}
          className={classes.evaluationRadioGroup}
          value={String(values.score)}
          onChange={event =>
            setFieldValue(
              `criterionevaluations.${index}.score`,
              Number((event.target as HTMLInputElement).value),
            )
          }
          onBlur={handleBlur}
        >
          <FormControlLabel value="1" control={<Radio />} label="Ja" />
          <FormControlLabel value="-1" control={<Radio />} label="Nein" />
          <FormControlLabel value="0" control={<Radio />} label="Ausstehend" />
        </RadioGroup>

        <EasyTextfield
          id={`form-comment${index}`}
          name={`criterionevaluations.${index}.comment`}
          label="Kommentar"
          multiline={true}
          rows={4}
          fullWidth
        />
      </div>

      <ProtocolCriterion
        label="Andere Bewertungen"
        cycles={criterionData.cycles}
      />
    </Paper>
  );
};

import { Checkbox, FormControlLabel } from '@mui/material';
import { FormControlLabelProps } from '@mui/material/FormControlLabel';
import { Field, FieldProps } from 'formik';

export const EasyCheckbox = ({
  name,
  ...rest
}: Omit<FormControlLabelProps, 'control'> & { name: string }) => (
  <Field name={name}>
    {({ field: { value, ...fieldRest } }: FieldProps) => (
      <FormControlLabel
        control={<Checkbox checked={value} {...fieldRest} />}
        {...rest}
      />
    )}
  </Field>
);

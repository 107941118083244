import { CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { pickBy } from 'ramda';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Base } from '../../components/Base/Base';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { handleErrors } from '../../utils/formatter';
import { EvaluatorForm } from './helper/EvaluatorForm';
import { EvaluatorFormSchema } from './helper/validation';
import { EvaluatorFormValues } from './types';
import {
  GET_EVALUATOR_QUERY,
  UPDATE_EVALUATOR_MUTATION,
  UPDATE_USERINFO_MUTATION,
  UPDATE_USER_MUTATION,
} from './graphql';
import { useMutation, useQuery } from '@apollo/client';

const useStyles = makeStyles(theme => ({
  contactContainer: { margin: '60px 0 50px 0' },
  courseList: { marginTop: 50 },
  subHeadline: { margin: '50px 0 20px 0' },
  confirmationContainer: { marginTop: 40 },
  confirmationButton: { marginRight: 8 },
  checkboxControl: { marginTop: 30 },
  listBackground: { background: theme.palette.grey[50], marginTop: 20 },
  error: {
    color: theme.palette.error.main,
  },
}));

export const EvaluatorEditPage = () => {
  const classes = useStyles();

  const navigate = useNavigate();
  const { id } = useParams();

  const {
    data,
    loading: getEvaluatorLoading,
    error: getEvaluatorError,
  } = useQuery(GET_EVALUATOR_QUERY, {
    // @ts-ignore
    variables: { id },
    skip: !id,
  });

  const [updateEvaluator] = useMutation(UPDATE_EVALUATOR_MUTATION);
  const [updateUserinfo] = useMutation(UPDATE_USERINFO_MUTATION);
  const [updateUser] = useMutation(UPDATE_USER_MUTATION);

  const [error, setError] = useState('');

  const initialValues: EvaluatorFormValues | undefined =
    data && data.evaluator && data.evaluator.user
      ? {
          evaluator: {
            short: data.evaluator.short,
            phone: data.evaluator.phone,
            categories: data.evaluator.categories
              ? data.evaluator.categories.reduce<string[]>(
                  (acc, category) => (category ? [...acc, category!.id] : acc),
                  [],
                )
              : [],
          },
          user: {
            blocked: data.evaluator.user.blocked,
            email: data.evaluator.user.email,
            username: data.evaluator.user.username,
            password: '',
          },
          userinfo: {
            firstname: data.evaluator.user.info!.firstname,
            lastname: data.evaluator.user.info!.lastname,
            gender: data.evaluator.user.info!.gender,
            title: data.evaluator.user.info!.title,
          },
        }
      : undefined;

  return (
    <Base>
      {getEvaluatorLoading ? (
        <CircularProgress />
      ) : getEvaluatorError ? (
        <>
          <h1>ERROR</h1>
          <Typography>{getEvaluatorError.message}</Typography>
        </>
      ) : (
        initialValues && (
          <>
            <PageHeader>Prüfer bearbeiten</PageHeader>
            <EvaluatorForm
              type="edit"
              validationSchema={EvaluatorFormSchema}
              initialValues={initialValues}
              onSubmit={async (values, { setErrors }) => {
                if (id) {
                  try {
                    const isNotEmptyPassword = (value: any, key: string) =>
                      key !== 'password' || value !== '';
                    await updateUser({
                      variables: {
                        id: data!.evaluator!.user!.id,
                        user: pickBy(isNotEmptyPassword, values.user),
                      },
                    });

                    await updateEvaluator({
                      variables: {
                        id,
                        evaluator: {
                          ...values.evaluator,
                          courses: values.user.blocked ? [] : undefined,
                        },
                      },
                    });

                    await updateUserinfo({
                      variables: {
                        id: data!.evaluator!.user!.info!.id,
                        userinfo: values.userinfo,
                      },
                    });

                    navigate('/evaluator');
                  } catch (e) {
                    const errorMessage = handleErrors(e, setErrors, [
                      {
                        error: 'Die E-Mail ist bereits vergeben',
                        field: 'user.email',
                        match: ['email', 'E-Mail'],
                      },
                      {
                        error: 'Der Benutzername ist bereits vergeben',
                        field: 'user.username',
                        match: ['username', 'benutzername'],
                      },
                    ]);
                    setError(errorMessage);
                  }
                }
              }}
            />
            {error && (
              <Typography className={classes.error}>{error}</Typography>
            )}
          </>
        )
      )}
    </Base>
  );
};

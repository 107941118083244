import { CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { uniq } from 'ramda';
import { useMemo } from 'react';
import { Base } from '../../components/Base/Base';
import { FabLink } from '../../components/FabButtonLink/FabLink';
import { IconButtonLink } from '../../components/IconButtonLink/IconButtonLink';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { formatToName } from '../../utils/formatter';

import MaterialTable, { MTableBodyRow } from '@material-table/core';
import { GetEvaluatorsQueryEvaluators } from '../../types/api';
import { GET_EVALUATORS_QUERY } from './graphql';
import { useQuery } from '@apollo/client';

export const EvaluatorOverviewPage = () => {
  const { data, loading, error } = useQuery(GET_EVALUATORS_QUERY);

  const usedCategories = useMemo<string[]>(() => {
    if (data && data.evaluators) {
      // return uniq(pluck('name', flatten(pluck('categories', data.evaluators)))); // old version
      // TODO: check if IE support (polyfill?)
      return uniq(
        data.evaluators
          .map(evaluator => evaluator.categories.map(category => category.name))
          .flat(),
      );
    } else {
      return [];
    }
  }, [data]);

  return (
    <Base>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <>
          <h1>ERROR</h1>
          <div>{error.message}</div>
        </>
      ) : (
        data &&
        data.evaluators && (
          <>
            <PageHeader>Prüfer</PageHeader>
            <MaterialTable
              columns={[
                {
                  title: 'Name',
                  field: 'fullname',
                  cellStyle: (cData, rowData) => {
                    return {
                      textDecoration: rowData.user!.blocked
                        ? 'line-through'
                        : 'none',
                    };
                  },
                  defaultSort: 'asc',
                  customSort: (a, b) =>
                    a.user!.info!.lastname.toLowerCase() >
                    b.user!.info!.lastname.toLowerCase()
                      ? 1
                      : -1,
                },
                {
                  sorting: false,
                  title: 'Zertifizierungskategorien',
                  customFilterAndSearch: (term, rowData) => {
                    return (
                      term.length === 0 ||
                      rowData.categories.some((category: any) =>
                        term.includes(category.name),
                      )
                    );
                  },
                  lookup: usedCategories.reduce(
                    (acc, current) => ({ ...acc, [current]: current }),
                    {},
                  ),
                  render: (rowData: GetEvaluatorsQueryEvaluators) =>
                    rowData.categories
                      .map(category => category!.name)
                      .join(', '),
                },
                {
                  filtering: false,
                  cellStyle: { textAlign: 'right', width: 100 },
                  render: rowData => (
                    <IconButtonLink
                      to={`/evaluator/edit/${rowData.id}`}
                      data-testid="editEvaluator"
                    >
                      <EditIcon fontSize="small" />
                    </IconButtonLink>
                  ),
                },
              ]}
              data={data.evaluators.map(evaluator => ({
                ...evaluator,
                fullname: formatToName(evaluator!.user!.info!),
              }))}
              localization={{
                body: { emptyDataSourceMessage: 'Keine Prüfer vorhanden' },
              }}
              options={{
                paging: true,
                pageSize: 50,
                pageSizeOptions: [],
                filtering: true,
                toolbar: false,
                emptyRowsWhenPaging: false,
                sorting: true,
              }}
              components={{
                Row: props => <MTableBodyRow {...props} />,
                Container: props => (
                  <div style={{ position: 'relative' }}>
                    <FabLink
                      to="/evaluator/add"
                      size="small"
                      color="primary"
                      data-testid="addEvaluator"
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        zIndex: 11,
                      }}
                    >
                      <AddIcon />
                    </FabLink>
                    <div {...props} />
                  </div>
                ),
              }}
            />
          </>
        )
      )}
    </Base>
  );
};

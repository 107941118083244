import { graphql } from 'gql/gql';

export const GET_COURSES_FOR_DASHBOARD = graphql(`
  query GetCoursesForDashboard {
    courses(limit: 500, where: { enabled: true }) {
      id
      state
      provider {
        id
        name
      }
      category {
        id
        name
      }
      processes {
        id
        confirmedAt
      }
    }
  }
`);

/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Long` scalar type represents 52-bit integers */
  Long: any;
  /** A time string with format: HH:mm:ss.SSS */
  Time: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AdminUser = {
  __typename?: 'AdminUser';
  firstname: Scalars['String'];
  id: Scalars['ID'];
  lastname: Scalars['String'];
  username?: Maybe<Scalars['String']>;
};

/** Kurskategorie */
export type Category = {
  __typename?: 'Category';
  _id: Scalars['ID'];
  courses: Array<Course>;
  criteria: Array<Criterion>;
  documenttypes: Array<Documenttype>;
  enabled: Scalars['Boolean'];
  evaluators: Array<Evaluator>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Kurskategorie */
export type CategoryCoursesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

/** Kurskategorie */
export type CategoryCriteriaArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

/** Kurskategorie */
export type CategoryDocumenttypesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

/** Kurskategorie */
export type CategoryEvaluatorsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type CategoryAggregator = {
  __typename?: 'CategoryAggregator';
  count?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type CategoryConnection = {
  __typename?: 'CategoryConnection';
  aggregate?: Maybe<CategoryAggregator>;
  groupBy?: Maybe<CategoryGroupBy>;
  values: Array<Category>;
};

export type CategoryConnectionEnabled = {
  __typename?: 'CategoryConnectionEnabled';
  connection?: Maybe<CategoryConnection>;
  key?: Maybe<Scalars['Boolean']>;
};

export type CategoryConnectionId = {
  __typename?: 'CategoryConnectionId';
  connection?: Maybe<CategoryConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type CategoryConnectionName = {
  __typename?: 'CategoryConnectionName';
  connection?: Maybe<CategoryConnection>;
  key?: Maybe<Scalars['String']>;
};

export type CategoryConnection_Id = {
  __typename?: 'CategoryConnection_id';
  connection?: Maybe<CategoryConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type CategoryGroupBy = {
  __typename?: 'CategoryGroupBy';
  _id?: Maybe<Array<Maybe<CategoryConnection_Id>>>;
  enabled?: Maybe<Array<Maybe<CategoryConnectionEnabled>>>;
  id?: Maybe<Array<Maybe<CategoryConnectionId>>>;
  name?: Maybe<Array<Maybe<CategoryConnectionName>>>;
};

export type CategoryInput = {
  courses?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  created_by?: InputMaybe<Scalars['ID']>;
  criteria?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  documenttypes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  evaluators?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  updated_by?: InputMaybe<Scalars['ID']>;
};

/** Ansprechpartner */
export type Contact = {
  __typename?: 'Contact';
  _id: Scalars['ID'];
  email: Scalars['String'];
  firstname: Scalars['String'];
  gender: Enum_Contact_Gender;
  id: Scalars['ID'];
  lastname: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  provider?: Maybe<Provider>;
  title: Enum_Contact_Title;
};

export type ContactAggregator = {
  __typename?: 'ContactAggregator';
  count?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ContactConnection = {
  __typename?: 'ContactConnection';
  aggregate?: Maybe<ContactAggregator>;
  groupBy?: Maybe<ContactGroupBy>;
  values: Array<Contact>;
};

export type ContactConnectionEmail = {
  __typename?: 'ContactConnectionEmail';
  connection?: Maybe<ContactConnection>;
  key?: Maybe<Scalars['String']>;
};

export type ContactConnectionFirstname = {
  __typename?: 'ContactConnectionFirstname';
  connection?: Maybe<ContactConnection>;
  key?: Maybe<Scalars['String']>;
};

export type ContactConnectionGender = {
  __typename?: 'ContactConnectionGender';
  connection?: Maybe<ContactConnection>;
  key?: Maybe<Scalars['String']>;
};

export type ContactConnectionId = {
  __typename?: 'ContactConnectionId';
  connection?: Maybe<ContactConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type ContactConnectionLastname = {
  __typename?: 'ContactConnectionLastname';
  connection?: Maybe<ContactConnection>;
  key?: Maybe<Scalars['String']>;
};

export type ContactConnectionPhone = {
  __typename?: 'ContactConnectionPhone';
  connection?: Maybe<ContactConnection>;
  key?: Maybe<Scalars['String']>;
};

export type ContactConnectionProvider = {
  __typename?: 'ContactConnectionProvider';
  connection?: Maybe<ContactConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type ContactConnectionTitle = {
  __typename?: 'ContactConnectionTitle';
  connection?: Maybe<ContactConnection>;
  key?: Maybe<Scalars['String']>;
};

export type ContactConnection_Id = {
  __typename?: 'ContactConnection_id';
  connection?: Maybe<ContactConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type ContactGroupBy = {
  __typename?: 'ContactGroupBy';
  _id?: Maybe<Array<Maybe<ContactConnection_Id>>>;
  email?: Maybe<Array<Maybe<ContactConnectionEmail>>>;
  firstname?: Maybe<Array<Maybe<ContactConnectionFirstname>>>;
  gender?: Maybe<Array<Maybe<ContactConnectionGender>>>;
  id?: Maybe<Array<Maybe<ContactConnectionId>>>;
  lastname?: Maybe<Array<Maybe<ContactConnectionLastname>>>;
  phone?: Maybe<Array<Maybe<ContactConnectionPhone>>>;
  provider?: Maybe<Array<Maybe<ContactConnectionProvider>>>;
  title?: Maybe<Array<Maybe<ContactConnectionTitle>>>;
};

export type ContactInput = {
  created_by?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Enum_Contact_Gender>;
  lastname?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Enum_Contact_Title>;
  updated_by?: InputMaybe<Scalars['ID']>;
};

export type Course = {
  __typename?: 'Course';
  _id: Scalars['ID'];
  category?: Maybe<Category>;
  comment?: Maybe<Scalars['String']>;
  contact?: Maybe<Contact>;
  createdAt: Scalars['DateTime'];
  enabled: Scalars['Boolean'];
  evaluators: Array<Evaluator>;
  id: Scalars['ID'];
  predecessor?: Maybe<Course>;
  processes: Array<Process>;
  provider?: Maybe<Provider>;
  state?: Maybe<Scalars['JSON']>;
  updatedAt: Scalars['DateTime'];
};

export type CourseEvaluatorsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type CourseProcessesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type CourseAggregator = {
  __typename?: 'CourseAggregator';
  count?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type CourseConnection = {
  __typename?: 'CourseConnection';
  aggregate?: Maybe<CourseAggregator>;
  groupBy?: Maybe<CourseGroupBy>;
  values: Array<Course>;
};

export type CourseConnectionCategory = {
  __typename?: 'CourseConnectionCategory';
  connection?: Maybe<CourseConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type CourseConnectionComment = {
  __typename?: 'CourseConnectionComment';
  connection?: Maybe<CourseConnection>;
  key?: Maybe<Scalars['String']>;
};

export type CourseConnectionContact = {
  __typename?: 'CourseConnectionContact';
  connection?: Maybe<CourseConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type CourseConnectionCreatedAt = {
  __typename?: 'CourseConnectionCreatedAt';
  connection?: Maybe<CourseConnection>;
  key?: Maybe<Scalars['DateTime']>;
};

export type CourseConnectionEnabled = {
  __typename?: 'CourseConnectionEnabled';
  connection?: Maybe<CourseConnection>;
  key?: Maybe<Scalars['Boolean']>;
};

export type CourseConnectionId = {
  __typename?: 'CourseConnectionId';
  connection?: Maybe<CourseConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type CourseConnectionPredecessor = {
  __typename?: 'CourseConnectionPredecessor';
  connection?: Maybe<CourseConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type CourseConnectionProvider = {
  __typename?: 'CourseConnectionProvider';
  connection?: Maybe<CourseConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type CourseConnectionState = {
  __typename?: 'CourseConnectionState';
  connection?: Maybe<CourseConnection>;
  key?: Maybe<Scalars['JSON']>;
};

export type CourseConnectionUpdatedAt = {
  __typename?: 'CourseConnectionUpdatedAt';
  connection?: Maybe<CourseConnection>;
  key?: Maybe<Scalars['DateTime']>;
};

export type CourseConnection_Id = {
  __typename?: 'CourseConnection_id';
  connection?: Maybe<CourseConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type CourseGroupBy = {
  __typename?: 'CourseGroupBy';
  _id?: Maybe<Array<Maybe<CourseConnection_Id>>>;
  category?: Maybe<Array<Maybe<CourseConnectionCategory>>>;
  comment?: Maybe<Array<Maybe<CourseConnectionComment>>>;
  contact?: Maybe<Array<Maybe<CourseConnectionContact>>>;
  createdAt?: Maybe<Array<Maybe<CourseConnectionCreatedAt>>>;
  enabled?: Maybe<Array<Maybe<CourseConnectionEnabled>>>;
  id?: Maybe<Array<Maybe<CourseConnectionId>>>;
  predecessor?: Maybe<Array<Maybe<CourseConnectionPredecessor>>>;
  provider?: Maybe<Array<Maybe<CourseConnectionProvider>>>;
  state?: Maybe<Array<Maybe<CourseConnectionState>>>;
  updatedAt?: Maybe<Array<Maybe<CourseConnectionUpdatedAt>>>;
};

export type CourseInput = {
  category?: InputMaybe<Scalars['ID']>;
  comment?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['ID']>;
  created_by?: InputMaybe<Scalars['ID']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  evaluators?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  predecessor?: InputMaybe<Scalars['ID']>;
  processes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  provider?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<Scalars['JSON']>;
  updated_by?: InputMaybe<Scalars['ID']>;
};

/** Bewertungskriterium */
export type Criterion = {
  __typename?: 'Criterion';
  _id: Scalars['ID'];
  categories: Array<Category>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Bewertungskriterium */
export type CriterionCategoriesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type CriterionAggregator = {
  __typename?: 'CriterionAggregator';
  count?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type CriterionConnection = {
  __typename?: 'CriterionConnection';
  aggregate?: Maybe<CriterionAggregator>;
  groupBy?: Maybe<CriterionGroupBy>;
  values: Array<Criterion>;
};

export type CriterionConnectionId = {
  __typename?: 'CriterionConnectionId';
  connection?: Maybe<CriterionConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type CriterionConnectionName = {
  __typename?: 'CriterionConnectionName';
  connection?: Maybe<CriterionConnection>;
  key?: Maybe<Scalars['String']>;
};

export type CriterionConnection_Id = {
  __typename?: 'CriterionConnection_id';
  connection?: Maybe<CriterionConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type CriterionGroupBy = {
  __typename?: 'CriterionGroupBy';
  _id?: Maybe<Array<Maybe<CriterionConnection_Id>>>;
  id?: Maybe<Array<Maybe<CriterionConnectionId>>>;
  name?: Maybe<Array<Maybe<CriterionConnectionName>>>;
};

export type CriterionInput = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  created_by?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  updated_by?: InputMaybe<Scalars['ID']>;
};

/** Bewertungsrunde */
export type Cycle = {
  __typename?: 'Cycle';
  _id: Scalars['ID'];
  cancelled: Scalars['Boolean'];
  closedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  cycleevaluations: Array<Cycleevaluation>;
  documents: Array<Document>;
  expirationReminded: Scalars['Boolean'];
  id: Scalars['ID'];
  process?: Maybe<Process>;
  published: Scalars['Boolean'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  requestedAt?: Maybe<Scalars['DateTime']>;
  score: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** Bewertungsrunde */
export type CycleCycleevaluationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

/** Bewertungsrunde */
export type CycleDocumentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type CycleAggregator = {
  __typename?: 'CycleAggregator';
  avg?: Maybe<CycleAggregatorAvg>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<CycleAggregatorMax>;
  min?: Maybe<CycleAggregatorMin>;
  sum?: Maybe<CycleAggregatorSum>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type CycleAggregatorAvg = {
  __typename?: 'CycleAggregatorAvg';
  score?: Maybe<Scalars['Float']>;
};

export type CycleAggregatorMax = {
  __typename?: 'CycleAggregatorMax';
  score?: Maybe<Scalars['Float']>;
};

export type CycleAggregatorMin = {
  __typename?: 'CycleAggregatorMin';
  score?: Maybe<Scalars['Float']>;
};

export type CycleAggregatorSum = {
  __typename?: 'CycleAggregatorSum';
  score?: Maybe<Scalars['Float']>;
};

export type CycleConnection = {
  __typename?: 'CycleConnection';
  aggregate?: Maybe<CycleAggregator>;
  groupBy?: Maybe<CycleGroupBy>;
  values: Array<Cycle>;
};

export type CycleConnectionCancelled = {
  __typename?: 'CycleConnectionCancelled';
  connection?: Maybe<CycleConnection>;
  key?: Maybe<Scalars['Boolean']>;
};

export type CycleConnectionClosedAt = {
  __typename?: 'CycleConnectionClosedAt';
  connection?: Maybe<CycleConnection>;
  key?: Maybe<Scalars['DateTime']>;
};

export type CycleConnectionCreatedAt = {
  __typename?: 'CycleConnectionCreatedAt';
  connection?: Maybe<CycleConnection>;
  key?: Maybe<Scalars['DateTime']>;
};

export type CycleConnectionExpirationReminded = {
  __typename?: 'CycleConnectionExpirationReminded';
  connection?: Maybe<CycleConnection>;
  key?: Maybe<Scalars['Boolean']>;
};

export type CycleConnectionId = {
  __typename?: 'CycleConnectionId';
  connection?: Maybe<CycleConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type CycleConnectionProcess = {
  __typename?: 'CycleConnectionProcess';
  connection?: Maybe<CycleConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type CycleConnectionPublished = {
  __typename?: 'CycleConnectionPublished';
  connection?: Maybe<CycleConnection>;
  key?: Maybe<Scalars['Boolean']>;
};

export type CycleConnectionPublishedAt = {
  __typename?: 'CycleConnectionPublishedAt';
  connection?: Maybe<CycleConnection>;
  key?: Maybe<Scalars['DateTime']>;
};

export type CycleConnectionRequestedAt = {
  __typename?: 'CycleConnectionRequestedAt';
  connection?: Maybe<CycleConnection>;
  key?: Maybe<Scalars['DateTime']>;
};

export type CycleConnectionScore = {
  __typename?: 'CycleConnectionScore';
  connection?: Maybe<CycleConnection>;
  key?: Maybe<Scalars['Int']>;
};

export type CycleConnectionUpdatedAt = {
  __typename?: 'CycleConnectionUpdatedAt';
  connection?: Maybe<CycleConnection>;
  key?: Maybe<Scalars['DateTime']>;
};

export type CycleConnection_Id = {
  __typename?: 'CycleConnection_id';
  connection?: Maybe<CycleConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type CycleGroupBy = {
  __typename?: 'CycleGroupBy';
  _id?: Maybe<Array<Maybe<CycleConnection_Id>>>;
  cancelled?: Maybe<Array<Maybe<CycleConnectionCancelled>>>;
  closedAt?: Maybe<Array<Maybe<CycleConnectionClosedAt>>>;
  createdAt?: Maybe<Array<Maybe<CycleConnectionCreatedAt>>>;
  expirationReminded?: Maybe<Array<Maybe<CycleConnectionExpirationReminded>>>;
  id?: Maybe<Array<Maybe<CycleConnectionId>>>;
  process?: Maybe<Array<Maybe<CycleConnectionProcess>>>;
  published?: Maybe<Array<Maybe<CycleConnectionPublished>>>;
  publishedAt?: Maybe<Array<Maybe<CycleConnectionPublishedAt>>>;
  requestedAt?: Maybe<Array<Maybe<CycleConnectionRequestedAt>>>;
  score?: Maybe<Array<Maybe<CycleConnectionScore>>>;
  updatedAt?: Maybe<Array<Maybe<CycleConnectionUpdatedAt>>>;
};

export type CycleInput = {
  cancelled: Scalars['Boolean'];
  closedAt?: InputMaybe<Scalars['DateTime']>;
  created_by?: InputMaybe<Scalars['ID']>;
  cycleevaluations?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  documents?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  expirationReminded: Scalars['Boolean'];
  process?: InputMaybe<Scalars['ID']>;
  published: Scalars['Boolean'];
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  requestedAt?: InputMaybe<Scalars['DateTime']>;
  score?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['ID']>;
};

/** Bewertungsrunde eines Prüfers */
export type Cycleevaluation = {
  __typename?: 'Cycleevaluation';
  _id: Scalars['ID'];
  closedAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  criterionevaluations: Scalars['JSON'];
  cycle?: Maybe<Cycle>;
  evaluator?: Maybe<Evaluator>;
  expirationReminded: Scalars['Boolean'];
  id: Scalars['ID'];
  score: Scalars['Int'];
};

export type CycleevaluationAggregator = {
  __typename?: 'CycleevaluationAggregator';
  avg?: Maybe<CycleevaluationAggregatorAvg>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<CycleevaluationAggregatorMax>;
  min?: Maybe<CycleevaluationAggregatorMin>;
  sum?: Maybe<CycleevaluationAggregatorSum>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type CycleevaluationAggregatorAvg = {
  __typename?: 'CycleevaluationAggregatorAvg';
  score?: Maybe<Scalars['Float']>;
};

export type CycleevaluationAggregatorMax = {
  __typename?: 'CycleevaluationAggregatorMax';
  score?: Maybe<Scalars['Float']>;
};

export type CycleevaluationAggregatorMin = {
  __typename?: 'CycleevaluationAggregatorMin';
  score?: Maybe<Scalars['Float']>;
};

export type CycleevaluationAggregatorSum = {
  __typename?: 'CycleevaluationAggregatorSum';
  score?: Maybe<Scalars['Float']>;
};

export type CycleevaluationConnection = {
  __typename?: 'CycleevaluationConnection';
  aggregate?: Maybe<CycleevaluationAggregator>;
  groupBy?: Maybe<CycleevaluationGroupBy>;
  values: Array<Cycleevaluation>;
};

export type CycleevaluationConnectionClosedAt = {
  __typename?: 'CycleevaluationConnectionClosedAt';
  connection?: Maybe<CycleevaluationConnection>;
  key?: Maybe<Scalars['DateTime']>;
};

export type CycleevaluationConnectionComment = {
  __typename?: 'CycleevaluationConnectionComment';
  connection?: Maybe<CycleevaluationConnection>;
  key?: Maybe<Scalars['String']>;
};

export type CycleevaluationConnectionCriterionevaluations = {
  __typename?: 'CycleevaluationConnectionCriterionevaluations';
  connection?: Maybe<CycleevaluationConnection>;
  key?: Maybe<Scalars['JSON']>;
};

export type CycleevaluationConnectionCycle = {
  __typename?: 'CycleevaluationConnectionCycle';
  connection?: Maybe<CycleevaluationConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type CycleevaluationConnectionEvaluator = {
  __typename?: 'CycleevaluationConnectionEvaluator';
  connection?: Maybe<CycleevaluationConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type CycleevaluationConnectionExpirationReminded = {
  __typename?: 'CycleevaluationConnectionExpirationReminded';
  connection?: Maybe<CycleevaluationConnection>;
  key?: Maybe<Scalars['Boolean']>;
};

export type CycleevaluationConnectionId = {
  __typename?: 'CycleevaluationConnectionId';
  connection?: Maybe<CycleevaluationConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type CycleevaluationConnectionScore = {
  __typename?: 'CycleevaluationConnectionScore';
  connection?: Maybe<CycleevaluationConnection>;
  key?: Maybe<Scalars['Int']>;
};

export type CycleevaluationConnection_Id = {
  __typename?: 'CycleevaluationConnection_id';
  connection?: Maybe<CycleevaluationConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type CycleevaluationGroupBy = {
  __typename?: 'CycleevaluationGroupBy';
  _id?: Maybe<Array<Maybe<CycleevaluationConnection_Id>>>;
  closedAt?: Maybe<Array<Maybe<CycleevaluationConnectionClosedAt>>>;
  comment?: Maybe<Array<Maybe<CycleevaluationConnectionComment>>>;
  criterionevaluations?: Maybe<
    Array<Maybe<CycleevaluationConnectionCriterionevaluations>>
  >;
  cycle?: Maybe<Array<Maybe<CycleevaluationConnectionCycle>>>;
  evaluator?: Maybe<Array<Maybe<CycleevaluationConnectionEvaluator>>>;
  expirationReminded?: Maybe<
    Array<Maybe<CycleevaluationConnectionExpirationReminded>>
  >;
  id?: Maybe<Array<Maybe<CycleevaluationConnectionId>>>;
  score?: Maybe<Array<Maybe<CycleevaluationConnectionScore>>>;
};

export type CycleevaluationInput = {
  closedAt?: InputMaybe<Scalars['DateTime']>;
  comment?: InputMaybe<Scalars['String']>;
  created_by?: InputMaybe<Scalars['ID']>;
  criterionevaluations?: InputMaybe<Scalars['JSON']>;
  cycle?: InputMaybe<Scalars['ID']>;
  evaluator?: InputMaybe<Scalars['ID']>;
  expirationReminded: Scalars['Boolean'];
  score?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['ID']>;
};

export type Document = {
  __typename?: 'Document';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  cycle?: Maybe<Cycle>;
  documenttype?: Maybe<Documenttype>;
  file?: Maybe<UploadFile>;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type DocumentAggregator = {
  __typename?: 'DocumentAggregator';
  count?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type DocumentConnection = {
  __typename?: 'DocumentConnection';
  aggregate?: Maybe<DocumentAggregator>;
  groupBy?: Maybe<DocumentGroupBy>;
  values: Array<Document>;
};

export type DocumentConnectionCreatedAt = {
  __typename?: 'DocumentConnectionCreatedAt';
  connection?: Maybe<DocumentConnection>;
  key?: Maybe<Scalars['DateTime']>;
};

export type DocumentConnectionCycle = {
  __typename?: 'DocumentConnectionCycle';
  connection?: Maybe<DocumentConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type DocumentConnectionDocumenttype = {
  __typename?: 'DocumentConnectionDocumenttype';
  connection?: Maybe<DocumentConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type DocumentConnectionFile = {
  __typename?: 'DocumentConnectionFile';
  connection?: Maybe<DocumentConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type DocumentConnectionId = {
  __typename?: 'DocumentConnectionId';
  connection?: Maybe<DocumentConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type DocumentConnectionUpdatedAt = {
  __typename?: 'DocumentConnectionUpdatedAt';
  connection?: Maybe<DocumentConnection>;
  key?: Maybe<Scalars['DateTime']>;
};

export type DocumentConnection_Id = {
  __typename?: 'DocumentConnection_id';
  connection?: Maybe<DocumentConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type DocumentGroupBy = {
  __typename?: 'DocumentGroupBy';
  _id?: Maybe<Array<Maybe<DocumentConnection_Id>>>;
  createdAt?: Maybe<Array<Maybe<DocumentConnectionCreatedAt>>>;
  cycle?: Maybe<Array<Maybe<DocumentConnectionCycle>>>;
  documenttype?: Maybe<Array<Maybe<DocumentConnectionDocumenttype>>>;
  file?: Maybe<Array<Maybe<DocumentConnectionFile>>>;
  id?: Maybe<Array<Maybe<DocumentConnectionId>>>;
  updatedAt?: Maybe<Array<Maybe<DocumentConnectionUpdatedAt>>>;
};

export type DocumentInput = {
  created_by?: InputMaybe<Scalars['ID']>;
  cycle?: InputMaybe<Scalars['ID']>;
  documenttype?: InputMaybe<Scalars['ID']>;
  file?: InputMaybe<Scalars['ID']>;
  updated_by?: InputMaybe<Scalars['ID']>;
};

/** Dokument als Bewertungsgrundlage */
export type Documenttype = {
  __typename?: 'Documenttype';
  _id: Scalars['ID'];
  categories: Array<Category>;
  documents: Array<Document>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Dokument als Bewertungsgrundlage */
export type DocumenttypeCategoriesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

/** Dokument als Bewertungsgrundlage */
export type DocumenttypeDocumentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type DocumenttypeAggregator = {
  __typename?: 'DocumenttypeAggregator';
  count?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type DocumenttypeConnection = {
  __typename?: 'DocumenttypeConnection';
  aggregate?: Maybe<DocumenttypeAggregator>;
  groupBy?: Maybe<DocumenttypeGroupBy>;
  values: Array<Documenttype>;
};

export type DocumenttypeConnectionId = {
  __typename?: 'DocumenttypeConnectionId';
  connection?: Maybe<DocumenttypeConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type DocumenttypeConnectionName = {
  __typename?: 'DocumenttypeConnectionName';
  connection?: Maybe<DocumenttypeConnection>;
  key?: Maybe<Scalars['String']>;
};

export type DocumenttypeConnection_Id = {
  __typename?: 'DocumenttypeConnection_id';
  connection?: Maybe<DocumenttypeConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type DocumenttypeGroupBy = {
  __typename?: 'DocumenttypeGroupBy';
  _id?: Maybe<Array<Maybe<DocumenttypeConnection_Id>>>;
  id?: Maybe<Array<Maybe<DocumenttypeConnectionId>>>;
  name?: Maybe<Array<Maybe<DocumenttypeConnectionName>>>;
};

export type DocumenttypeInput = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  created_by?: InputMaybe<Scalars['ID']>;
  documents?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  updated_by?: InputMaybe<Scalars['ID']>;
};

export enum Enum_Contact_Gender {
  Female = 'female',
  Male = 'male',
}

export enum Enum_Contact_Title {
  Dr = 'dr',
  None = 'none',
  Prof = 'prof',
}

export enum Enum_Userinfo_Gender {
  Female = 'female',
  Male = 'male',
}

export enum Enum_Userinfo_Title {
  Dr = 'dr',
  None = 'none',
  Prof = 'prof',
}

export type Evaluator = {
  __typename?: 'Evaluator';
  _id: Scalars['ID'];
  categories: Array<Category>;
  courses: Array<Course>;
  createdAt: Scalars['DateTime'];
  cycleevaluations: Array<Cycleevaluation>;
  id: Scalars['ID'];
  phone?: Maybe<Scalars['String']>;
  short: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<UsersPermissionsUser>;
};

export type EvaluatorCategoriesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type EvaluatorCoursesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type EvaluatorCycleevaluationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type EvaluatorAggregator = {
  __typename?: 'EvaluatorAggregator';
  count?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type EvaluatorConnection = {
  __typename?: 'EvaluatorConnection';
  aggregate?: Maybe<EvaluatorAggregator>;
  groupBy?: Maybe<EvaluatorGroupBy>;
  values: Array<Evaluator>;
};

export type EvaluatorConnectionCreatedAt = {
  __typename?: 'EvaluatorConnectionCreatedAt';
  connection?: Maybe<EvaluatorConnection>;
  key?: Maybe<Scalars['DateTime']>;
};

export type EvaluatorConnectionId = {
  __typename?: 'EvaluatorConnectionId';
  connection?: Maybe<EvaluatorConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type EvaluatorConnectionPhone = {
  __typename?: 'EvaluatorConnectionPhone';
  connection?: Maybe<EvaluatorConnection>;
  key?: Maybe<Scalars['String']>;
};

export type EvaluatorConnectionShort = {
  __typename?: 'EvaluatorConnectionShort';
  connection?: Maybe<EvaluatorConnection>;
  key?: Maybe<Scalars['String']>;
};

export type EvaluatorConnectionUpdatedAt = {
  __typename?: 'EvaluatorConnectionUpdatedAt';
  connection?: Maybe<EvaluatorConnection>;
  key?: Maybe<Scalars['DateTime']>;
};

export type EvaluatorConnectionUser = {
  __typename?: 'EvaluatorConnectionUser';
  connection?: Maybe<EvaluatorConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type EvaluatorConnection_Id = {
  __typename?: 'EvaluatorConnection_id';
  connection?: Maybe<EvaluatorConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type EvaluatorGroupBy = {
  __typename?: 'EvaluatorGroupBy';
  _id?: Maybe<Array<Maybe<EvaluatorConnection_Id>>>;
  createdAt?: Maybe<Array<Maybe<EvaluatorConnectionCreatedAt>>>;
  id?: Maybe<Array<Maybe<EvaluatorConnectionId>>>;
  phone?: Maybe<Array<Maybe<EvaluatorConnectionPhone>>>;
  short?: Maybe<Array<Maybe<EvaluatorConnectionShort>>>;
  updatedAt?: Maybe<Array<Maybe<EvaluatorConnectionUpdatedAt>>>;
  user?: Maybe<Array<Maybe<EvaluatorConnectionUser>>>;
};

export type EvaluatorInput = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  courses?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  created_by?: InputMaybe<Scalars['ID']>;
  cycleevaluations?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  phone?: InputMaybe<Scalars['String']>;
  short?: InputMaybe<Scalars['String']>;
  updated_by?: InputMaybe<Scalars['ID']>;
  user?: InputMaybe<Scalars['ID']>;
};

export type FileInfoInput = {
  alternativeText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type FileInput = {
  alternativeText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  created_by?: InputMaybe<Scalars['ID']>;
  ext?: InputMaybe<Scalars['String']>;
  formats?: InputMaybe<Scalars['JSON']>;
  hash: Scalars['String'];
  height?: InputMaybe<Scalars['Int']>;
  mime: Scalars['String'];
  name: Scalars['String'];
  previewUrl?: InputMaybe<Scalars['String']>;
  provider: Scalars['String'];
  provider_metadata?: InputMaybe<Scalars['JSON']>;
  related?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  size: Scalars['Float'];
  updated_by?: InputMaybe<Scalars['ID']>;
  url: Scalars['String'];
  width?: InputMaybe<Scalars['Int']>;
};

export type InputId = {
  id: Scalars['ID'];
};

export type Morph =
  | Category
  | CategoryAggregator
  | CategoryConnection
  | CategoryConnectionEnabled
  | CategoryConnectionId
  | CategoryConnectionName
  | CategoryConnection_Id
  | CategoryGroupBy
  | Contact
  | ContactAggregator
  | ContactConnection
  | ContactConnectionEmail
  | ContactConnectionFirstname
  | ContactConnectionGender
  | ContactConnectionId
  | ContactConnectionLastname
  | ContactConnectionPhone
  | ContactConnectionProvider
  | ContactConnectionTitle
  | ContactConnection_Id
  | ContactGroupBy
  | Course
  | CourseAggregator
  | CourseConnection
  | CourseConnectionCategory
  | CourseConnectionComment
  | CourseConnectionContact
  | CourseConnectionCreatedAt
  | CourseConnectionEnabled
  | CourseConnectionId
  | CourseConnectionPredecessor
  | CourseConnectionProvider
  | CourseConnectionState
  | CourseConnectionUpdatedAt
  | CourseConnection_Id
  | CourseGroupBy
  | Criterion
  | CriterionAggregator
  | CriterionConnection
  | CriterionConnectionId
  | CriterionConnectionName
  | CriterionConnection_Id
  | CriterionGroupBy
  | Cycle
  | CycleAggregator
  | CycleAggregatorAvg
  | CycleAggregatorMax
  | CycleAggregatorMin
  | CycleAggregatorSum
  | CycleConnection
  | CycleConnectionCancelled
  | CycleConnectionClosedAt
  | CycleConnectionCreatedAt
  | CycleConnectionExpirationReminded
  | CycleConnectionId
  | CycleConnectionProcess
  | CycleConnectionPublished
  | CycleConnectionPublishedAt
  | CycleConnectionRequestedAt
  | CycleConnectionScore
  | CycleConnectionUpdatedAt
  | CycleConnection_Id
  | CycleGroupBy
  | Cycleevaluation
  | CycleevaluationAggregator
  | CycleevaluationAggregatorAvg
  | CycleevaluationAggregatorMax
  | CycleevaluationAggregatorMin
  | CycleevaluationAggregatorSum
  | CycleevaluationConnection
  | CycleevaluationConnectionClosedAt
  | CycleevaluationConnectionComment
  | CycleevaluationConnectionCriterionevaluations
  | CycleevaluationConnectionCycle
  | CycleevaluationConnectionEvaluator
  | CycleevaluationConnectionExpirationReminded
  | CycleevaluationConnectionId
  | CycleevaluationConnectionScore
  | CycleevaluationConnection_Id
  | CycleevaluationGroupBy
  | Document
  | DocumentAggregator
  | DocumentConnection
  | DocumentConnectionCreatedAt
  | DocumentConnectionCycle
  | DocumentConnectionDocumenttype
  | DocumentConnectionFile
  | DocumentConnectionId
  | DocumentConnectionUpdatedAt
  | DocumentConnection_Id
  | DocumentGroupBy
  | Documenttype
  | DocumenttypeAggregator
  | DocumenttypeConnection
  | DocumenttypeConnectionId
  | DocumenttypeConnectionName
  | DocumenttypeConnection_Id
  | DocumenttypeGroupBy
  | Evaluator
  | EvaluatorAggregator
  | EvaluatorConnection
  | EvaluatorConnectionCreatedAt
  | EvaluatorConnectionId
  | EvaluatorConnectionPhone
  | EvaluatorConnectionShort
  | EvaluatorConnectionUpdatedAt
  | EvaluatorConnectionUser
  | EvaluatorConnection_Id
  | EvaluatorGroupBy
  | MyEvaluation
  | Process
  | ProcessAggregator
  | ProcessConnection
  | ProcessConnectionCertification
  | ProcessConnectionConfirmedAt
  | ProcessConnectionCourse
  | ProcessConnectionCreatedAt
  | ProcessConnectionExpirationReminded
  | ProcessConnectionId
  | ProcessConnectionPaidAt
  | ProcessConnectionUpdatedAt
  | ProcessConnection_Id
  | ProcessGroupBy
  | Provider
  | ProviderAggregator
  | ProviderConnection
  | ProviderConnectionCity
  | ProviderConnectionCreatedAt
  | ProviderConnectionEnabled
  | ProviderConnectionHomepage
  | ProviderConnectionId
  | ProviderConnectionName
  | ProviderConnectionPhone
  | ProviderConnectionStreet
  | ProviderConnectionStreetnumber
  | ProviderConnectionUpdatedAt
  | ProviderConnectionZip
  | ProviderConnection_Id
  | ProviderGroupBy
  | UploadFile
  | UploadFileAggregator
  | UploadFileAggregatorAvg
  | UploadFileAggregatorMax
  | UploadFileAggregatorMin
  | UploadFileAggregatorSum
  | UploadFileConnection
  | UploadFileConnectionAlternativeText
  | UploadFileConnectionCaption
  | UploadFileConnectionCreatedAt
  | UploadFileConnectionExt
  | UploadFileConnectionFormats
  | UploadFileConnectionHash
  | UploadFileConnectionHeight
  | UploadFileConnectionId
  | UploadFileConnectionMime
  | UploadFileConnectionName
  | UploadFileConnectionPreviewUrl
  | UploadFileConnectionProvider
  | UploadFileConnectionProvider_Metadata
  | UploadFileConnectionSize
  | UploadFileConnectionUpdatedAt
  | UploadFileConnectionUrl
  | UploadFileConnectionWidth
  | UploadFileConnection_Id
  | UploadFileGroupBy
  | UserPermissionsPasswordPayload
  | Userinfo
  | UserinfoAggregator
  | UserinfoConnection
  | UserinfoConnectionFirstname
  | UserinfoConnectionGender
  | UserinfoConnectionId
  | UserinfoConnectionLastname
  | UserinfoConnectionTitle
  | UserinfoConnection_Id
  | UserinfoGroupBy
  | UsersPermissionsLoginPayload
  | UsersPermissionsMe
  | UsersPermissionsMeRole
  | UsersPermissionsPermission
  | UsersPermissionsRole
  | UsersPermissionsRoleAggregator
  | UsersPermissionsRoleConnection
  | UsersPermissionsRoleConnectionDescription
  | UsersPermissionsRoleConnectionId
  | UsersPermissionsRoleConnectionName
  | UsersPermissionsRoleConnectionType
  | UsersPermissionsRoleConnection_Id
  | UsersPermissionsRoleGroupBy
  | UsersPermissionsUser
  | UsersPermissionsUserAggregator
  | UsersPermissionsUserConnection
  | UsersPermissionsUserConnectionBlocked
  | UsersPermissionsUserConnectionConfirmed
  | UsersPermissionsUserConnectionCreatedAt
  | UsersPermissionsUserConnectionEmail
  | UsersPermissionsUserConnectionId
  | UsersPermissionsUserConnectionInfo
  | UsersPermissionsUserConnectionProvider
  | UsersPermissionsUserConnectionRole
  | UsersPermissionsUserConnectionUpdatedAt
  | UsersPermissionsUserConnectionUsername
  | UsersPermissionsUserConnection_Id
  | UsersPermissionsUserGroupBy
  | CreateCategoryPayload
  | CreateContactPayload
  | CreateCoursePayload
  | CreateCriterionPayload
  | CreateCyclePayload
  | CreateCycleevaluationPayload
  | CreateDocumentPayload
  | CreateDocumenttypePayload
  | CreateEvaluatorPayload
  | CreateProcessPayload
  | CreateProviderPayload
  | CreateRolePayload
  | CreateUserPayload
  | CreateUserinfoPayload
  | DeleteCategoryPayload
  | DeleteContactPayload
  | DeleteCoursePayload
  | DeleteCriterionPayload
  | DeleteCyclePayload
  | DeleteCycleevaluationPayload
  | DeleteDocumentPayload
  | DeleteDocumenttypePayload
  | DeleteEvaluatorPayload
  | DeleteFilePayload
  | DeleteProcessPayload
  | DeleteProviderPayload
  | DeleteRolePayload
  | DeleteUserPayload
  | DeleteUserinfoPayload
  | UpdateCategoryPayload
  | UpdateContactPayload
  | UpdateCoursePayload
  | UpdateCriterionPayload
  | UpdateCyclePayload
  | UpdateCycleevaluationPayload
  | UpdateDocumentPayload
  | UpdateDocumenttypePayload
  | UpdateEvaluatorPayload
  | UpdateProcessPayload
  | UpdateProviderPayload
  | UpdateRolePayload
  | UpdateUserPayload
  | UpdateUserinfoPayload;

export type Mutation = {
  __typename?: 'Mutation';
  createCategory?: Maybe<CreateCategoryPayload>;
  createContact?: Maybe<CreateContactPayload>;
  createCourse?: Maybe<CreateCoursePayload>;
  createCriterion?: Maybe<CreateCriterionPayload>;
  createCycle?: Maybe<CreateCyclePayload>;
  createCycleevaluation?: Maybe<CreateCycleevaluationPayload>;
  createDocument?: Maybe<CreateDocumentPayload>;
  createDocumenttype?: Maybe<CreateDocumenttypePayload>;
  createEvaluator?: Maybe<CreateEvaluatorPayload>;
  createProcess?: Maybe<CreateProcessPayload>;
  createProvider?: Maybe<CreateProviderPayload>;
  /** Create a new role */
  createRole?: Maybe<CreateRolePayload>;
  /** Create a new user */
  createUser?: Maybe<CreateUserPayload>;
  createUserinfo?: Maybe<CreateUserinfoPayload>;
  customCreateProvider?: Maybe<CreateProviderPayload>;
  customUpdateProvider?: Maybe<UpdateProviderPayload>;
  deleteCategory?: Maybe<DeleteCategoryPayload>;
  deleteContact?: Maybe<DeleteContactPayload>;
  deleteCourse?: Maybe<DeleteCoursePayload>;
  deleteCriterion?: Maybe<DeleteCriterionPayload>;
  deleteCycle?: Maybe<DeleteCyclePayload>;
  deleteCycleevaluation?: Maybe<DeleteCycleevaluationPayload>;
  deleteDocument?: Maybe<DeleteDocumentPayload>;
  deleteDocumenttype?: Maybe<DeleteDocumenttypePayload>;
  deleteEvaluator?: Maybe<DeleteEvaluatorPayload>;
  /** Delete one file */
  deleteFile?: Maybe<DeleteFilePayload>;
  deleteProcess?: Maybe<DeleteProcessPayload>;
  deleteProvider?: Maybe<DeleteProviderPayload>;
  /** Delete an existing role */
  deleteRole?: Maybe<DeleteRolePayload>;
  /** Delete an existing user */
  deleteUser?: Maybe<DeleteUserPayload>;
  deleteUserinfo?: Maybe<DeleteUserinfoPayload>;
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
  forgotPassword?: Maybe<UserPermissionsPasswordPayload>;
  login: UsersPermissionsLoginPayload;
  multipleUpload: Array<Maybe<UploadFile>>;
  register: UsersPermissionsLoginPayload;
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  updateCategory?: Maybe<UpdateCategoryPayload>;
  updateContact?: Maybe<UpdateContactPayload>;
  updateCourse?: Maybe<UpdateCoursePayload>;
  updateCriterion?: Maybe<UpdateCriterionPayload>;
  updateCycle?: Maybe<UpdateCyclePayload>;
  updateCycleevaluation?: Maybe<UpdateCycleevaluationPayload>;
  updateDocument?: Maybe<UpdateDocumentPayload>;
  updateDocumenttype?: Maybe<UpdateDocumenttypePayload>;
  updateEvaluator?: Maybe<UpdateEvaluatorPayload>;
  updateFileInfo: UploadFile;
  updateProcess?: Maybe<UpdateProcessPayload>;
  updateProvider?: Maybe<UpdateProviderPayload>;
  /** Update an existing role */
  updateRole?: Maybe<UpdateRolePayload>;
  /** Update an existing user */
  updateUser?: Maybe<UpdateUserPayload>;
  updateUserinfo?: Maybe<UpdateUserinfoPayload>;
  upload: UploadFile;
};

export type MutationCreateCategoryArgs = {
  input?: InputMaybe<CreateCategoryInput>;
};

export type MutationCreateContactArgs = {
  input?: InputMaybe<CreateContactInput>;
};

export type MutationCreateCourseArgs = {
  input?: InputMaybe<CreateCourseInput>;
};

export type MutationCreateCriterionArgs = {
  input?: InputMaybe<CreateCriterionInput>;
};

export type MutationCreateCycleArgs = {
  input?: InputMaybe<CreateCycleInput>;
};

export type MutationCreateCycleevaluationArgs = {
  input?: InputMaybe<CreateCycleevaluationInput>;
};

export type MutationCreateDocumentArgs = {
  input?: InputMaybe<CreateDocumentInput>;
};

export type MutationCreateDocumenttypeArgs = {
  input?: InputMaybe<CreateDocumenttypeInput>;
};

export type MutationCreateEvaluatorArgs = {
  input?: InputMaybe<CreateEvaluatorInput>;
};

export type MutationCreateProcessArgs = {
  input?: InputMaybe<CreateProcessInput>;
};

export type MutationCreateProviderArgs = {
  input?: InputMaybe<CreateProviderInput>;
};

export type MutationCreateRoleArgs = {
  input?: InputMaybe<CreateRoleInput>;
};

export type MutationCreateUserArgs = {
  input?: InputMaybe<CreateUserInput>;
};

export type MutationCreateUserinfoArgs = {
  input?: InputMaybe<CreateUserinfoInput>;
};

export type MutationCustomCreateProviderArgs = {
  contacts?: InputMaybe<Array<InputMaybe<ContactInput>>>;
  provider: ProviderInput;
};

export type MutationCustomUpdateProviderArgs = {
  contacts?: InputMaybe<Array<InputMaybe<EditContactInput>>>;
  id: Scalars['ID'];
  provider?: InputMaybe<EditProviderInput>;
};

export type MutationDeleteCategoryArgs = {
  input?: InputMaybe<DeleteCategoryInput>;
};

export type MutationDeleteContactArgs = {
  input?: InputMaybe<DeleteContactInput>;
};

export type MutationDeleteCourseArgs = {
  input?: InputMaybe<DeleteCourseInput>;
};

export type MutationDeleteCriterionArgs = {
  input?: InputMaybe<DeleteCriterionInput>;
};

export type MutationDeleteCycleArgs = {
  input?: InputMaybe<DeleteCycleInput>;
};

export type MutationDeleteCycleevaluationArgs = {
  input?: InputMaybe<DeleteCycleevaluationInput>;
};

export type MutationDeleteDocumentArgs = {
  input?: InputMaybe<DeleteDocumentInput>;
};

export type MutationDeleteDocumenttypeArgs = {
  input?: InputMaybe<DeleteDocumenttypeInput>;
};

export type MutationDeleteEvaluatorArgs = {
  input?: InputMaybe<DeleteEvaluatorInput>;
};

export type MutationDeleteFileArgs = {
  input?: InputMaybe<DeleteFileInput>;
};

export type MutationDeleteProcessArgs = {
  input?: InputMaybe<DeleteProcessInput>;
};

export type MutationDeleteProviderArgs = {
  input?: InputMaybe<DeleteProviderInput>;
};

export type MutationDeleteRoleArgs = {
  input?: InputMaybe<DeleteRoleInput>;
};

export type MutationDeleteUserArgs = {
  input?: InputMaybe<DeleteUserInput>;
};

export type MutationDeleteUserinfoArgs = {
  input?: InputMaybe<DeleteUserinfoInput>;
};

export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String'];
};

export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};

export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};

export type MutationMultipleUploadArgs = {
  field?: InputMaybe<Scalars['String']>;
  files: Array<InputMaybe<Scalars['Upload']>>;
  ref?: InputMaybe<Scalars['String']>;
  refId?: InputMaybe<Scalars['ID']>;
  source?: InputMaybe<Scalars['String']>;
};

export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};

export type MutationResetPasswordArgs = {
  code: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};

export type MutationUpdateCategoryArgs = {
  input?: InputMaybe<UpdateCategoryInput>;
};

export type MutationUpdateContactArgs = {
  input?: InputMaybe<UpdateContactInput>;
};

export type MutationUpdateCourseArgs = {
  input?: InputMaybe<UpdateCourseInput>;
};

export type MutationUpdateCriterionArgs = {
  input?: InputMaybe<UpdateCriterionInput>;
};

export type MutationUpdateCycleArgs = {
  input?: InputMaybe<UpdateCycleInput>;
};

export type MutationUpdateCycleevaluationArgs = {
  input?: InputMaybe<UpdateCycleevaluationInput>;
};

export type MutationUpdateDocumentArgs = {
  input?: InputMaybe<UpdateDocumentInput>;
};

export type MutationUpdateDocumenttypeArgs = {
  input?: InputMaybe<UpdateDocumenttypeInput>;
};

export type MutationUpdateEvaluatorArgs = {
  input?: InputMaybe<UpdateEvaluatorInput>;
};

export type MutationUpdateFileInfoArgs = {
  id: Scalars['ID'];
  info: FileInfoInput;
};

export type MutationUpdateProcessArgs = {
  input?: InputMaybe<UpdateProcessInput>;
};

export type MutationUpdateProviderArgs = {
  input?: InputMaybe<UpdateProviderInput>;
};

export type MutationUpdateRoleArgs = {
  input?: InputMaybe<UpdateRoleInput>;
};

export type MutationUpdateUserArgs = {
  input?: InputMaybe<UpdateUserInput>;
};

export type MutationUpdateUserinfoArgs = {
  input?: InputMaybe<UpdateUserinfoInput>;
};

export type MutationUploadArgs = {
  field?: InputMaybe<Scalars['String']>;
  file: Scalars['Upload'];
  info?: InputMaybe<FileInfoInput>;
  ref?: InputMaybe<Scalars['String']>;
  refId?: InputMaybe<Scalars['ID']>;
  source?: InputMaybe<Scalars['String']>;
};

export type MyEvaluation = {
  __typename?: 'MyEvaluation';
  course: Scalars['ID'];
  cycleevaluation: Scalars['ID'];
  open: Scalars['Boolean'];
};

/** Zertifizierungs- oder Nachmeldungsprozess */
export type Process = {
  __typename?: 'Process';
  _id: Scalars['ID'];
  certification: Scalars['Boolean'];
  confirmedAt?: Maybe<Scalars['DateTime']>;
  course?: Maybe<Course>;
  createdAt: Scalars['DateTime'];
  cycles: Array<Cycle>;
  expirationReminded: Scalars['Boolean'];
  id: Scalars['ID'];
  paidAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

/** Zertifizierungs- oder Nachmeldungsprozess */
export type ProcessCyclesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type ProcessAggregator = {
  __typename?: 'ProcessAggregator';
  count?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ProcessConnection = {
  __typename?: 'ProcessConnection';
  aggregate?: Maybe<ProcessAggregator>;
  groupBy?: Maybe<ProcessGroupBy>;
  values: Array<Process>;
};

export type ProcessConnectionCertification = {
  __typename?: 'ProcessConnectionCertification';
  connection?: Maybe<ProcessConnection>;
  key?: Maybe<Scalars['Boolean']>;
};

export type ProcessConnectionConfirmedAt = {
  __typename?: 'ProcessConnectionConfirmedAt';
  connection?: Maybe<ProcessConnection>;
  key?: Maybe<Scalars['DateTime']>;
};

export type ProcessConnectionCourse = {
  __typename?: 'ProcessConnectionCourse';
  connection?: Maybe<ProcessConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type ProcessConnectionCreatedAt = {
  __typename?: 'ProcessConnectionCreatedAt';
  connection?: Maybe<ProcessConnection>;
  key?: Maybe<Scalars['DateTime']>;
};

export type ProcessConnectionExpirationReminded = {
  __typename?: 'ProcessConnectionExpirationReminded';
  connection?: Maybe<ProcessConnection>;
  key?: Maybe<Scalars['Boolean']>;
};

export type ProcessConnectionId = {
  __typename?: 'ProcessConnectionId';
  connection?: Maybe<ProcessConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type ProcessConnectionPaidAt = {
  __typename?: 'ProcessConnectionPaidAt';
  connection?: Maybe<ProcessConnection>;
  key?: Maybe<Scalars['DateTime']>;
};

export type ProcessConnectionUpdatedAt = {
  __typename?: 'ProcessConnectionUpdatedAt';
  connection?: Maybe<ProcessConnection>;
  key?: Maybe<Scalars['DateTime']>;
};

export type ProcessConnection_Id = {
  __typename?: 'ProcessConnection_id';
  connection?: Maybe<ProcessConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type ProcessGroupBy = {
  __typename?: 'ProcessGroupBy';
  _id?: Maybe<Array<Maybe<ProcessConnection_Id>>>;
  certification?: Maybe<Array<Maybe<ProcessConnectionCertification>>>;
  confirmedAt?: Maybe<Array<Maybe<ProcessConnectionConfirmedAt>>>;
  course?: Maybe<Array<Maybe<ProcessConnectionCourse>>>;
  createdAt?: Maybe<Array<Maybe<ProcessConnectionCreatedAt>>>;
  expirationReminded?: Maybe<Array<Maybe<ProcessConnectionExpirationReminded>>>;
  id?: Maybe<Array<Maybe<ProcessConnectionId>>>;
  paidAt?: Maybe<Array<Maybe<ProcessConnectionPaidAt>>>;
  updatedAt?: Maybe<Array<Maybe<ProcessConnectionUpdatedAt>>>;
};

export type ProcessInput = {
  certification?: InputMaybe<Scalars['Boolean']>;
  confirmedAt?: InputMaybe<Scalars['DateTime']>;
  course?: InputMaybe<Scalars['ID']>;
  created_by?: InputMaybe<Scalars['ID']>;
  cycles?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  expirationReminded: Scalars['Boolean'];
  paidAt?: InputMaybe<Scalars['DateTime']>;
  updated_by?: InputMaybe<Scalars['ID']>;
};

export type Provider = {
  __typename?: 'Provider';
  _id: Scalars['ID'];
  city: Scalars['String'];
  contacts: Array<Contact>;
  courses: Array<Course>;
  createdAt: Scalars['DateTime'];
  enabled: Scalars['Boolean'];
  homepage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetnumber?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  zip: Scalars['String'];
};

export type ProviderContactsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type ProviderCoursesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type ProviderAggregator = {
  __typename?: 'ProviderAggregator';
  count?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ProviderConnection = {
  __typename?: 'ProviderConnection';
  aggregate?: Maybe<ProviderAggregator>;
  groupBy?: Maybe<ProviderGroupBy>;
  values: Array<Provider>;
};

export type ProviderConnectionCity = {
  __typename?: 'ProviderConnectionCity';
  connection?: Maybe<ProviderConnection>;
  key?: Maybe<Scalars['String']>;
};

export type ProviderConnectionCreatedAt = {
  __typename?: 'ProviderConnectionCreatedAt';
  connection?: Maybe<ProviderConnection>;
  key?: Maybe<Scalars['DateTime']>;
};

export type ProviderConnectionEnabled = {
  __typename?: 'ProviderConnectionEnabled';
  connection?: Maybe<ProviderConnection>;
  key?: Maybe<Scalars['Boolean']>;
};

export type ProviderConnectionHomepage = {
  __typename?: 'ProviderConnectionHomepage';
  connection?: Maybe<ProviderConnection>;
  key?: Maybe<Scalars['String']>;
};

export type ProviderConnectionId = {
  __typename?: 'ProviderConnectionId';
  connection?: Maybe<ProviderConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type ProviderConnectionName = {
  __typename?: 'ProviderConnectionName';
  connection?: Maybe<ProviderConnection>;
  key?: Maybe<Scalars['String']>;
};

export type ProviderConnectionPhone = {
  __typename?: 'ProviderConnectionPhone';
  connection?: Maybe<ProviderConnection>;
  key?: Maybe<Scalars['String']>;
};

export type ProviderConnectionStreet = {
  __typename?: 'ProviderConnectionStreet';
  connection?: Maybe<ProviderConnection>;
  key?: Maybe<Scalars['String']>;
};

export type ProviderConnectionStreetnumber = {
  __typename?: 'ProviderConnectionStreetnumber';
  connection?: Maybe<ProviderConnection>;
  key?: Maybe<Scalars['String']>;
};

export type ProviderConnectionUpdatedAt = {
  __typename?: 'ProviderConnectionUpdatedAt';
  connection?: Maybe<ProviderConnection>;
  key?: Maybe<Scalars['DateTime']>;
};

export type ProviderConnectionZip = {
  __typename?: 'ProviderConnectionZip';
  connection?: Maybe<ProviderConnection>;
  key?: Maybe<Scalars['String']>;
};

export type ProviderConnection_Id = {
  __typename?: 'ProviderConnection_id';
  connection?: Maybe<ProviderConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type ProviderGroupBy = {
  __typename?: 'ProviderGroupBy';
  _id?: Maybe<Array<Maybe<ProviderConnection_Id>>>;
  city?: Maybe<Array<Maybe<ProviderConnectionCity>>>;
  createdAt?: Maybe<Array<Maybe<ProviderConnectionCreatedAt>>>;
  enabled?: Maybe<Array<Maybe<ProviderConnectionEnabled>>>;
  homepage?: Maybe<Array<Maybe<ProviderConnectionHomepage>>>;
  id?: Maybe<Array<Maybe<ProviderConnectionId>>>;
  name?: Maybe<Array<Maybe<ProviderConnectionName>>>;
  phone?: Maybe<Array<Maybe<ProviderConnectionPhone>>>;
  street?: Maybe<Array<Maybe<ProviderConnectionStreet>>>;
  streetnumber?: Maybe<Array<Maybe<ProviderConnectionStreetnumber>>>;
  updatedAt?: Maybe<Array<Maybe<ProviderConnectionUpdatedAt>>>;
  zip?: Maybe<Array<Maybe<ProviderConnectionZip>>>;
};

export type ProviderInput = {
  city?: InputMaybe<Scalars['String']>;
  contacts?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  courses?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  created_by?: InputMaybe<Scalars['ID']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  homepage?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetnumber?: InputMaybe<Scalars['String']>;
  updated_by?: InputMaybe<Scalars['ID']>;
  zip?: InputMaybe<Scalars['String']>;
};

export enum PublicationState {
  Live = 'LIVE',
  Preview = 'PREVIEW',
}

export type Query = {
  __typename?: 'Query';
  categories: Array<Category>;
  categoriesConnection?: Maybe<CategoryConnection>;
  category?: Maybe<Category>;
  contact?: Maybe<Contact>;
  contacts: Array<Contact>;
  contactsConnection?: Maybe<ContactConnection>;
  course?: Maybe<Course>;
  courses: Array<Course>;
  coursesConnection?: Maybe<CourseConnection>;
  criteria: Array<Criterion>;
  criteriaConnection?: Maybe<CriterionConnection>;
  criterion?: Maybe<Criterion>;
  cycle?: Maybe<Cycle>;
  cycleevaluation?: Maybe<Cycleevaluation>;
  cycleevaluations: Array<Cycleevaluation>;
  cycleevaluationsConnection?: Maybe<CycleevaluationConnection>;
  cycles: Array<Cycle>;
  cyclesConnection?: Maybe<CycleConnection>;
  document?: Maybe<Document>;
  documents: Array<Document>;
  documentsConnection?: Maybe<DocumentConnection>;
  documenttype?: Maybe<Documenttype>;
  documenttypes: Array<Documenttype>;
  documenttypesConnection?: Maybe<DocumenttypeConnection>;
  evaluator?: Maybe<Evaluator>;
  evaluators: Array<Evaluator>;
  evaluatorsConnection?: Maybe<EvaluatorConnection>;
  files?: Maybe<Array<Maybe<UploadFile>>>;
  filesConnection?: Maybe<UploadFileConnection>;
  me?: Maybe<UsersPermissionsMe>;
  myEvaluations: Array<Maybe<MyEvaluation>>;
  process?: Maybe<Process>;
  processes: Array<Process>;
  processesConnection?: Maybe<ProcessConnection>;
  provider?: Maybe<Provider>;
  providers: Array<Provider>;
  providersConnection?: Maybe<ProviderConnection>;
  role?: Maybe<UsersPermissionsRole>;
  /** Retrieve all the existing roles. You can't apply filters on this query. */
  roles?: Maybe<Array<Maybe<UsersPermissionsRole>>>;
  rolesConnection?: Maybe<UsersPermissionsRoleConnection>;
  user?: Maybe<UsersPermissionsUser>;
  userinfo?: Maybe<Userinfo>;
  userinfos: Array<Userinfo>;
  userinfosConnection?: Maybe<UserinfoConnection>;
  users: Array<UsersPermissionsUser>;
  usersConnection?: Maybe<UsersPermissionsUserConnection>;
};

export type QueryCategoriesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryCategoriesConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryCategoryArgs = {
  id: Scalars['ID'];
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryContactArgs = {
  id: Scalars['ID'];
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryContactsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryContactsConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryCourseArgs = {
  id: Scalars['ID'];
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryCoursesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryCoursesConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryCriteriaArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryCriteriaConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryCriterionArgs = {
  id: Scalars['ID'];
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryCycleArgs = {
  id: Scalars['ID'];
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryCycleevaluationArgs = {
  id: Scalars['ID'];
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryCycleevaluationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryCycleevaluationsConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryCyclesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryCyclesConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryDocumentArgs = {
  id: Scalars['ID'];
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryDocumentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryDocumentsConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryDocumenttypeArgs = {
  id: Scalars['ID'];
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryDocumenttypesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryDocumenttypesConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryEvaluatorArgs = {
  id: Scalars['ID'];
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryEvaluatorsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryEvaluatorsConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryFilesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryFilesConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryProcessArgs = {
  id: Scalars['ID'];
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryProcessesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryProcessesConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryProviderArgs = {
  id: Scalars['ID'];
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryProvidersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryProvidersConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryRoleArgs = {
  id: Scalars['ID'];
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryRolesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryRolesConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryUserArgs = {
  id: Scalars['ID'];
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryUserinfoArgs = {
  id: Scalars['ID'];
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryUserinfosArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryUserinfosConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryUsersConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type RoleInput = {
  created_by?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  type?: InputMaybe<Scalars['String']>;
  updated_by?: InputMaybe<Scalars['ID']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type UploadFile = {
  __typename?: 'UploadFile';
  _id: Scalars['ID'];
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  ext?: Maybe<Scalars['String']>;
  formats?: Maybe<Scalars['JSON']>;
  hash: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  mime: Scalars['String'];
  name: Scalars['String'];
  previewUrl?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  provider_metadata?: Maybe<Scalars['JSON']>;
  related?: Maybe<Array<Maybe<Morph>>>;
  size: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};

export type UploadFileRelatedArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type UploadFileAggregator = {
  __typename?: 'UploadFileAggregator';
  avg?: Maybe<UploadFileAggregatorAvg>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<UploadFileAggregatorMax>;
  min?: Maybe<UploadFileAggregatorMin>;
  sum?: Maybe<UploadFileAggregatorSum>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type UploadFileAggregatorAvg = {
  __typename?: 'UploadFileAggregatorAvg';
  height?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

export type UploadFileAggregatorMax = {
  __typename?: 'UploadFileAggregatorMax';
  height?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

export type UploadFileAggregatorMin = {
  __typename?: 'UploadFileAggregatorMin';
  height?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

export type UploadFileAggregatorSum = {
  __typename?: 'UploadFileAggregatorSum';
  height?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

export type UploadFileConnection = {
  __typename?: 'UploadFileConnection';
  aggregate?: Maybe<UploadFileAggregator>;
  groupBy?: Maybe<UploadFileGroupBy>;
  values?: Maybe<Array<Maybe<UploadFile>>>;
};

export type UploadFileConnectionAlternativeText = {
  __typename?: 'UploadFileConnectionAlternativeText';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['String']>;
};

export type UploadFileConnectionCaption = {
  __typename?: 'UploadFileConnectionCaption';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['String']>;
};

export type UploadFileConnectionCreatedAt = {
  __typename?: 'UploadFileConnectionCreatedAt';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['DateTime']>;
};

export type UploadFileConnectionExt = {
  __typename?: 'UploadFileConnectionExt';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['String']>;
};

export type UploadFileConnectionFormats = {
  __typename?: 'UploadFileConnectionFormats';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['JSON']>;
};

export type UploadFileConnectionHash = {
  __typename?: 'UploadFileConnectionHash';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['String']>;
};

export type UploadFileConnectionHeight = {
  __typename?: 'UploadFileConnectionHeight';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['Int']>;
};

export type UploadFileConnectionId = {
  __typename?: 'UploadFileConnectionId';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type UploadFileConnectionMime = {
  __typename?: 'UploadFileConnectionMime';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['String']>;
};

export type UploadFileConnectionName = {
  __typename?: 'UploadFileConnectionName';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['String']>;
};

export type UploadFileConnectionPreviewUrl = {
  __typename?: 'UploadFileConnectionPreviewUrl';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['String']>;
};

export type UploadFileConnectionProvider = {
  __typename?: 'UploadFileConnectionProvider';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['String']>;
};

export type UploadFileConnectionProvider_Metadata = {
  __typename?: 'UploadFileConnectionProvider_metadata';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['JSON']>;
};

export type UploadFileConnectionSize = {
  __typename?: 'UploadFileConnectionSize';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['Float']>;
};

export type UploadFileConnectionUpdatedAt = {
  __typename?: 'UploadFileConnectionUpdatedAt';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['DateTime']>;
};

export type UploadFileConnectionUrl = {
  __typename?: 'UploadFileConnectionUrl';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['String']>;
};

export type UploadFileConnectionWidth = {
  __typename?: 'UploadFileConnectionWidth';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['Int']>;
};

export type UploadFileConnection_Id = {
  __typename?: 'UploadFileConnection_id';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type UploadFileGroupBy = {
  __typename?: 'UploadFileGroupBy';
  _id?: Maybe<Array<Maybe<UploadFileConnection_Id>>>;
  alternativeText?: Maybe<Array<Maybe<UploadFileConnectionAlternativeText>>>;
  caption?: Maybe<Array<Maybe<UploadFileConnectionCaption>>>;
  createdAt?: Maybe<Array<Maybe<UploadFileConnectionCreatedAt>>>;
  ext?: Maybe<Array<Maybe<UploadFileConnectionExt>>>;
  formats?: Maybe<Array<Maybe<UploadFileConnectionFormats>>>;
  hash?: Maybe<Array<Maybe<UploadFileConnectionHash>>>;
  height?: Maybe<Array<Maybe<UploadFileConnectionHeight>>>;
  id?: Maybe<Array<Maybe<UploadFileConnectionId>>>;
  mime?: Maybe<Array<Maybe<UploadFileConnectionMime>>>;
  name?: Maybe<Array<Maybe<UploadFileConnectionName>>>;
  previewUrl?: Maybe<Array<Maybe<UploadFileConnectionPreviewUrl>>>;
  provider?: Maybe<Array<Maybe<UploadFileConnectionProvider>>>;
  provider_metadata?: Maybe<
    Array<Maybe<UploadFileConnectionProvider_Metadata>>
  >;
  size?: Maybe<Array<Maybe<UploadFileConnectionSize>>>;
  updatedAt?: Maybe<Array<Maybe<UploadFileConnectionUpdatedAt>>>;
  url?: Maybe<Array<Maybe<UploadFileConnectionUrl>>>;
  width?: Maybe<Array<Maybe<UploadFileConnectionWidth>>>;
};

export type UserInput = {
  blocked?: InputMaybe<Scalars['Boolean']>;
  confirmationToken?: InputMaybe<Scalars['String']>;
  confirmed?: InputMaybe<Scalars['Boolean']>;
  created_by?: InputMaybe<Scalars['ID']>;
  email: Scalars['String'];
  info?: InputMaybe<Scalars['ID']>;
  password?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  resetPasswordToken?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['ID']>;
  updated_by?: InputMaybe<Scalars['ID']>;
  username: Scalars['String'];
};

export type UserPermissionsPasswordPayload = {
  __typename?: 'UserPermissionsPasswordPayload';
  ok: Scalars['Boolean'];
};

export type Userinfo = {
  __typename?: 'Userinfo';
  _id: Scalars['ID'];
  firstname: Scalars['String'];
  gender: Enum_Userinfo_Gender;
  id: Scalars['ID'];
  lastname: Scalars['String'];
  title: Enum_Userinfo_Title;
};

export type UserinfoAggregator = {
  __typename?: 'UserinfoAggregator';
  count?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type UserinfoConnection = {
  __typename?: 'UserinfoConnection';
  aggregate?: Maybe<UserinfoAggregator>;
  groupBy?: Maybe<UserinfoGroupBy>;
  values: Array<Userinfo>;
};

export type UserinfoConnectionFirstname = {
  __typename?: 'UserinfoConnectionFirstname';
  connection?: Maybe<UserinfoConnection>;
  key?: Maybe<Scalars['String']>;
};

export type UserinfoConnectionGender = {
  __typename?: 'UserinfoConnectionGender';
  connection?: Maybe<UserinfoConnection>;
  key?: Maybe<Scalars['String']>;
};

export type UserinfoConnectionId = {
  __typename?: 'UserinfoConnectionId';
  connection?: Maybe<UserinfoConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type UserinfoConnectionLastname = {
  __typename?: 'UserinfoConnectionLastname';
  connection?: Maybe<UserinfoConnection>;
  key?: Maybe<Scalars['String']>;
};

export type UserinfoConnectionTitle = {
  __typename?: 'UserinfoConnectionTitle';
  connection?: Maybe<UserinfoConnection>;
  key?: Maybe<Scalars['String']>;
};

export type UserinfoConnection_Id = {
  __typename?: 'UserinfoConnection_id';
  connection?: Maybe<UserinfoConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type UserinfoGroupBy = {
  __typename?: 'UserinfoGroupBy';
  _id?: Maybe<Array<Maybe<UserinfoConnection_Id>>>;
  firstname?: Maybe<Array<Maybe<UserinfoConnectionFirstname>>>;
  gender?: Maybe<Array<Maybe<UserinfoConnectionGender>>>;
  id?: Maybe<Array<Maybe<UserinfoConnectionId>>>;
  lastname?: Maybe<Array<Maybe<UserinfoConnectionLastname>>>;
  title?: Maybe<Array<Maybe<UserinfoConnectionTitle>>>;
};

export type UserinfoInput = {
  created_by?: InputMaybe<Scalars['ID']>;
  firstname?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Enum_Userinfo_Gender>;
  lastname?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Enum_Userinfo_Title>;
  updated_by?: InputMaybe<Scalars['ID']>;
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String'];
  password: Scalars['String'];
  provider?: InputMaybe<Scalars['String']>;
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt?: Maybe<Scalars['String']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  blocked?: Maybe<Scalars['Boolean']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  evaluator?: Maybe<Evaluator>;
  id: Scalars['ID'];
  info?: Maybe<Userinfo>;
  role?: Maybe<UsersPermissionsMeRole>;
  username: Scalars['String'];
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  _id: Scalars['ID'];
  action: Scalars['String'];
  controller: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  policy?: Maybe<Scalars['String']>;
  role?: Maybe<UsersPermissionsRole>;
  type: Scalars['String'];
};

export type UsersPermissionsRegisterInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  _id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions?: Maybe<Array<Maybe<UsersPermissionsPermission>>>;
  type?: Maybe<Scalars['String']>;
  users: Array<UsersPermissionsUser>;
};

export type UsersPermissionsRolePermissionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type UsersPermissionsRoleUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type UsersPermissionsRoleAggregator = {
  __typename?: 'UsersPermissionsRoleAggregator';
  count?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type UsersPermissionsRoleConnection = {
  __typename?: 'UsersPermissionsRoleConnection';
  aggregate?: Maybe<UsersPermissionsRoleAggregator>;
  groupBy?: Maybe<UsersPermissionsRoleGroupBy>;
  values?: Maybe<Array<Maybe<UsersPermissionsRole>>>;
};

export type UsersPermissionsRoleConnectionDescription = {
  __typename?: 'UsersPermissionsRoleConnectionDescription';
  connection?: Maybe<UsersPermissionsRoleConnection>;
  key?: Maybe<Scalars['String']>;
};

export type UsersPermissionsRoleConnectionId = {
  __typename?: 'UsersPermissionsRoleConnectionId';
  connection?: Maybe<UsersPermissionsRoleConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsRoleConnectionName = {
  __typename?: 'UsersPermissionsRoleConnectionName';
  connection?: Maybe<UsersPermissionsRoleConnection>;
  key?: Maybe<Scalars['String']>;
};

export type UsersPermissionsRoleConnectionType = {
  __typename?: 'UsersPermissionsRoleConnectionType';
  connection?: Maybe<UsersPermissionsRoleConnection>;
  key?: Maybe<Scalars['String']>;
};

export type UsersPermissionsRoleConnection_Id = {
  __typename?: 'UsersPermissionsRoleConnection_id';
  connection?: Maybe<UsersPermissionsRoleConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsRoleGroupBy = {
  __typename?: 'UsersPermissionsRoleGroupBy';
  _id?: Maybe<Array<Maybe<UsersPermissionsRoleConnection_Id>>>;
  description?: Maybe<Array<Maybe<UsersPermissionsRoleConnectionDescription>>>;
  id?: Maybe<Array<Maybe<UsersPermissionsRoleConnectionId>>>;
  name?: Maybe<Array<Maybe<UsersPermissionsRoleConnectionName>>>;
  type?: Maybe<Array<Maybe<UsersPermissionsRoleConnectionType>>>;
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  _id: Scalars['ID'];
  blocked?: Maybe<Scalars['Boolean']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  info?: Maybe<Userinfo>;
  provider?: Maybe<Scalars['String']>;
  role?: Maybe<UsersPermissionsRole>;
  updatedAt: Scalars['DateTime'];
  username: Scalars['String'];
};

export type UsersPermissionsUserAggregator = {
  __typename?: 'UsersPermissionsUserAggregator';
  count?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type UsersPermissionsUserConnection = {
  __typename?: 'UsersPermissionsUserConnection';
  aggregate?: Maybe<UsersPermissionsUserAggregator>;
  groupBy?: Maybe<UsersPermissionsUserGroupBy>;
  values: Array<UsersPermissionsUser>;
};

export type UsersPermissionsUserConnectionBlocked = {
  __typename?: 'UsersPermissionsUserConnectionBlocked';
  connection?: Maybe<UsersPermissionsUserConnection>;
  key?: Maybe<Scalars['Boolean']>;
};

export type UsersPermissionsUserConnectionConfirmed = {
  __typename?: 'UsersPermissionsUserConnectionConfirmed';
  connection?: Maybe<UsersPermissionsUserConnection>;
  key?: Maybe<Scalars['Boolean']>;
};

export type UsersPermissionsUserConnectionCreatedAt = {
  __typename?: 'UsersPermissionsUserConnectionCreatedAt';
  connection?: Maybe<UsersPermissionsUserConnection>;
  key?: Maybe<Scalars['DateTime']>;
};

export type UsersPermissionsUserConnectionEmail = {
  __typename?: 'UsersPermissionsUserConnectionEmail';
  connection?: Maybe<UsersPermissionsUserConnection>;
  key?: Maybe<Scalars['String']>;
};

export type UsersPermissionsUserConnectionId = {
  __typename?: 'UsersPermissionsUserConnectionId';
  connection?: Maybe<UsersPermissionsUserConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsUserConnectionInfo = {
  __typename?: 'UsersPermissionsUserConnectionInfo';
  connection?: Maybe<UsersPermissionsUserConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsUserConnectionProvider = {
  __typename?: 'UsersPermissionsUserConnectionProvider';
  connection?: Maybe<UsersPermissionsUserConnection>;
  key?: Maybe<Scalars['String']>;
};

export type UsersPermissionsUserConnectionRole = {
  __typename?: 'UsersPermissionsUserConnectionRole';
  connection?: Maybe<UsersPermissionsUserConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsUserConnectionUpdatedAt = {
  __typename?: 'UsersPermissionsUserConnectionUpdatedAt';
  connection?: Maybe<UsersPermissionsUserConnection>;
  key?: Maybe<Scalars['DateTime']>;
};

export type UsersPermissionsUserConnectionUsername = {
  __typename?: 'UsersPermissionsUserConnectionUsername';
  connection?: Maybe<UsersPermissionsUserConnection>;
  key?: Maybe<Scalars['String']>;
};

export type UsersPermissionsUserConnection_Id = {
  __typename?: 'UsersPermissionsUserConnection_id';
  connection?: Maybe<UsersPermissionsUserConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsUserGroupBy = {
  __typename?: 'UsersPermissionsUserGroupBy';
  _id?: Maybe<Array<Maybe<UsersPermissionsUserConnection_Id>>>;
  blocked?: Maybe<Array<Maybe<UsersPermissionsUserConnectionBlocked>>>;
  confirmed?: Maybe<Array<Maybe<UsersPermissionsUserConnectionConfirmed>>>;
  createdAt?: Maybe<Array<Maybe<UsersPermissionsUserConnectionCreatedAt>>>;
  email?: Maybe<Array<Maybe<UsersPermissionsUserConnectionEmail>>>;
  id?: Maybe<Array<Maybe<UsersPermissionsUserConnectionId>>>;
  info?: Maybe<Array<Maybe<UsersPermissionsUserConnectionInfo>>>;
  provider?: Maybe<Array<Maybe<UsersPermissionsUserConnectionProvider>>>;
  role?: Maybe<Array<Maybe<UsersPermissionsUserConnectionRole>>>;
  updatedAt?: Maybe<Array<Maybe<UsersPermissionsUserConnectionUpdatedAt>>>;
  username?: Maybe<Array<Maybe<UsersPermissionsUserConnectionUsername>>>;
};

export type CreateCategoryInput = {
  data?: InputMaybe<CategoryInput>;
};

export type CreateCategoryPayload = {
  __typename?: 'createCategoryPayload';
  category?: Maybe<Category>;
};

export type CreateContactInput = {
  data?: InputMaybe<ContactInput>;
};

export type CreateContactPayload = {
  __typename?: 'createContactPayload';
  contact?: Maybe<Contact>;
};

export type CreateCourseInput = {
  data?: InputMaybe<CourseInput>;
};

export type CreateCoursePayload = {
  __typename?: 'createCoursePayload';
  course?: Maybe<Course>;
};

export type CreateCriterionInput = {
  data?: InputMaybe<CriterionInput>;
};

export type CreateCriterionPayload = {
  __typename?: 'createCriterionPayload';
  criterion?: Maybe<Criterion>;
};

export type CreateCycleInput = {
  data?: InputMaybe<CycleInput>;
};

export type CreateCyclePayload = {
  __typename?: 'createCyclePayload';
  cycle?: Maybe<Cycle>;
};

export type CreateCycleevaluationInput = {
  data?: InputMaybe<CycleevaluationInput>;
};

export type CreateCycleevaluationPayload = {
  __typename?: 'createCycleevaluationPayload';
  cycleevaluation?: Maybe<Cycleevaluation>;
};

export type CreateDocumentInput = {
  data?: InputMaybe<DocumentInput>;
};

export type CreateDocumentPayload = {
  __typename?: 'createDocumentPayload';
  document?: Maybe<Document>;
};

export type CreateDocumenttypeInput = {
  data?: InputMaybe<DocumenttypeInput>;
};

export type CreateDocumenttypePayload = {
  __typename?: 'createDocumenttypePayload';
  documenttype?: Maybe<Documenttype>;
};

export type CreateEvaluatorInput = {
  data?: InputMaybe<EvaluatorInput>;
};

export type CreateEvaluatorPayload = {
  __typename?: 'createEvaluatorPayload';
  evaluator?: Maybe<Evaluator>;
};

export type CreateProcessInput = {
  data?: InputMaybe<ProcessInput>;
};

export type CreateProcessPayload = {
  __typename?: 'createProcessPayload';
  process?: Maybe<Process>;
};

export type CreateProviderInput = {
  data?: InputMaybe<ProviderInput>;
};

export type CreateProviderPayload = {
  __typename?: 'createProviderPayload';
  provider?: Maybe<Provider>;
};

export type CreateRoleInput = {
  data?: InputMaybe<RoleInput>;
};

export type CreateRolePayload = {
  __typename?: 'createRolePayload';
  role?: Maybe<UsersPermissionsRole>;
};

export type CreateUserInput = {
  data?: InputMaybe<UserInput>;
};

export type CreateUserPayload = {
  __typename?: 'createUserPayload';
  user?: Maybe<UsersPermissionsUser>;
};

export type CreateUserinfoInput = {
  data?: InputMaybe<UserinfoInput>;
};

export type CreateUserinfoPayload = {
  __typename?: 'createUserinfoPayload';
  userinfo?: Maybe<Userinfo>;
};

export type DeleteCategoryInput = {
  where?: InputMaybe<InputId>;
};

export type DeleteCategoryPayload = {
  __typename?: 'deleteCategoryPayload';
  category?: Maybe<Category>;
};

export type DeleteContactInput = {
  where?: InputMaybe<InputId>;
};

export type DeleteContactPayload = {
  __typename?: 'deleteContactPayload';
  contact?: Maybe<Contact>;
};

export type DeleteCourseInput = {
  where?: InputMaybe<InputId>;
};

export type DeleteCoursePayload = {
  __typename?: 'deleteCoursePayload';
  course?: Maybe<Course>;
};

export type DeleteCriterionInput = {
  where?: InputMaybe<InputId>;
};

export type DeleteCriterionPayload = {
  __typename?: 'deleteCriterionPayload';
  criterion?: Maybe<Criterion>;
};

export type DeleteCycleInput = {
  where?: InputMaybe<InputId>;
};

export type DeleteCyclePayload = {
  __typename?: 'deleteCyclePayload';
  cycle?: Maybe<Cycle>;
};

export type DeleteCycleevaluationInput = {
  where?: InputMaybe<InputId>;
};

export type DeleteCycleevaluationPayload = {
  __typename?: 'deleteCycleevaluationPayload';
  cycleevaluation?: Maybe<Cycleevaluation>;
};

export type DeleteDocumentInput = {
  where?: InputMaybe<InputId>;
};

export type DeleteDocumentPayload = {
  __typename?: 'deleteDocumentPayload';
  document?: Maybe<Document>;
};

export type DeleteDocumenttypeInput = {
  where?: InputMaybe<InputId>;
};

export type DeleteDocumenttypePayload = {
  __typename?: 'deleteDocumenttypePayload';
  documenttype?: Maybe<Documenttype>;
};

export type DeleteEvaluatorInput = {
  where?: InputMaybe<InputId>;
};

export type DeleteEvaluatorPayload = {
  __typename?: 'deleteEvaluatorPayload';
  evaluator?: Maybe<Evaluator>;
};

export type DeleteFileInput = {
  where?: InputMaybe<InputId>;
};

export type DeleteFilePayload = {
  __typename?: 'deleteFilePayload';
  file?: Maybe<UploadFile>;
};

export type DeleteProcessInput = {
  where?: InputMaybe<InputId>;
};

export type DeleteProcessPayload = {
  __typename?: 'deleteProcessPayload';
  process?: Maybe<Process>;
};

export type DeleteProviderInput = {
  where?: InputMaybe<InputId>;
};

export type DeleteProviderPayload = {
  __typename?: 'deleteProviderPayload';
  provider?: Maybe<Provider>;
};

export type DeleteRoleInput = {
  where?: InputMaybe<InputId>;
};

export type DeleteRolePayload = {
  __typename?: 'deleteRolePayload';
  role?: Maybe<UsersPermissionsRole>;
};

export type DeleteUserInput = {
  where?: InputMaybe<InputId>;
};

export type DeleteUserPayload = {
  __typename?: 'deleteUserPayload';
  user?: Maybe<UsersPermissionsUser>;
};

export type DeleteUserinfoInput = {
  where?: InputMaybe<InputId>;
};

export type DeleteUserinfoPayload = {
  __typename?: 'deleteUserinfoPayload';
  userinfo?: Maybe<Userinfo>;
};

export type EditCategoryInput = {
  courses?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  created_by?: InputMaybe<Scalars['ID']>;
  criteria?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  documenttypes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  evaluators?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  updated_by?: InputMaybe<Scalars['ID']>;
};

export type EditContactInput = {
  created_by?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Enum_Contact_Gender>;
  helperId?: InputMaybe<Scalars['ID']>;
  lastname?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Enum_Contact_Title>;
  updated_by?: InputMaybe<Scalars['ID']>;
};

export type EditCourseInput = {
  category?: InputMaybe<Scalars['ID']>;
  comment?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['ID']>;
  created_by?: InputMaybe<Scalars['ID']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  evaluators?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  predecessor?: InputMaybe<Scalars['ID']>;
  processes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  provider?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<Scalars['JSON']>;
  updated_by?: InputMaybe<Scalars['ID']>;
};

export type EditCriterionInput = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  created_by?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  updated_by?: InputMaybe<Scalars['ID']>;
};

export type EditCycleInput = {
  cancelled?: InputMaybe<Scalars['Boolean']>;
  closedAt?: InputMaybe<Scalars['DateTime']>;
  created_by?: InputMaybe<Scalars['ID']>;
  cycleevaluations?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  documents?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  expirationReminded?: InputMaybe<Scalars['Boolean']>;
  process?: InputMaybe<Scalars['ID']>;
  published?: InputMaybe<Scalars['Boolean']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  requestedAt?: InputMaybe<Scalars['DateTime']>;
  score?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['ID']>;
};

export type EditCycleevaluationInput = {
  closedAt?: InputMaybe<Scalars['DateTime']>;
  comment?: InputMaybe<Scalars['String']>;
  created_by?: InputMaybe<Scalars['ID']>;
  criterionevaluations?: InputMaybe<Scalars['JSON']>;
  cycle?: InputMaybe<Scalars['ID']>;
  evaluator?: InputMaybe<Scalars['ID']>;
  expirationReminded?: InputMaybe<Scalars['Boolean']>;
  score?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['ID']>;
};

export type EditDocumentInput = {
  created_by?: InputMaybe<Scalars['ID']>;
  cycle?: InputMaybe<Scalars['ID']>;
  documenttype?: InputMaybe<Scalars['ID']>;
  file?: InputMaybe<Scalars['ID']>;
  updated_by?: InputMaybe<Scalars['ID']>;
};

export type EditDocumenttypeInput = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  created_by?: InputMaybe<Scalars['ID']>;
  documents?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  updated_by?: InputMaybe<Scalars['ID']>;
};

export type EditEvaluatorInput = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  courses?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  created_by?: InputMaybe<Scalars['ID']>;
  cycleevaluations?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  phone?: InputMaybe<Scalars['String']>;
  short?: InputMaybe<Scalars['String']>;
  updated_by?: InputMaybe<Scalars['ID']>;
  user?: InputMaybe<Scalars['ID']>;
};

export type EditFileInput = {
  alternativeText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  created_by?: InputMaybe<Scalars['ID']>;
  ext?: InputMaybe<Scalars['String']>;
  formats?: InputMaybe<Scalars['JSON']>;
  hash?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  mime?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  previewUrl?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  provider_metadata?: InputMaybe<Scalars['JSON']>;
  related?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  size?: InputMaybe<Scalars['Float']>;
  updated_by?: InputMaybe<Scalars['ID']>;
  url?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type EditProcessInput = {
  certification?: InputMaybe<Scalars['Boolean']>;
  confirmedAt?: InputMaybe<Scalars['DateTime']>;
  course?: InputMaybe<Scalars['ID']>;
  created_by?: InputMaybe<Scalars['ID']>;
  cycles?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  expirationReminded?: InputMaybe<Scalars['Boolean']>;
  paidAt?: InputMaybe<Scalars['DateTime']>;
  updated_by?: InputMaybe<Scalars['ID']>;
};

export type EditProviderInput = {
  city?: InputMaybe<Scalars['String']>;
  contacts?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  courses?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  created_by?: InputMaybe<Scalars['ID']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  homepage?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetnumber?: InputMaybe<Scalars['String']>;
  updated_by?: InputMaybe<Scalars['ID']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type EditRoleInput = {
  created_by?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  type?: InputMaybe<Scalars['String']>;
  updated_by?: InputMaybe<Scalars['ID']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type EditUserInput = {
  blocked?: InputMaybe<Scalars['Boolean']>;
  confirmationToken?: InputMaybe<Scalars['String']>;
  confirmed?: InputMaybe<Scalars['Boolean']>;
  created_by?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  info?: InputMaybe<Scalars['ID']>;
  password?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  resetPasswordToken?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['ID']>;
  updated_by?: InputMaybe<Scalars['ID']>;
  username?: InputMaybe<Scalars['String']>;
};

export type EditUserinfoInput = {
  created_by?: InputMaybe<Scalars['ID']>;
  firstname?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Enum_Userinfo_Gender>;
  lastname?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Enum_Userinfo_Title>;
  updated_by?: InputMaybe<Scalars['ID']>;
};

export type UpdateCategoryInput = {
  data?: InputMaybe<EditCategoryInput>;
  where?: InputMaybe<InputId>;
};

export type UpdateCategoryPayload = {
  __typename?: 'updateCategoryPayload';
  category?: Maybe<Category>;
};

export type UpdateContactInput = {
  data?: InputMaybe<EditContactInput>;
  where?: InputMaybe<InputId>;
};

export type UpdateContactPayload = {
  __typename?: 'updateContactPayload';
  contact?: Maybe<Contact>;
};

export type UpdateCourseInput = {
  data?: InputMaybe<EditCourseInput>;
  where?: InputMaybe<InputId>;
};

export type UpdateCoursePayload = {
  __typename?: 'updateCoursePayload';
  course?: Maybe<Course>;
};

export type UpdateCriterionInput = {
  data?: InputMaybe<EditCriterionInput>;
  where?: InputMaybe<InputId>;
};

export type UpdateCriterionPayload = {
  __typename?: 'updateCriterionPayload';
  criterion?: Maybe<Criterion>;
};

export type UpdateCycleInput = {
  data?: InputMaybe<EditCycleInput>;
  where?: InputMaybe<InputId>;
};

export type UpdateCyclePayload = {
  __typename?: 'updateCyclePayload';
  cycle?: Maybe<Cycle>;
};

export type UpdateCycleevaluationInput = {
  data?: InputMaybe<EditCycleevaluationInput>;
  where?: InputMaybe<InputId>;
};

export type UpdateCycleevaluationPayload = {
  __typename?: 'updateCycleevaluationPayload';
  cycleevaluation?: Maybe<Cycleevaluation>;
};

export type UpdateDocumentInput = {
  data?: InputMaybe<EditDocumentInput>;
  where?: InputMaybe<InputId>;
};

export type UpdateDocumentPayload = {
  __typename?: 'updateDocumentPayload';
  document?: Maybe<Document>;
};

export type UpdateDocumenttypeInput = {
  data?: InputMaybe<EditDocumenttypeInput>;
  where?: InputMaybe<InputId>;
};

export type UpdateDocumenttypePayload = {
  __typename?: 'updateDocumenttypePayload';
  documenttype?: Maybe<Documenttype>;
};

export type UpdateEvaluatorInput = {
  data?: InputMaybe<EditEvaluatorInput>;
  where?: InputMaybe<InputId>;
};

export type UpdateEvaluatorPayload = {
  __typename?: 'updateEvaluatorPayload';
  evaluator?: Maybe<Evaluator>;
};

export type UpdateProcessInput = {
  data?: InputMaybe<EditProcessInput>;
  where?: InputMaybe<InputId>;
};

export type UpdateProcessPayload = {
  __typename?: 'updateProcessPayload';
  process?: Maybe<Process>;
};

export type UpdateProviderInput = {
  data?: InputMaybe<EditProviderInput>;
  where?: InputMaybe<InputId>;
};

export type UpdateProviderPayload = {
  __typename?: 'updateProviderPayload';
  provider?: Maybe<Provider>;
};

export type UpdateRoleInput = {
  data?: InputMaybe<EditRoleInput>;
  where?: InputMaybe<InputId>;
};

export type UpdateRolePayload = {
  __typename?: 'updateRolePayload';
  role?: Maybe<UsersPermissionsRole>;
};

export type UpdateUserInput = {
  data?: InputMaybe<EditUserInput>;
  where?: InputMaybe<InputId>;
};

export type UpdateUserPayload = {
  __typename?: 'updateUserPayload';
  user?: Maybe<UsersPermissionsUser>;
};

export type UpdateUserinfoInput = {
  data?: InputMaybe<EditUserinfoInput>;
  where?: InputMaybe<InputId>;
};

export type UpdateUserinfoPayload = {
  __typename?: 'updateUserinfoPayload';
  userinfo?: Maybe<Userinfo>;
};

export type LoginMutationVariables = Exact<{
  input: UsersPermissionsLoginInput;
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'UsersPermissionsLoginPayload';
    jwt?: string | null;
    user: {
      __typename?: 'UsersPermissionsMe';
      id: string;
      email: string;
      confirmed?: boolean | null;
      blocked?: boolean | null;
      username: string;
      role?: {
        __typename?: 'UsersPermissionsMeRole';
        id: string;
        name: string;
        description?: string | null;
        type?: string | null;
      } | null;
      info?: {
        __typename?: 'Userinfo';
        id: string;
        firstname: string;
        lastname: string;
        title: Enum_Userinfo_Title;
        gender: Enum_Userinfo_Gender;
      } | null;
      evaluator?: {
        __typename?: 'Evaluator';
        id: string;
        short: string;
        phone?: string | null;
      } | null;
    };
  };
};

export type GetMeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'UsersPermissionsMe';
    id: string;
    username: string;
    role?: {
      __typename?: 'UsersPermissionsMeRole';
      id: string;
      name: string;
      description?: string | null;
      type?: string | null;
    } | null;
    info?: {
      __typename?: 'Userinfo';
      firstname: string;
      lastname: string;
      title: Enum_Userinfo_Title;
      gender: Enum_Userinfo_Gender;
    } | null;
    evaluator?: {
      __typename?: 'Evaluator';
      id: string;
      short: string;
      phone?: string | null;
    } | null;
  } | null;
};

export type GetCoursesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCoursesQuery = {
  __typename?: 'Query';
  courses: Array<{
    __typename?: 'Course';
    id: string;
    state?: any | null;
    enabled: boolean;
    processes: Array<{
      __typename?: 'Process';
      id: string;
      confirmedAt?: any | null;
    }>;
    provider?: { __typename?: 'Provider'; id: string; name: string } | null;
    category?: { __typename?: 'Category'; id: string; name: string } | null;
  }>;
};

export type GetCourseQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCourseQuery = {
  __typename?: 'Query';
  documenttypes: Array<{
    __typename?: 'Documenttype';
    id: string;
    name: string;
  }>;
  course?: {
    __typename?: 'Course';
    id: string;
    enabled: boolean;
    createdAt: any;
    comment?: string | null;
    state?: any | null;
    contact?: { __typename?: 'Contact'; id: string } | null;
    provider?: {
      __typename?: 'Provider';
      id: string;
      name: string;
      enabled: boolean;
      street?: string | null;
      city: string;
      zip: string;
      streetnumber?: string | null;
      homepage?: string | null;
      contacts: Array<{
        __typename?: 'Contact';
        id: string;
        firstname: string;
        lastname: string;
        phone?: string | null;
        email: string;
        title: Enum_Contact_Title;
        gender: Enum_Contact_Gender;
      }>;
    } | null;
    processes: Array<{
      __typename?: 'Process';
      id: string;
      createdAt: any;
      paidAt?: any | null;
      confirmedAt?: any | null;
      certification: boolean;
      cycles: Array<{
        __typename?: 'Cycle';
        id: string;
        createdAt: any;
        requestedAt?: any | null;
        published: boolean;
        publishedAt?: any | null;
        cancelled: boolean;
        closedAt?: any | null;
        score: number;
        cycleevaluations: Array<{
          __typename?: 'Cycleevaluation';
          id: string;
          score: number;
          comment?: string | null;
          closedAt?: any | null;
          criterionevaluations: any;
          evaluator?: {
            __typename?: 'Evaluator';
            id: string;
            short: string;
            user?: {
              __typename?: 'UsersPermissionsUser';
              id: string;
              email: string;
              info?: {
                __typename?: 'Userinfo';
                id: string;
                firstname: string;
                lastname: string;
                title: Enum_Userinfo_Title;
                gender: Enum_Userinfo_Gender;
              } | null;
            } | null;
          } | null;
        }>;
        documents: Array<{
          __typename?: 'Document';
          id: string;
          file?: {
            __typename?: 'UploadFile';
            id: string;
            url: string;
            name: string;
            updatedAt: any;
          } | null;
          documenttype?: {
            __typename?: 'Documenttype';
            id: string;
            name: string;
          } | null;
        }>;
      }>;
    }>;
    evaluators: Array<{
      __typename?: 'Evaluator';
      id: string;
      short: string;
      user?: {
        __typename?: 'UsersPermissionsUser';
        id: string;
        email: string;
        info?: {
          __typename?: 'Userinfo';
          id: string;
          firstname: string;
          lastname: string;
          title: Enum_Userinfo_Title;
          gender: Enum_Userinfo_Gender;
        } | null;
      } | null;
    }>;
    category?: {
      __typename?: 'Category';
      id: string;
      name: string;
      criteria: Array<{ __typename?: 'Criterion'; id: string; name: string }>;
    } | null;
  } | null;
  criteria: Array<{ __typename?: 'Criterion'; id: string; name: string }>;
};

export type GetCycleevaluationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCycleevaluationQuery = {
  __typename?: 'Query';
  cycleevaluation?: {
    __typename?: 'Cycleevaluation';
    id: string;
    score: number;
    evaluator?: { __typename?: 'Evaluator'; id: string } | null;
    cycle?: {
      __typename?: 'Cycle';
      id: string;
      published: boolean;
      cancelled: boolean;
      closedAt?: any | null;
      score: number;
      process?: {
        __typename?: 'Process';
        id: string;
        course?: { __typename?: 'Course'; id: string } | null;
      } | null;
    } | null;
  } | null;
};

export type GetEnabledEvaluatorsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetEnabledEvaluatorsQuery = {
  __typename?: 'Query';
  evaluators: Array<{
    __typename?: 'Evaluator';
    id: string;
    categories: Array<{ __typename?: 'Category'; id: string }>;
    user?: {
      __typename?: 'UsersPermissionsUser';
      id: string;
      blocked?: boolean | null;
      info?: {
        __typename?: 'Userinfo';
        firstname: string;
        lastname: string;
        title: Enum_Userinfo_Title;
        gender: Enum_Userinfo_Gender;
      } | null;
    } | null;
  }>;
};

export type GetCreateCourseQueryVariables = Exact<{ [key: string]: never }>;

export type GetCreateCourseQuery = {
  __typename?: 'Query';
  providers: Array<{ __typename?: 'Provider'; id: string; name: string }>;
  categories: Array<{ __typename?: 'Category'; id: string; name: string }>;
};

export type GetCycleevaluationsQueryVariables = Exact<{
  evaluatorId: Scalars['ID'];
}>;

export type GetCycleevaluationsQuery = {
  __typename?: 'Query';
  cycleevaluations: Array<{
    __typename?: 'Cycleevaluation';
    id: string;
    closedAt?: any | null;
    cycle?: {
      __typename?: 'Cycle';
      id: string;
      process?: {
        __typename?: 'Process';
        id: string;
        course?: { __typename?: 'Course'; id: string } | null;
      } | null;
    } | null;
  }>;
};

export type GetMyEvaluationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyEvaluationsQuery = {
  __typename?: 'Query';
  myEvaluations: Array<{
    __typename?: 'MyEvaluation';
    cycleevaluation: string;
    open: boolean;
    course: string;
  } | null>;
};

export type UpdateCourseMutationVariables = Exact<{
  id: Scalars['ID'];
  course?: InputMaybe<EditCourseInput>;
}>;

export type UpdateCourseMutation = {
  __typename?: 'Mutation';
  updateCourse?: {
    __typename?: 'updateCoursePayload';
    course?: { __typename?: 'Course'; id: string } | null;
  } | null;
};

export type CreateCourseMutationVariables = Exact<{
  course?: InputMaybe<CourseInput>;
}>;

export type CreateCourseMutation = {
  __typename?: 'Mutation';
  createCourse?: {
    __typename?: 'createCoursePayload';
    course?: { __typename?: 'Course'; id: string } | null;
  } | null;
};

export type UploadFileMutationVariables = Exact<{
  file: Scalars['Upload'];
  ref?: InputMaybe<Scalars['String']>;
}>;

export type UploadFileMutation = {
  __typename?: 'Mutation';
  upload: {
    __typename: 'UploadFile';
    id: string;
    name: string;
    mime: string;
    url: string;
  };
};

export type UpdateProcessMutationVariables = Exact<{
  id: Scalars['ID'];
  process?: InputMaybe<EditProcessInput>;
}>;

export type UpdateProcessMutation = {
  __typename?: 'Mutation';
  updateProcess?: {
    __typename?: 'updateProcessPayload';
    process?: { __typename?: 'Process'; id: string } | null;
  } | null;
};

export type UpdateCycleMutationVariables = Exact<{
  id: Scalars['ID'];
  cycle?: InputMaybe<EditCycleInput>;
}>;

export type UpdateCycleMutation = {
  __typename?: 'Mutation';
  updateCycle?: {
    __typename?: 'updateCyclePayload';
    cycle?: { __typename?: 'Cycle'; id: string } | null;
  } | null;
};

export type CreateProcessMutationVariables = Exact<{
  process?: InputMaybe<ProcessInput>;
}>;

export type CreateProcessMutation = {
  __typename?: 'Mutation';
  createProcess?: {
    __typename?: 'createProcessPayload';
    process?: { __typename?: 'Process'; id: string } | null;
  } | null;
};

export type CreateCycleMutationVariables = Exact<{
  cycle?: InputMaybe<CycleInput>;
}>;

export type CreateCycleMutation = {
  __typename?: 'Mutation';
  createCycle?: {
    __typename?: 'createCyclePayload';
    cycle?: { __typename?: 'Cycle'; id: string } | null;
  } | null;
};

export type UpdateCycleevaluationMutationVariables = Exact<{
  id: Scalars['ID'];
  cycleevaluation?: InputMaybe<EditCycleevaluationInput>;
}>;

export type UpdateCycleevaluationMutation = {
  __typename?: 'Mutation';
  updateCycleevaluation?: {
    __typename?: 'updateCycleevaluationPayload';
    cycleevaluation?: { __typename?: 'Cycleevaluation'; id: string } | null;
  } | null;
};

export type GetCoursesForDashboardQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCoursesForDashboardQuery = {
  __typename?: 'Query';
  courses: Array<{
    __typename?: 'Course';
    id: string;
    state?: any | null;
    provider?: { __typename?: 'Provider'; id: string; name: string } | null;
    category?: { __typename?: 'Category'; id: string; name: string } | null;
    processes: Array<{
      __typename?: 'Process';
      id: string;
      confirmedAt?: any | null;
    }>;
  }>;
};

export type GetEvaluatorsQueryVariables = Exact<{ [key: string]: never }>;

export type GetEvaluatorsQuery = {
  __typename?: 'Query';
  evaluators: Array<{
    __typename?: 'Evaluator';
    id: string;
    categories: Array<{ __typename?: 'Category'; id: string; name: string }>;
    user?: {
      __typename?: 'UsersPermissionsUser';
      id: string;
      blocked?: boolean | null;
      info?: {
        __typename?: 'Userinfo';
        id: string;
        firstname: string;
        lastname: string;
        title: Enum_Userinfo_Title;
        gender: Enum_Userinfo_Gender;
      } | null;
    } | null;
  }>;
};

export type CreateEvaluatorMutationVariables = Exact<{
  evaluator?: InputMaybe<EvaluatorInput>;
}>;

export type CreateEvaluatorMutation = {
  __typename?: 'Mutation';
  createEvaluator?: {
    __typename?: 'createEvaluatorPayload';
    evaluator?: { __typename?: 'Evaluator'; id: string } | null;
  } | null;
};

export type CreateUserinfoMutationVariables = Exact<{
  userinfo?: InputMaybe<UserinfoInput>;
}>;

export type CreateUserinfoMutation = {
  __typename?: 'Mutation';
  createUserinfo?: {
    __typename?: 'createUserinfoPayload';
    userinfo?: { __typename?: 'Userinfo'; id: string } | null;
  } | null;
};

export type DeleteUserinfoMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteUserinfoMutation = {
  __typename?: 'Mutation';
  deleteUserinfo?: {
    __typename?: 'deleteUserinfoPayload';
    userinfo?: { __typename?: 'Userinfo'; id: string } | null;
  } | null;
};

export type CreateUserMutationVariables = Exact<{
  user?: InputMaybe<UserInput>;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser?: {
    __typename?: 'createUserPayload';
    user?: { __typename?: 'UsersPermissionsUser'; id: string } | null;
  } | null;
};

export type UpdateEvaluatorMutationVariables = Exact<{
  id: Scalars['ID'];
  evaluator?: InputMaybe<EditEvaluatorInput>;
}>;

export type UpdateEvaluatorMutation = {
  __typename?: 'Mutation';
  updateEvaluator?: {
    __typename?: 'updateEvaluatorPayload';
    evaluator?: { __typename?: 'Evaluator'; id: string } | null;
  } | null;
};

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID'];
  user?: InputMaybe<EditUserInput>;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser?: {
    __typename?: 'updateUserPayload';
    user?: { __typename?: 'UsersPermissionsUser'; id: string } | null;
  } | null;
};

export type UpdateUserinfoMutationVariables = Exact<{
  id: Scalars['ID'];
  userinfo?: InputMaybe<EditUserinfoInput>;
}>;

export type UpdateUserinfoMutation = {
  __typename?: 'Mutation';
  updateUserinfo?: {
    __typename?: 'updateUserinfoPayload';
    userinfo?: { __typename?: 'Userinfo'; id: string } | null;
  } | null;
};

export type GetEvaluatorQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetEvaluatorQuery = {
  __typename?: 'Query';
  evaluator?: {
    __typename?: 'Evaluator';
    id: string;
    phone?: string | null;
    short: string;
    categories: Array<{ __typename?: 'Category'; id: string }>;
    user?: {
      __typename?: 'UsersPermissionsUser';
      id: string;
      email: string;
      username: string;
      blocked?: boolean | null;
      info?: {
        __typename?: 'Userinfo';
        id: string;
        firstname: string;
        lastname: string;
        title: Enum_Userinfo_Title;
        gender: Enum_Userinfo_Gender;
      } | null;
    } | null;
  } | null;
};

export type GetCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCategoriesQuery = {
  __typename?: 'Query';
  categories: Array<{ __typename?: 'Category'; id: string; name: string }>;
};

export type GetProvidersQueryVariables = Exact<{ [key: string]: never }>;

export type GetProvidersQuery = {
  __typename?: 'Query';
  providers: Array<{
    __typename?: 'Provider';
    id: string;
    name: string;
    enabled: boolean;
  }>;
};

export type CustomCreateProviderMutationVariables = Exact<{
  provider: ProviderInput;
  contacts?: InputMaybe<
    Array<InputMaybe<ContactInput>> | InputMaybe<ContactInput>
  >;
}>;

export type CustomCreateProviderMutation = {
  __typename?: 'Mutation';
  customCreateProvider?: {
    __typename?: 'createProviderPayload';
    provider?: { __typename?: 'Provider'; id: string } | null;
  } | null;
};

export type CustomUpdateProviderMutationVariables = Exact<{
  id: Scalars['ID'];
  provider?: InputMaybe<EditProviderInput>;
  contacts?: InputMaybe<
    Array<InputMaybe<EditContactInput>> | InputMaybe<EditContactInput>
  >;
}>;

export type CustomUpdateProviderMutation = {
  __typename?: 'Mutation';
  customUpdateProvider?: {
    __typename?: 'updateProviderPayload';
    provider?: { __typename?: 'Provider'; id: string } | null;
  } | null;
};

export type GetProviderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetProviderQuery = {
  __typename?: 'Query';
  provider?: {
    __typename?: 'Provider';
    id: string;
    name: string;
    street?: string | null;
    city: string;
    zip: string;
    homepage?: string | null;
    phone?: string | null;
    streetnumber?: string | null;
    enabled: boolean;
    contacts: Array<{
      __typename?: 'Contact';
      id: string;
      firstname: string;
      lastname: string;
      email: string;
      phone?: string | null;
      title: Enum_Contact_Title;
      gender: Enum_Contact_Gender;
    }>;
  } | null;
};

export const LoginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Login' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UsersPermissionsLoginInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'login' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jwt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'confirmed' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'blocked' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'username' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'info' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstname' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastname' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'gender' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'evaluator' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'short' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'phone' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>;
export const GetMeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMe' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'role' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'info' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstname' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastname' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gender' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'evaluator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'short' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMeQuery, GetMeQueryVariables>;
export const GetCoursesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCourses' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '500' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'confirmedAt' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCoursesQuery, GetCoursesQueryVariables>;
export const GetCourseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCourse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'documenttypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'street' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'streetnumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'homepage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contacts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstname' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastname' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'phone' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'gender' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paidAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'confirmedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'certification' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cycles' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'requestedAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'published' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'publishedAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cancelled' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'closedAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'score' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cycleevaluations' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'score' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'comment' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'closedAt' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'criterionevaluations',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'evaluator' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'short',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'user' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'email',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'info',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'firstname',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'lastname',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'title',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'gender',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'documents' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'url' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'updatedAt',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'documenttype',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'evaluators' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'short' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'info' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstname' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastname' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'gender' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'criteria' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'criteria' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCourseQuery, GetCourseQueryVariables>;
export const GetCycleevaluationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCycleevaluation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cycleevaluation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'evaluator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cycle' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'published' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cancelled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closedAt' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'process' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'course' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCycleevaluationQuery,
  GetCycleevaluationQueryVariables
>;
export const GetEnabledEvaluatorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEnabledEvaluators' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'evaluators' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'blocked' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'blocked' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'info' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstname' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastname' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'gender' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetEnabledEvaluatorsQuery,
  GetEnabledEvaluatorsQueryVariables
>;
export const GetCreateCourseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCreateCourse' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'providers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'enabled' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCreateCourseQuery,
  GetCreateCourseQueryVariables
>;
export const GetCycleevaluationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCycleevaluations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'evaluatorId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cycleevaluations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'evaluator' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'evaluatorId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cycle' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'process' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'course' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCycleevaluationsQuery,
  GetCycleevaluationsQueryVariables
>;
export const GetMyEvaluationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMyEvaluations' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myEvaluations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cycleevaluation' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'open' } },
                { kind: 'Field', name: { kind: 'Name', value: 'course' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMyEvaluationsQuery,
  GetMyEvaluationsQueryVariables
>;
export const UpdateCourseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCourse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'course' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'editCourseInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCourse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'data' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'course' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'course' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCourseMutation,
  UpdateCourseMutationVariables
>;
export const CreateCourseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCourse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'course' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CourseInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCourse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'data' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'course' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'course' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCourseMutation,
  CreateCourseMutationVariables
>;
export const UploadFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UploadFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Upload' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ref' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upload' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'file' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'file' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ref' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ref' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UploadFileMutation, UploadFileMutationVariables>;
export const UpdateProcessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProcess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'process' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'editProcessInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProcess' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'data' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'process' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'process' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProcessMutation,
  UpdateProcessMutationVariables
>;
export const UpdateCycleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCycle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cycle' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'editCycleInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCycle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'data' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'cycle' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cycle' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCycleMutation, UpdateCycleMutationVariables>;
export const CreateProcessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProcess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'process' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ProcessInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProcess' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'data' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'process' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'process' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateProcessMutation,
  CreateProcessMutationVariables
>;
export const CreateCycleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCycle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cycle' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CycleInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCycle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'data' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'cycle' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cycle' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCycleMutation, CreateCycleMutationVariables>;
export const UpdateCycleevaluationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCycleevaluation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cycleevaluation' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'editCycleevaluationInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCycleevaluation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'data' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'cycleevaluation' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cycleevaluation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCycleevaluationMutation,
  UpdateCycleevaluationMutationVariables
>;
export const GetCoursesForDashboardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCoursesForDashboard' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'enabled' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'confirmedAt' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCoursesForDashboardQuery,
  GetCoursesForDashboardQueryVariables
>;
export const GetEvaluatorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEvaluators' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'evaluators' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '500' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'blocked' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'info' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstname' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastname' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'gender' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEvaluatorsQuery, GetEvaluatorsQueryVariables>;
export const CreateEvaluatorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateEvaluator' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'evaluator' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EvaluatorInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEvaluator' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'data' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'evaluator' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'evaluator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateEvaluatorMutation,
  CreateEvaluatorMutationVariables
>;
export const CreateUserinfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateUserinfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userinfo' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UserinfoInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUserinfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'data' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userinfo' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userinfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateUserinfoMutation,
  CreateUserinfoMutationVariables
>;
export const DeleteUserinfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteUserinfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUserinfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userinfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteUserinfoMutation,
  DeleteUserinfoMutationVariables
>;
export const CreateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'user' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UserInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'data' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateEvaluatorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateEvaluator' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'evaluator' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'editEvaluatorInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateEvaluator' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'data' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'evaluator' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'evaluator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateEvaluatorMutation,
  UpdateEvaluatorMutationVariables
>;
export const UpdateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'user' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'editUserInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'data' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateUserinfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserinfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userinfo' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'editUserinfoInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserinfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'data' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userinfo' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userinfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserinfoMutation,
  UpdateUserinfoMutationVariables
>;
export const GetEvaluatorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEvaluator' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'evaluator' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'short' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'username' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'blocked' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'info' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstname' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastname' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'gender' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEvaluatorQuery, GetEvaluatorQueryVariables>;
export const GetCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCategories' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCategoriesQuery, GetCategoriesQueryVariables>;
export const GetProvidersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProviders' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'providers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '500' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProvidersQuery, GetProvidersQueryVariables>;
export const CustomCreateProviderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CustomCreateProvider' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'provider' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ProviderInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contacts' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ContactInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customCreateProvider' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'provider' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'provider' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contacts' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'contacts' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CustomCreateProviderMutation,
  CustomCreateProviderMutationVariables
>;
export const CustomUpdateProviderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CustomUpdateProvider' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'provider' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'editProviderInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contacts' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'editContactInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customUpdateProvider' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'provider' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'provider' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contacts' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'contacts' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CustomUpdateProviderMutation,
  CustomUpdateProviderMutationVariables
>;
export const GetProviderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProvider' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'provider' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'homepage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'streetnumber' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstname' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastname' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gender' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProviderQuery, GetProviderQueryVariables>;

import React from 'react';
import { Link } from 'react-router-dom';
import { Fab } from '@mui/material';
import { FabProps } from '@mui/material/Fab';

interface FabLinkProps extends FabProps {
  to: string;
  replace?: boolean;
}

export const FabLink = (props: FabLinkProps) => (
  <Fab {...props} component={Link as any} />
);

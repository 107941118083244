import { Chip } from '@mui/material';
import { format } from 'date-fns';
import { FormikErrors, FormikHelpers } from 'formik';
import { isEqual, isObject, transform } from 'lodash';
import { lensPath, set, view } from 'ramda';
import { ContactInput, Enum_Contact_Title, UserinfoInput } from 'gql/graphql';
import { CourseState } from '../pages/course/types';

export type Userinfo =
  | Pick<ContactInput, 'firstname' | 'lastname' | 'title' | 'gender'>
  | Pick<UserinfoInput, 'firstname' | 'lastname' | 'title' | 'gender'>;

export const formatToName = (user: Userinfo) => {
  if (!user) {
    return 'Unbekannt';
  }
  let title = '';
  if (user.title) {
    if (user.title === Enum_Contact_Title.Dr) {
      title = 'Dr.';
    }
    if (user.title === Enum_Contact_Title.Prof) {
      title = 'Prof.';
    }
  }
  return `${title} ${user.firstname} ${user.lastname}`.trim();
};

/**
 * https://gist.github.com/Yimiprod/7ee176597fef230d1451
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export const difference = (objectOuter: any, baseOuter: any) => {
  function changes(object: any, base: any) {
    return transform(object, (result: any, value: any, key: any) => {
      if (!isEqual(value, base[key])) {
        (result as any)[key] =
          isObject(value) && isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }
  return changes(objectOuter, baseOuter);
};

export const stateToString = (state: CourseState | undefined) =>
  state ? (
    <>
      {niceFormat(state.date)} | {state.name}{' '}
      {typeof state.diff === 'number' && (
        <Chip component="span" label={getDiffLabel(state.diff)} />
      )}
    </>
  ) : (
    ''
  );

const getDiffLabel = (days: number) => {
  if (days === 1) {
    return `Endet morgen`;
  } else if (days === 0) {
    return 'Endet heute';
  } else if (days === -1) {
    return 'Endete gestern';
  } else if (days > 0) {
    return `Endet in ${days} Tagen`;
  } else {
    return `Endete vor ${Math.abs(days)} Tagen`;
  }
};

export const generateId = () => `id_${Math.random().toString(36).substr(2, 5)}`;

export const mandatory = 'Bitte ausfüllen';
export const url = 'Bitte korrekte URL angeben';
export const email = 'Bitte gültige E-Mail eingeben';
export const commentRequired = 'Bitte geben Sie eine Begründung an.';

interface ErrorMapper {
  field: string;
  error: string;
  match: string[];
}

/**
 * return error message from GraphQL-Query/Mutation and set field errors based on regex in error
 * @param e
 * @param setErrors
 * @param errorMap
 */
export const handleErrors = (
  e: any,
  setErrors?: FormikHelpers<any>['setErrors'],
  errorMap?: ErrorMapper[],
) => {
  let catched = false;
  if (errorMap && setErrors) {
    const errors = errorMap.reduce<FormikErrors<any>>((result, error) => {
      const hasError = error.match.some(match =>
        JSON.stringify(e).toLowerCase().includes(match),
      );
      if (hasError) {
        catched = true;
        const modifiedResult = set(
          lensPath(error.field.split('.')),
          error.error,
          result,
        );
        return modifiedResult;
      } else {
        return result;
      }
    }, {});

    setErrors(errors);
  }

  // do not show errorMessage when error is shown at form-field
  if (catched) {
    return '';
  }

  let errorMessage = e.message || 'Ein allgemeiner Fehler ist augetreten';
  const graphQLLens = lensPath(['graphQLErrors', 0, 'message']);
  const exceptionLens = lensPath([
    'networkError',
    'result',
    'errors',
    0,
    'extensions',
    'exception',
    'data',
  ]);
  const errorsLens = lensPath(['errors', 0, 'message']);

  [graphQLLens, exceptionLens, errorsLens].forEach(lens => {
    const error = view(exceptionLens, e);
    if (error) {
      errorMessage = error;
    }
  });

  return String(errorMessage);
};

export const niceFormat = (date: string) => {
  if (!date || date.includes('1900-01-01')) {
    return 'unbekannt';
  } else {
    return format(new Date(date), 'dd.MM.yyyy');
  }
};
export const inputDateFormat = 'yyyy-MM-dd';

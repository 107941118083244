import { Button, Grid, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { EasySuggest } from 'components/Fields/EasySuggest';
import { Form, Formik, FormikConfig, FormikProps } from 'formik';
import { ButtonLink } from '../../../components/ButtonLink/ButtonLink';
import { EasyTextfield } from '../../../components/Fields/EasyTextfield';
import { CourseCreateFormValues } from '../types';
import { CreateCourseFormSchema } from './validation';
import { useQuery } from '@apollo/client';
import { GET_CREATE_COURSE_QUERY } from '../graphql';

const useStyles = makeStyles(theme => ({
  subHeadline: { margin: '50px 0 20px 0' },
  confirmationContainer: { marginTop: 40 },
  confirmationButton: { marginRight: 8 },
}));

export const CreateCourseForm = ({
  ...rest
}: FormikConfig<CourseCreateFormValues>) => {
  const classes = useStyles();
  const { data } = useQuery(GET_CREATE_COURSE_QUERY);

  return (
    <Formik
      validationSchema={CreateCourseFormSchema}
      validateOnChange={false}
      {...rest}
    >
      {(formik: FormikProps<CourseCreateFormValues>) => (
        <Form noValidate autoComplete="off">
          <Typography
            component="h2"
            variant="h5"
            className={classes.subHeadline}
          >
            Allgemeine Informationen
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <EasySuggest
                name="provider"
                label="Anbieter"
                required
                options={
                  (data &&
                    data.providers &&
                    data.providers.map(provider => ({
                      label: provider!.name,
                      value: provider!.id,
                    }))) ||
                  []
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <EasyTextfield
                required
                name="category"
                label="Kategorie"
                fullWidth
                select
                margin="normal"
              >
                {data?.categories?.map(category => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </EasyTextfield>
            </Grid>
            <Grid item xs={12} md={4}>
              <EasyTextfield
                id="form-predecessor"
                name="predecessor"
                label="Vorgänger-Kurs-Id"
                margin="normal"
                fullWidth
              />
            </Grid>
          </Grid>
          <div className={classes.confirmationContainer}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              className={classes.confirmationButton}
            >
              Speichern
            </Button>

            <ButtonLink
              variant="contained"
              to={'/course'}
              className={classes.confirmationButton}
            >
              Abbrechen
            </ButtonLink>
          </div>
        </Form>
      )}
    </Formik>
  );
};

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { AuthContextType, LoginFormValues, Me } from './types';

import { addApiToken, removeApiToken } from '../../utils/api/api';
import { LOCAL_STORAGE_TOKEN } from '../../utils/constants';
import { mandatory } from '../../utils/formatter';
import { EasyTextfield } from '../Fields/EasyTextfield';
import { handleErrors } from '../../utils/formatter';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ME, LOGIN_MUTATION } from './graphql';
import { GetMeQuery } from 'gql/graphql';
import { makeStyles } from '@mui/styles';

export const AuthContext = React.createContext<AuthContextType>(null as any);

const initialValues: LoginFormValues = { identifier: '', password: '' };

export const AuthFormSchema = Yup.object().shape({
  identifier: Yup.string().required(mandatory),
  password: Yup.string().required(mandatory),
});

const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.main,
  },
}));

export const useAuth = () => {
  return useContext(AuthContext);
};

const parseToMe = (data: NonNullable<GetMeQuery['me']>): Me => ({
  id: data.id,
  name: data.username,
  info: data.info!,
  isAdministrator:
    data.role!.type === 'root' || data.role!.type === 'administrator', // "old" role is 'root', new role is "administrator"
  evaluator: data.evaluator ? data.evaluator.id : '',
});

// get my user profile: http://localhost:1337/users/me
export const Auth = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();

  const [me, setMe] = useState<Me | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [loginMutation] = useMutation(LOGIN_MUTATION);
  const { data: dataMe, error: errorMe } = useQuery(GET_ME);

  useEffect(() => {
    (async function getMeAsync() {
      if (dataMe && dataMe.me) {
        setMe(parseToMe(dataMe.me));
        setLoading(false);
      }
      if (errorMe) {
        logout();
        setLoading(false);
      }
    })();
  }, [dataMe, errorMe]);

  const logout = () => {
    localStorage.removeItem(LOCAL_STORAGE_TOKEN);
    removeApiToken();
    setMe(null);
  };

  const login = async (loginValues: LoginFormValues) => {
    try {
      const user = await loginMutation({
        variables: { input: loginValues },
      });
      if (user.data?.login.jwt) {
        localStorage.setItem(LOCAL_STORAGE_TOKEN, user.data.login.jwt);
        addApiToken(user.data.login.jwt);
        setMe(parseToMe(user.data.login.user));
      }
    } catch (error) {
      let errorMessage = handleErrors(error);
      if (errorMessage.includes('Bad Request')) {
        errorMessage = 'Bitte überprüfen Sie Benutzername und Passwort';
      }
      setError(errorMessage);
    }
  };

  return me ? (
    <AuthContext.Provider value={{ logout, me }}>
      {children}
    </AuthContext.Provider>
  ) : loading ? null : (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        login(values);
      }}
      validationSchema={AuthFormSchema}
    >
      {({ isSubmitting }: FormikProps<LoginFormValues>) => (
        <Dialog open={true}>
          <Form noValidate autoComplete="off">
            <DialogTitle>DIGAB-Login</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Bitte geben Sie Ihre Benutzerdaten ein für den Zugang zur
                Zertifizierungsplattform.
              </DialogContentText>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <EasyTextfield
                    id="form-identifier"
                    name="identifier"
                    label="Benutzername"
                    required
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <EasyTextfield
                    id="form-password"
                    name="password"
                    label="Passwort"
                    required
                    margin="normal"
                    fullWidth
                    type="password"
                  />
                </Grid>
              </Grid>
              <Typography className={classes.error}>{error}</Typography>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" type="submit">
                Login
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router';
import { ButtonLink } from '../ButtonLink/ButtonLink';

const useStyles = makeStyles(theme => ({
  navButton: {
    marginLeft: 10,
    marginBottom: 10,
  },
  navContainer: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
  },
}));

export const Navigation = () => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <div className={classes.navContainer}>
      <ButtonLink
        variant="contained"
        to="/"
        color={location.pathname === '/' ? 'primary' : 'grey'}
        className={classes.navButton}
      >
        Dashboard
      </ButtonLink>
      <ButtonLink
        variant="contained"
        color={location.pathname.indexOf('provider') > -1 ? 'primary' : 'grey'}
        to="/provider"
        className={classes.navButton}
      >
        Kursanbieter
      </ButtonLink>
      <ButtonLink
        variant="contained"
        color={location.pathname.indexOf('evaluator') > -1 ? 'primary' : 'grey'}
        to="/evaluator"
        className={classes.navButton}
      >
        Prüfer
      </ButtonLink>
      <ButtonLink
        variant="contained"
        color={location.pathname.indexOf('course') > -1 ? 'primary' : 'grey'}
        to="/course"
        className={classes.navButton}
      >
        Kurse
      </ButtonLink>
    </div>
  );
};

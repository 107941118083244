import { Paper, Tab, Tabs, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { ProviderHeadline } from './ProviderHeadline';

import { find, pluck } from 'ramda';
import React, { useState } from 'react';
import { useAuth } from '../../../components/Auth/Auth';
import { Comment } from '../../../components/Comment/Comment';
import { ConfirmDialog } from '../../../components/Dialog/ConfirmDialog';
import { handleErrors } from '../../../utils/formatter';
import { EvaluationFormValues, EvaluationInnerProps } from '../types';
import { Documents } from './Documents';
import { EvaluationForm } from './EvaluationForm';
import { Progress } from './Progress';
import { UPDATE_CYCLEEVALUATION_MUTATION } from '../graphql';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  tabBar: { background: '#eee' },
  subHeadline: { margin: '50px 0 20px 0' },
  confirmationContainer: { marginTop: 40 },
  confirmationButton: { marginRight: 8 },
  evaluationWrapper: { marginTop: 30 },
  evaluationContainer: { padding: '10px 24px' },
  evaluationRadioGroup: { flexDirection: 'row' },
  evaluationHeader: { background: '#eee', padding: '10px 20px' },
  error: {
    marginTop: 5,
    color: theme.palette.error.main,
  },
}));

export const Evaluation = ({
  course,
  criteria,
  target,
  documenttypes,
}: EvaluationInnerProps) => {
  const classes = useStyles();
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const [valuesToUpdate, setValuesToUpdate] =
    useState<EvaluationFormValues | null>(null);

  // set initial process to target process
  const [selectedProcess, selectProcess] = useState(
    course.processes
      ? pluck('id', course.processes).indexOf(target.processId)
      : 0,
  );

  const auth = useAuth();

  const [updateCycleevaluation] = useMutation(UPDATE_CYCLEEVALUATION_MUTATION);

  const handleConfirmFinishing = () => {
    updateValues(valuesToUpdate!);
    setValuesToUpdate(null);
  };

  const handleCancelFinishing = () => {
    setValuesToUpdate(null);
  };

  const updateValues = async (values: EvaluationFormValues) => {
    try {
      await updateCycleevaluation({
        variables: {
          id: String(target.cycleevaluationId),
          cycleevaluation: values,
        },
      });
      navigate('/course');
    } catch (e) {
      const errorMessage = handleErrors(e);
      setError(errorMessage);
    }
  };

  return (
    <>
      <PageHeader>Kurs bewerten</PageHeader>

      <ProviderHeadline
        name={course.provider!.name}
        category={
          course.category!.name +
          (course.processes!.length > 1 ? ' (Nachmeldung)' : '')
        }
      />

      {course.comment && <Comment comment={course.comment} />}
      <Progress course={course} />

      <Typography component="h2" variant="h5" className={classes.subHeadline}>
        Dokumente
      </Typography>

      <Paper>
        <Tabs
          className={classes.tabBar}
          value={selectedProcess}
          indicatorColor="primary"
          textColor="primary"
          onChange={(event, newProcess) => selectProcess(newProcess)}
        >
          {course.processes!.map(
            (process, index) =>
              process && (
                <Tab
                  key={process.id}
                  id={process.id}
                  label={
                    process.certification
                      ? 'Zertifizierung'
                      : `${index}. Nachmeldung`
                  }
                />
              ),
          )}
        </Tabs>

        <Documents
          process={course.processes[selectedProcess]}
          target={target}
          documenttypes={documenttypes}
        />
      </Paper>

      <Typography component="h2" variant="h5" className={classes.subHeadline}>
        Protokoll
      </Typography>

      {auth.me.evaluator === target.evaluatorId ? (
        <>
          <EvaluationForm
            criteria={criteria}
            target={target}
            process={
              find(
                process => process!.id === target.processId,
                course.processes!,
              )!
            }
            onSubmit={values => {
              if (values.score === 0) {
                updateValues(values);
              } else {
                setValuesToUpdate(values);
              }
            }}
          />
          <ConfirmDialog
            title="Bewertung veröffentlichen"
            content="Die Bewertung kann anschließend nicht mehr verändert werden."
            onCancel={handleCancelFinishing}
            onConfirm={handleConfirmFinishing}
            open={Boolean(valuesToUpdate)}
          />
          {error && (
            <Typography className={classes.error}>
              Beim Speichern ist ein Fehler aufgetreten.
              <br />
              {error}
            </Typography>
          )}
        </>
      ) : (
        <Typography>{`Für den Kurs ${course.provider!.name} - ${
          course.category!.name
        } gibt es aktuell keine Prüfrunde`}</Typography>
      )}
    </>
  );
};

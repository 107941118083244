import { CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import {
  clone,
  difference as differenceRamda,
  isEmpty,
  omit,
  pluck,
} from 'ramda';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Base } from '../../components/Base/Base';
import { ConfirmDialog } from '../../components/Dialog/ConfirmDialog';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { LOCAL_STORAGE_TOKEN } from '../../utils/constants';
import {
  difference,
  handleErrors,
  inputDateFormat,
} from '../../utils/formatter';
import { EditCourseForm } from './helper/EditCourseForm';
import { ProviderHeadline } from './helper/ProviderHeadline';
import {
  CourseFormValues,
  CycleValue,
  UpdateCourseFormValues,
  UpdateProcessFormValues,
} from './types';
import {
  CREATE_CYCLE_MUTATION,
  CREATE_PROCESS_MUTATION,
  GET_COURSE_QUERY,
  GET_ENABLED_EVALUATORS_QUERY,
  UPDATE_COURSE_MUTATION,
  UPDATE_CYCLE_MUTATION,
  UPDATE_PROCESS_MUTATION,
} from './graphql';
import { useMutation, useQuery } from '@apollo/client';

const useStyles = makeStyles(theme => ({
  subHeadline: { margin: '50px 0 20px 0' },
  tabBar: { background: '#eee' },
  publishButton: { marginRight: 10, marginTop: 10 },
  tabContainer: { background: '#eee', display: 'flex' },
  tabLeft: { flex: 1 },
  tabRight: { alignSelf: 'center', marginRight: 4, marginLeft: 10 },
  process: { background: theme.palette.grey[50], padding: 20 },
  confirmationContainer: { marginTop: 40 },
  confirmationButton: { marginRight: 8 },
  error: {
    marginTop: 5,
    color: theme.palette.error.main,
  },
}));

const updateDocuments = async (values: UpdateProcessFormValues) => {
  const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);
  for (const processValue of values.processes) {
    for (const cycleValue of processValue.cycles) {
      for (const documentValue of cycleValue.documents) {
        // delete documents
        if (
          documentValue.originFileId &&
          documentValue.originFileId !== documentValue.file
        ) {
          await fetch(
            `${process.env.REACT_APP_SERVER_URL}upload/files/${documentValue.originFileId}`,
            {
              method: 'DELETE',
              headers: {
                authorization: `Bearer ${token}`,
              },
            },
          );
        }

        // upload documents (is automatically linked to document)
        if (documentValue.file && typeof documentValue.file === 'object') {
          const formData = new FormData();
          formData.append('files', documentValue.file);
          formData.append('ref', 'document');
          formData.append('refId', documentValue.id);
          formData.append('field', 'file');
          await fetch(`${process.env.REACT_APP_SERVER_URL}upload`, {
            method: 'POST',
            body: formData,
            headers: {
              authorization: `Bearer ${token}`,
            },
          });
        }
      }
    }
  }
};

const checkIfWillPublish = (
  newValues: UpdateProcessFormValues,
  oldValues: UpdateProcessFormValues,
) => {
  const diff = difference(newValues, oldValues) as any;
  let willPublish = false;
  if (!isEmpty(diff)) {
    if (diff && diff.processes) {
      diff.processes.forEach((process: any) => {
        if (process && process.cycles) {
          process.cycles.forEach((cycle: any) => {
            if (cycle.published) {
              willPublish = true;
            }
          });
        }
      });
    }
  }
  return willPublish;
};

export const CourseEditPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();

  // TODO: use only one query and combine these
  const {
    data: dataCourse,
    loading: loadingCourse,
    error: errorCourse,
  } = useQuery(GET_COURSE_QUERY, {
    // @ts-ignore
    variables: { id },
    skip: !id,
  });

  const [error, setError] = useState('');

  const {
    data: dataEvaluators,
    loading: loadingEvaluators,
    error: errorEvaluators,
  } = useQuery(GET_ENABLED_EVALUATORS_QUERY);

  const [updateCourse] = useMutation(UPDATE_COURSE_MUTATION);
  const [updateCycle] = useMutation(UPDATE_CYCLE_MUTATION);
  const [updateProcess] = useMutation(UPDATE_PROCESS_MUTATION);
  const [createProcess] = useMutation(CREATE_PROCESS_MUTATION);
  const [createCycle] = useMutation(CREATE_CYCLE_MUTATION);

  const [showCreateNewProcessModal, setShowCreateNewProcessModal] =
    useState(false);
  const [showCreateNewCycleModal, setShowCreateNewCycleModal] = useState('');
  const [valuesToUpdate, setValuesToUpdate] = useState<CourseFormValues | null>(
    null,
  );

  const handleConfirmCreateNewCycle = async () => {
    await createCycle({
      variables: {
        cycle: {
          process: showCreateNewCycleModal,
          score: 0,
          published: false,
          cancelled: false,
          expirationReminded: false,
        },
      },
    });
    window.location.reload();
  };

  const handleCancelCreateNewCycle = () => {
    setShowCreateNewCycleModal('');
  };

  const handleConfirmCreateNewProcess = async () => {
    setShowCreateNewProcessModal(false);
    await createProcess({
      variables: {
        process: {
          course: id,
          certification: false,
          expirationReminded: false,
        },
      },
    });
    window.location.reload();
  };

  const handleCancelCreateNewProcess = () => {
    setShowCreateNewProcessModal(false);
  };

  if (loadingCourse || loadingEvaluators) {
    return (
      <Base>
        <CircularProgress />
      </Base>
    );
  } else if (errorCourse || errorEvaluators) {
    return (
      <Base>
        <>
          <h1>ERROR</h1>
          <Typography>
            {(errorCourse && JSON.stringify(errorCourse)) ||
              (errorEvaluators && JSON.stringify(errorEvaluators))}
          </Typography>
        </>
      </Base>
    );
  } else if (
    dataCourse &&
    dataCourse.course &&
    dataEvaluators &&
    dataEvaluators.evaluators
  ) {
    const initialCourseValues: UpdateCourseFormValues = {
      comment: dataCourse.course.comment,
      enabled: dataCourse.course.enabled,
      contact: dataCourse.course.contact ? dataCourse.course.contact.id : '',
      evaluators: dataCourse.course.evaluators
        ? dataCourse.course.evaluators.reduce<string[]>(
            (acc, evaluator) => (evaluator ? [...acc, evaluator.id] : acc),
            [],
          )
        : [],
    };

    const initialProcessValues: UpdateProcessFormValues = {
      processes: dataCourse.course.processes.map(process => ({
        id: process!.id,
        confirmedAt:
          typeof process!.confirmedAt === 'string'
            ? format(new Date(process!.confirmedAt), inputDateFormat)
            : undefined,
        paidAt:
          typeof process!.paidAt === 'string'
            ? format(new Date(process!.paidAt), inputDateFormat)
            : undefined,
        cycles: process!.cycles!.map(cycle => ({
          id: cycle!.id,
          published: Boolean(cycle!.published),
          cancelled: Boolean(cycle!.cancelled),
          requestedAt:
            typeof cycle!.requestedAt === 'string'
              ? format(new Date(cycle!.requestedAt), inputDateFormat)
              : undefined,
          documents: cycle!.documents!.map(document => ({
            id: document!.id,
            file: document!.file ? document!.file.id : null,
            originFileId: document!.file ? document!.file.id : null,
          })),
        })),
      })),
    };

    const updateValues = async (values: CourseFormValues) => {
      if (id) {
        try {
          if (values.course.contact === '') {
            values.course.contact = null;
          }
          // update Course
          await updateCourse({
            variables: { course: values.course, id },
          });

          // update documents
          await updateDocuments(values.process);

          // update process
          const processes = differenceRamda(
            values.process.processes.map(omit(['cycles'])),
            initialProcessValues!.processes.map(omit(['cycles'])),
          );
          for (const process of processes) {
            // we need to add Time and Timezone to support Datetime-Format
            if (process.confirmedAt) {
              process.confirmedAt += 'T00:00:00Z';
            } else {
              (process as any).confirmedAt = null;
            }
            if (process.paidAt) {
              process.paidAt += 'T00:00:00Z';
            } else {
              (process as any).paidAt = null;
            }
            await updateProcess({
              variables: { id: process.id, process: omit(['id'], process) },
            });
          }

          // update cycles
          const cycles = differenceRamda(
            ([] as CycleValue[])
              .concat(...pluck('cycles', values.process.processes))
              .map(omit(['documents'])),
            ([] as CycleValue[])
              .concat(...pluck('cycles', initialProcessValues!.processes))
              .map(omit(['documents'])),
          );
          for (const cycle of cycles) {
            // we need to add Time and Timezone to support Datetime-Format
            if (cycle.requestedAt) {
              cycle.requestedAt += 'T00:00:00Z';
            } else {
              (cycle as any).requestedAt = null;
            }

            await updateCycle({
              variables: { id: cycle.id, cycle: omit(['id'], cycle) },
            });
          }
          navigate('/course');
        } catch (e) {
          const errorMessage = handleErrors(e);
          setError(errorMessage);
        }
      }
    };

    const handleCreateNewCycle = (processId: string) =>
      setShowCreateNewCycleModal(processId);

    const handleCreateNewProcess = () => setShowCreateNewProcessModal(true);

    const handleConfirmPublishing = () => {
      updateValues(valuesToUpdate!);
      setValuesToUpdate(null);
    };

    const handleCancelPublishing = () => {
      setValuesToUpdate(null);
    };

    return (
      <Base>
        <PageHeader>Kurs bearbeiten</PageHeader>

        <ProviderHeadline
          name={dataCourse.course.provider!.name}
          category={dataCourse.course.category!.name}
        />
        <EditCourseForm
          processes={dataCourse.course.processes!}
          evaluators={dataEvaluators.evaluators!}
          course={dataCourse.course!}
          onCreateNewCycle={handleCreateNewCycle}
          onCreateNewProcess={handleCreateNewProcess}
          initialValues={{
            course: initialCourseValues,
            process: initialProcessValues,
          }}
          onSubmit={async values => {
            const willPublish = checkIfWillPublish(
              values.process,
              initialProcessValues,
            );
            // clone values to prevent modification
            if (willPublish) {
              setValuesToUpdate(clone(values));
            } else {
              updateValues(clone(values));
            }
          }}
        />
        {error && (
          <Typography className={classes.error}>
            Beim Speichern ist ein Fehler aufgetreten.
            <br />
            {error}
          </Typography>
        )}
        <ConfirmDialog
          title="Neue Nachmeldung"
          content="Es wird eine neue Nachmeldung angelegt. Nicht gespeicherte Änderungen werden verworfen."
          onCancel={handleCancelCreateNewProcess}
          onConfirm={handleConfirmCreateNewProcess}
          open={showCreateNewProcessModal}
        />
        <ConfirmDialog
          title="Neue Prüfrunde"
          content="Es wird eine neue Prüfrunde angelegt. Nicht gespeicherte Änderungen werden verworfen."
          onCancel={handleCancelCreateNewCycle}
          onConfirm={handleConfirmCreateNewCycle}
          open={Boolean(showCreateNewCycleModal)}
        />
        <ConfirmDialog
          title="Runde freigeben"
          content="Die Runde wird freigegeben und eine E-Mail an alle eingetragenen Prüfer verschickt."
          onCancel={handleCancelPublishing}
          onConfirm={handleConfirmPublishing}
          open={Boolean(valuesToUpdate)}
        />
      </Base>
    );
  } else {
    return (
      <Base>
        <Typography>Der Kurs existiert nicht</Typography>
      </Base>
    );
  }
};

import { TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import { useField } from 'formik';

type FormikTextfieldProps = {
  name: string;
} & Pick<
  TextFieldProps,
  | 'id'
  | 'margin'
  | 'required'
  | 'fullWidth'
  | 'label'
  | 'children'
  | 'select'
  | 'type'
  | 'disabled'
  | 'multiline'
  | 'rows'
  | 'helperText'
  | 'placeholder'
  | 'InputLabelProps'
  | 'className'
  | 'size'
>;

export const EasyTextfield = ({
  name,
  helperText,
  ...rest
}: FormikTextfieldProps) => {
  const [field, meta] = useField(name);
  const { error, touched } = meta;
  const { value, onChange, onBlur } = field;

  return (
    <TextField
      {...rest}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={touched && Boolean(error)}
      helperText={(touched && error) || helperText}
    />
  );
};

import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GetCoursesForDashboardCourse } from '../../../types/api';
import { stateToString } from '../../../utils/formatter';
import { useNavigate } from 'react-router-dom';

export interface DashboardPanelProps {
  courses: GetCoursesForDashboardCourse[];
  label: string;
}

const useStyles = makeStyles(theme => ({
  listBackground: { background: theme.palette.grey[50], marginTop: 20 },
  subheader: { background: theme.palette.grey[50] },
}));

export const DashboardPanel = ({
  courses,
  label,
  ...other
}: DashboardPanelProps) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Paper className={classes.listBackground}>
      <List
        dense
        subheader={
          <ListSubheader className={classes.subheader}>{label}</ListSubheader>
        }
        {...other}
      >
        {courses.map(course => (
          <ListItemButton
            onClick={() => navigate(`/course/view/${course!.id}`)}
            key={course!.id}
            data-testid="course"
          >
            <ListItemText
              primary={stateToString(course!.state)}
              secondary={`${course!.provider!.name} | ${
                course!.category!.name
              }`}
            />
          </ListItemButton>
        ))}
        {courses.length === 0 && (
          <ListItem>
            <ListItemText primary="Keine" />
          </ListItem>
        )}
      </List>
    </Paper>
  );
};

import { Maybe } from 'graphql/jsutils/Maybe';
import { remove } from 'ramda';

export const toggleInArray = (array: Array<Maybe<string>>, id: string) => {
  if (!array) {
    return [];
  }
  const index = array.indexOf(id);
  if (index > -1) {
    return remove(index, 1, array);
  } else {
    return [...array, id];
  }
};

import * as Yup from 'yup';
import { email, mandatory } from '../../../utils/formatter';

export const EvaluatorFormSchema = Yup.object().shape({
  evaluator: Yup.object().shape({
    short: Yup.string().required(mandatory),
  }),
  userinfo: Yup.object().shape({
    firstname: Yup.string().required(mandatory),
    lastname: Yup.string().required(mandatory),
  }),
  user: Yup.object().shape({
    email: Yup.string().required(mandatory).email(email),
    username: Yup.string().required(mandatory),
  }),
});

export const EvaluatorFormSchemaWithPassword = EvaluatorFormSchema.shape({
  user: Yup.object().shape({
    email: Yup.string().required(mandatory).email(email),
    username: Yup.string().required(mandatory),
    password: Yup.string().required(mandatory),
  }),
});

import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  comment: {
    marginBottom: 30,
  },
}));

interface CommentInnerProps {
  comment: string;
}

export const Comment = ({ comment }: CommentInnerProps) => {
  const classes = useStyles();

  return (
    <div className={classes.comment} data-testid="comment">
      <Typography variant="body2" color="primary">
        {comment}
      </Typography>
    </div>
  );
};

import { graphql } from 'gql/gql';

export const GET_EVALUATORS_QUERY = graphql(`
  query GetEvaluators {
    evaluators(limit: 500) {
      id
      categories {
        id
        name
      }
      user {
        id
        blocked
        info {
          id
          firstname
          lastname
          title
          gender
        }
      }
    }
  }
`);

export const CREATE_EVALUATOR_MUTATION = graphql(`
  mutation CreateEvaluator($evaluator: EvaluatorInput) {
    createEvaluator(input: { data: $evaluator }) {
      evaluator {
        id
      }
    }
  }
`);

export const CREATE_USERINFO_MUTATION = graphql(`
  mutation CreateUserinfo($userinfo: UserinfoInput) {
    createUserinfo(input: { data: $userinfo }) {
      userinfo {
        id
      }
    }
  }
`);

export const DELETE_USERINFO_MUTATION = graphql(`
  mutation DeleteUserinfo($id: ID!) {
    deleteUserinfo(input: { where: { id: $id } }) {
      userinfo {
        id
      }
    }
  }
`);

export const CREATE_USER_MUTATION = graphql(`
  mutation CreateUser($user: UserInput) {
    createUser(input: { data: $user }) {
      user {
        id
      }
    }
  }
`);

export const UPDATE_EVALUATOR_MUTATION = graphql(`
  mutation UpdateEvaluator($id: ID!, $evaluator: editEvaluatorInput) {
    updateEvaluator(input: { where: { id: $id }, data: $evaluator }) {
      evaluator {
        id
      }
    }
  }
`);

export const UPDATE_USER_MUTATION = graphql(`
  mutation UpdateUser($id: ID!, $user: editUserInput) {
    updateUser(input: { where: { id: $id }, data: $user }) {
      user {
        id
      }
    }
  }
`);

export const UPDATE_USERINFO_MUTATION = graphql(`
  mutation UpdateUserinfo($id: ID!, $userinfo: editUserinfoInput) {
    updateUserinfo(input: { where: { id: $id }, data: $userinfo }) {
      userinfo {
        id
      }
    }
  }
`);

export const GET_EVALUATOR_QUERY = graphql(`
  query GetEvaluator($id: ID!) {
    evaluator(id: $id) {
      id
      phone
      short
      categories {
        id
      }
      user {
        id
        email
        username
        blocked
        info {
          id
          firstname
          lastname
          title
          gender
        }
      }
    }
  }
`);

export const GET_CATEGORIES = graphql(`
  query GetCategories {
    categories {
      id
      name
    }
  }
`);

import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { Base } from '../../components/Base/Base';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { handleErrors } from '../../utils/formatter';
import { EvaluatorForm } from './helper/EvaluatorForm';
import { EvaluatorFormSchemaWithPassword } from './helper/validation';
import { EvaluatorFormValues } from './types';
import { FetchResult, useMutation } from '@apollo/client';
import {
  CreateUserinfoMutation,
  Enum_Userinfo_Gender,
  Enum_Userinfo_Title,
} from 'gql/graphql';
import {
  CREATE_EVALUATOR_MUTATION,
  CREATE_USERINFO_MUTATION,
  CREATE_USER_MUTATION,
  DELETE_USERINFO_MUTATION,
} from './graphql';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.main,
  },
}));

const initialValues: EvaluatorFormValues = {
  evaluator: {
    categories: [],
    phone: '',
    short: '',
  },
  user: {
    confirmed: true,
    email: '',
    password: '',
    username: '',
    blocked: false,
  },
  userinfo: {
    firstname: '',
    gender: Enum_Userinfo_Gender.Male,
    lastname: '',
    title: Enum_Userinfo_Title.None,
  },
};

export const EvaluatorAddPage = () => {
  const classes = useStyles();
  const [createUserInfo] = useMutation(CREATE_USERINFO_MUTATION);
  const [createEvaluator] = useMutation(CREATE_EVALUATOR_MUTATION);
  const [createUser] = useMutation(CREATE_USER_MUTATION);
  const [deleteUserInfo] = useMutation(DELETE_USERINFO_MUTATION);
  const navigate = useNavigate();

  const [error, setError] = useState('');

  return (
    <Base>
      <PageHeader>Prüfer hinzufügen</PageHeader>

      <EvaluatorForm
        type="add"
        validationSchema={EvaluatorFormSchemaWithPassword}
        initialValues={initialValues}
        onSubmit={async (values, { setErrors }) => {
          let createdUserinfo:
            | undefined
            | FetchResult<
                CreateUserinfoMutation,
                Record<string, any>,
                Record<string, any>
              >;

          try {
            createdUserinfo = await createUserInfo({
              variables: {
                userinfo: values.userinfo,
              },
            });

            const createdUser = await createUser({
              variables: {
                user: {
                  ...values.user,
                  info: createdUserinfo.data!.createUserinfo!.userinfo!.id,
                },
              },
            });

            await createEvaluator({
              variables: {
                evaluator: {
                  ...values.evaluator,
                  user: createdUser.data!.createUser!.user!.id,
                },
              },
            });

            navigate('/evaluator');
          } catch (e) {
            const errorMessage = handleErrors(e, setErrors, [
              {
                error: 'Die E-Mail ist bereits vergeben',
                field: 'user.email',
                match: ['email', 'E-Mail'],
              },
              {
                error: 'Der Benutzername ist bereits vergeben',
                field: 'user.username',
                match: ['username', 'benutzername'],
              },
            ]);
            setError(errorMessage);

            if (createdUserinfo && createdUserinfo.data) {
              deleteUserInfo({
                variables: {
                  id: createdUserinfo.data!.createUserinfo!.userinfo!.id,
                },
              });
            }
          }
        }}
      />
      {error && <Typography className={classes.error}>{error}</Typography>}
    </Base>
  );
};

import {
  Button,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Form, Formik, FormikProps } from 'formik';
import { useMemo } from 'react';
import { ButtonLink } from '../../../components/ButtonLink/ButtonLink';
import { EasyTextfield } from '../../../components/Fields/EasyTextfield';
import { ProtocolCriterion } from '../../../components/Protocol/ProtocolCriterion';
import { EvaluationFormInnerProps, EvaluationFormValues } from '../types';
import { Criterion } from './Criterion';
import {
  getCriteriaCycles,
  prepareCycleEvaluationsForProtocolCriterion,
} from './utils';
import { EvaluateCourseFormSchema } from './validation';

const useStyles = makeStyles(theme => ({
  confirmationContainer: { marginTop: 40 },
  confirmationButton: { marginRight: 8 },
  evaluationWrapper: { marginTop: 30 },
  evaluationContainer: { padding: '10px 24px' },
  evaluationRadioGroup: { flexDirection: 'row' },
  evaluationHeader: { background: '#eee', padding: '10px 20px' },
}));

export const EvaluationForm = ({
  process,
  criteria,
  target,
  ...rest
}: EvaluationFormInnerProps) => {
  const classes = useStyles();

  // get criteria cycles without target cycleevaluation
  const criteriaCycles = useMemo(
    () => getCriteriaCycles(process!, criteria, target.cycleevaluationId),
    [process, criteria, target.cycleevaluationId],
  );

  // get target cycleevaluation
  // TODO: check if IE support (polyfill?)
  const cycleEvaluation = process
    .cycles!.map(cycle => cycle.cycleevaluations)
    .flat()
    .find(cycleevaluation => cycleevaluation!.id === target.cycleevaluationId)!;

  const initialEvaluationValues: EvaluationFormValues = {
    comment: cycleEvaluation.comment,
    closedAt: cycleEvaluation.closedAt,
    score: cycleEvaluation.score,
    criterionevaluations: cycleEvaluation.criterionevaluations,
  };

  return (
    <Formik
      initialValues={initialEvaluationValues}
      isInitialValid
      validateOnChange={false}
      validationSchema={EvaluateCourseFormSchema}
      {...rest}
    >
      {({
        values,
        handleBlur,
        setFieldValue,
        isValid,
        submitCount,
      }: FormikProps<EvaluationFormValues>) => {
        return (
          <Form noValidate autoComplete="off">
            {values.criterionevaluations.map((criterionevaluation, index) => {
              const targetCriterionData = criteriaCycles.find(
                criterionData =>
                  criterionData.criterion.id === criterionevaluation.criterion,
              )!;
              return (
                <Criterion
                  key={criterionevaluation.criterion}
                  index={index}
                  values={criterionevaluation}
                  criterionData={targetCriterionData}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                />
              );
            })}

            <Paper
              elevation={1}
              className={classes.evaluationWrapper}
              data-testid="total"
            >
              <Typography
                variant="h6"
                component="h3"
                className={classes.evaluationHeader}
              >
                Meine Gesamtbewertung
              </Typography>

              <div className={classes.evaluationContainer}>
                <RadioGroup
                  name="score"
                  value={String(values.score)}
                  className={classes.evaluationRadioGroup}
                  onChange={event => {
                    setFieldValue(
                      'score',
                      Number((event.target as HTMLInputElement).value),
                    );
                  }}
                  onBlur={handleBlur}
                >
                  <FormControlLabel value="1" control={<Radio />} label="Ja" />
                  <FormControlLabel
                    value="-1"
                    control={<Radio />}
                    label="Nein"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Ausstehend"
                  />
                </RadioGroup>

                <EasyTextfield
                  id="form-comment"
                  name="comment"
                  label="Empfehlungen an die Kursoganisation / Begründung bei Ablehung"
                  multiline
                  rows={4}
                  fullWidth
                />
              </div>
              <ProtocolCriterion
                label="Andere Kommentare"
                cycles={prepareCycleEvaluationsForProtocolCriterion(
                  process.cycles,
                )}
              />
            </Paper>

            <div className={classes.confirmationContainer}>
              {!isValid && submitCount > 0 && (
                <Typography color="error" gutterBottom>
                  Es ist ein Fehler aufgetreten, bitte prüfen Sie ihre Eingaben
                </Typography>
              )}
              <Button
                variant="contained"
                type="submit"
                color="primary"
                className={classes.confirmationButton}
              >
                Speichern
              </Button>

              <ButtonLink
                variant="contained"
                to={'/course'}
                className={classes.confirmationButton}
              >
                Abbrechen
              </ButtonLink>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

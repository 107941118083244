import { Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../Auth/Auth';
import { Navigation } from '../Navigation/Navigation';
import { User } from '../User/User';
import logo from 'assets/logo.gif';

const useStyles = makeStyles(theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      width: 1200,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      padding: theme.spacing(3),
    },
  },
  header: {
    paddingBottom: 20,
    borderBottom: '1px solid #eee',
    marginBottom: 20,
  },
  container: {
    alignItems: 'flex-end',
  },
}));

type BaseProps = {
  children: React.ReactNode;
};

export const Base = ({ children }: BaseProps) => {
  const auth = useAuth();
  const classes = useStyles();

  return (
    <React.Fragment>
      <div>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <div className={classes.header}>
              <Grid container spacing={1} className={classes.container}>
                <Grid item xs={12} md={5}>
                  <Link to="/">
                    <img src={logo} alt="" width="315" height="111" />
                  </Link>
                </Grid>
                <Grid item xs={12} md={7}>
                  <User />
                  {auth.me.isAdministrator && <Navigation />}
                </Grid>
              </Grid>
            </div>
            {children}
          </Paper>
        </main>
      </div>
    </React.Fragment>
  );
};

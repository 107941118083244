import { CircularProgress, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import ViewIcon from '@mui/icons-material/Visibility';
import { addYears, endOfDay, isFuture } from 'date-fns';
import MaterialTable, { MTableBodyRow } from '@material-table/core';
import React from 'react';
import { Base } from '../../components/Base/Base';
import { FabLink } from '../../components/FabButtonLink/FabLink';
import { IconButtonLink } from '../../components/IconButtonLink/IconButtonLink';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { CERTIFICATION_YEARS } from '../../utils/constants';
import { stateToString } from '../../utils/formatter';
import { GET_COURSES_QUERY } from './graphql';
import { useQuery } from '@apollo/client';

export const CourseOverviewAdminPage = () => {
  const { data, loading, error } = useQuery(GET_COURSES_QUERY);

  return (
    <Base>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <>
          <h1>ERROR</h1>
          <Typography>{JSON.stringify(error)}</Typography>
        </>
      ) : (
        data &&
        data.courses && (
          <>
            <PageHeader>Kurse</PageHeader>
            <MaterialTable
              columns={[
                {
                  title: 'Zertifiziert',
                  field: 'certified',
                  type: 'boolean',
                  render: rowData =>
                    rowData.certified ? (
                      <CheckIcon style={{ marginLeft: 10 }} />
                    ) : (
                      ''
                    ),
                },
                {
                  title: 'Anbieter',
                  field: 'provider',
                  customSort: (a, b) =>
                    a.provider.toLowerCase() > b.provider.toLowerCase()
                      ? 1
                      : -1,
                  cellStyle: (cData, rowData) => {
                    return {
                      textDecoration: rowData.enabled ? 'none' : 'line-through',
                    };
                  },
                },
                {
                  title: 'Zertifizierungskategorie',
                  field: 'category',
                },
                {
                  title: 'Status',
                  field: 'state',
                  defaultSort: 'desc',
                  customFilterAndSearch: (term, rowData) =>
                    rowData.state.name.includes(term),
                  customSort: (a, b) => {
                    // state can be undefined
                    if (!a.state.date && !b.state.date) {
                      return 0;
                    }
                    return a.state.date > b.state.date || !b.state.date
                      ? 1
                      : -1;
                  },
                  render: rowData => stateToString(rowData.state),
                },
                {
                  filtering: false,
                  cellStyle: { textAlign: 'right', width: 130 },
                  render: rowData => (
                    <>
                      <IconButtonLink
                        to={`/course/view/${rowData!.id}`}
                        data-testid="viewCourse"
                      >
                        <ViewIcon fontSize="small" />
                      </IconButtonLink>
                      <IconButtonLink
                        to={`/course/edit/${rowData!.id}`}
                        data-testid="editCourse"
                      >
                        <EditIcon fontSize="small" />
                      </IconButtonLink>
                    </>
                  ),
                },
              ]}
              data={data.courses!.map(course => ({
                category: course!.category!.name,
                provider: course!.provider!.name,
                enabled: course!.enabled,
                state: course!.state,
                certified:
                  Boolean(course!.processes![0].confirmedAt) &&
                  isFuture(
                    endOfDay(
                      addYears(
                        new Date(course!.processes![0].confirmedAt),
                        CERTIFICATION_YEARS,
                      ),
                    ),
                  ),
                id: course!.id,
              }))}
              localization={{
                body: { emptyDataSourceMessage: 'Keine Kurse vorhanden' },
              }}
              options={{
                paging: true,
                pageSize: 50,
                pageSizeOptions: [],
                filtering: true,
                toolbar: false,
                emptyRowsWhenPaging: false,
                sorting: true,
              }}
              components={{
                Row: props => <MTableBodyRow {...props} />,
                Container: props => (
                  <div style={{ position: 'relative' }}>
                    <FabLink
                      to="/course/add"
                      size="small"
                      color="primary"
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        zIndex: 11,
                      }}
                      data-testid="addCourse"
                    >
                      <AddIcon />
                    </FabLink>
                    <div {...props} />
                  </div>
                ),
              }}
            />
          </>
        )
      )}
    </Base>
  );
};

import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { pickBy } from 'ramda';
import React, { useState } from 'react';
import { Base } from '../../components/Base/Base';
import { handleErrors, mandatory } from '../../utils/formatter';
import { CreateCourseForm } from './helper/CreateCourseForm';
import { CourseCreateFormValues } from './types';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { CREATE_COURSE_MUTATION } from './graphql';
import { PageHeader } from 'components/PageHeader/PageHeader';

const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.main,
  },
}));

const initialValues: CourseCreateFormValues = {
  provider: '',
  category: '',
  predecessor: '',
  enabled: true,
};

export const CourseAddPage = () => {
  const classes = useStyles();

  const navigate = useNavigate();
  const [createCourse] = useMutation(CREATE_COURSE_MUTATION);
  const [error, setError] = useState('');

  return (
    <Base>
      <PageHeader>Kurs hinzufügen</PageHeader>
      <CreateCourseForm
        initialValues={initialValues}
        onSubmit={async (values, { setErrors }) => {
          try {
            const isNotEmptyPredecessor = (value: any, key: string) =>
              key !== 'predecessor' || value !== '';

            const response = await createCourse({
              variables: { course: pickBy(isNotEmptyPredecessor, values) },
            });
            if (response.data) {
              navigate(
                `/course/edit/${response.data.createCourse!.course!.id}`,
              );
            }
          } catch (e) {
            const errorMessage = handleErrors(e, setErrors, [
              {
                field: 'provider',
                error: mandatory,
                match: ['provider-error'],
              },
              {
                field: 'category',
                error: mandatory,
                match: ['category-error'],
              },
              {
                field: 'predecessor',
                error: 'Kein Vorgänger-Kurs mit dieser ID vorhanden',
                match: ['predecessor-error'],
              },
            ]);
            setError(errorMessage);
          }
        }}
      />
      {error && <Typography className={classes.error}>{error}</Typography>}
    </Base>
  );
};

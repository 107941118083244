import React, { useState } from 'react';
import { prepareCycleEvaluationsForProtocolCriterion } from '../../pages/course/helper/utils';
import { CriterionCycles } from '../../pages/course/types';
import { GetCourseQueryCycle } from '../../types/api';
import { ProtocolCriterion } from './ProtocolCriterion';

interface ProtocolInnerProps {
  cycles: GetCourseQueryCycle[];
  criteriaCycles: CriterionCycles[];
}

export const Protocol = ({ cycles, criteriaCycles }: ProtocolInnerProps) => {
  const [expanded, expand] = useState('');

  return (
    <>
      {criteriaCycles.map((criterionCycles, groupIndex) => (
        <ProtocolCriterion
          key={criterionCycles.criterion.id}
          label={`${groupIndex + 1}. ${criterionCycles.criterion.name}`}
          cycles={criterionCycles.cycles}
          expanded={expanded === criterionCycles.criterion.id}
          onChange={() =>
            expand(
              expanded === criterionCycles.criterion.id
                ? ''
                : criterionCycles.criterion.id,
            )
          }
          data-testid="criterion"
        />
      ))}
      <ProtocolCriterion
        label="Empfehlungen an die Kursoganisation / Begründung bei Ablehung"
        cycles={prepareCycleEvaluationsForProtocolCriterion(cycles)}
        expanded={expanded === 'comment'}
        onChange={() => expand(expanded === 'comment' ? '' : 'comment')}
        data-testid="criterion"
      />
    </>
  );
};

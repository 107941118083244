import {
  Button,
  Fab,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewIcon from '@mui/icons-material/Visibility';
import cx from 'classnames';
import { useState } from 'react';
import { openPdf } from '../../../utils/files/files';
import { niceFormat } from '../../../utils/formatter';
import { EditCourseCyclesInnerProps } from '../types';
import { UploadFile } from 'gql/graphql';
import { makeStyles } from '@mui/styles';

const getFilename = (
  file: Pick<UploadFile, 'id' | 'name' | 'url' | 'updatedAt'>,
) => {
  const fileEnding = file.url.lastIndexOf('.');
  return fileEnding > 0
    ? `${file.name}.${file.url.substr(fileEnding + 1)}`
    : file.name;
};

const useStyles = makeStyles(theme => ({
  process: { padding: 20 },
  tabContainer: { background: '#eee', display: 'flex' },
  tabLeft: { flex: 1 },
  tabRight: { alignSelf: 'center', marginRight: 4, marginLeft: 10 },
  cycles: { padding: 20 },
  hidden: {
    display: 'none',
  },
  filename: {
    paddingLeft: 15,
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  alternate: {
    '&:nth-child(odd)': {
      background: theme.palette.grey[100],
    },
  },
  documentText: {
    display: 'flex',
    alignItems: 'center',
  },
  documentListItem: {
    height: 44,
  },
}));

export const EditCourseCycles = ({
  courseData,
  formik: { values, handleChange, handleBlur, setFieldValue },
  onCreateNewCycle,
  processIndex: selectedProcess,
}: EditCourseCyclesInnerProps) => {
  const classes = useStyles();

  const [selectedCycle, selectCycle] = useState(
    courseData.processes![selectedProcess]!.cycles!.length - 1,
  );

  const process = courseData.processes![selectedProcess]!;

  return (
    <Paper>
      <div className={classes.tabContainer}>
        <Tabs
          className={classes.tabLeft}
          value={selectedCycle}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          onChange={(event, newCycle) => selectCycle(newCycle)}
        >
          {process.cycles &&
            process.cycles.map(
              (cycle, index) =>
                cycle && (
                  <Tab
                    key={cycle.id}
                    label={`${index + 1}. Runde`}
                    data-testid="cycleTab"
                  />
                ),
            )}
        </Tabs>
        <Fab
          data-testid="addCycle"
          className={classes.tabRight}
          onClick={() => onCreateNewCycle(process.id)}
          size="small"
          color="primary"
        >
          <AddIcon />
        </Fab>
      </div>

      {process.cycles && process.cycles[selectedCycle] && (
        <div data-testid="cycleContent">
          <div className={classes.cycles}>
            <TextField
              id={`form-requestedAt_${selectedProcess}-${selectedCycle}`}
              margin="normal"
              name={`process.processes.${selectedProcess}.cycles.${selectedCycle}.requestedAt`}
              value={
                values.process.processes[selectedProcess].cycles[selectedCycle]
                  .requestedAt || ''
              }
              type="date"
              label="Nachbess. angefordert"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={
                    values.process.processes[selectedProcess].cycles[
                      selectedCycle
                    ].published
                  }
                  name={`process.processes.${selectedProcess}.cycles.${selectedCycle}.published`}
                  disabled={
                    courseData!.processes![selectedProcess]!.cycles![
                      selectedCycle
                    ]!.published ||
                    Boolean(
                      courseData!.processes![selectedProcess]!.cycles![
                        selectedCycle
                      ]!.closedAt,
                    )
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              label="Zur Prüfung freigeben"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={
                    values.process.processes[selectedProcess].cycles[
                      selectedCycle
                    ].cancelled
                  }
                  name={`process.processes.${selectedProcess}.cycles.${selectedCycle}.cancelled`}
                  disabled={Boolean(
                    courseData!.processes![selectedProcess]!.cycles![
                      selectedCycle
                    ]!.closedAt,
                  )}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              label="Runde schließen"
            />
          </div>
          <List>
            {process.cycles[selectedCycle].documents &&
              process.cycles[selectedCycle].documents.map(
                (document, documentIndex) =>
                  document &&
                  document.documenttype && (
                    <ListItem
                      className={cx(
                        classes.documentListItem,
                        classes.alternate,
                      )}
                      dense={true}
                      key={document.id}
                      data-testid="document"
                    >
                      <ListItemText
                        secondaryTypographyProps={{
                          className: classes.documentText,
                        }}
                        secondary={
                          <>
                            <input
                              className={classes.hidden}
                              id={`form-file_${selectedProcess}-${selectedCycle}-${documentIndex}`}
                              type="file"
                              onChange={event => {
                                const file = event.currentTarget.files![0];
                                setFieldValue(
                                  `process.processes.${selectedProcess}.cycles.${selectedCycle}.documents.${documentIndex}.file`,
                                  file,
                                );
                              }}
                            />
                            <label
                              htmlFor={`form-file_${selectedProcess}-${selectedCycle}-${documentIndex}`}
                            >
                              <Button
                                variant="contained"
                                component="span"
                                size="small"
                                data-testid="upload"
                              >
                                {document.documenttype.name}
                              </Button>
                            </label>
                            {values.process.processes[selectedProcess].cycles[
                              selectedCycle
                            ].documents[documentIndex].file && (
                              <>
                                <Typography
                                  className={classes.filename}
                                  display="inline"
                                  component="span"
                                >
                                  {values.process.processes[selectedProcess]
                                    .cycles[selectedCycle].documents[
                                    documentIndex
                                  ].file &&
                                    ((typeof values.process.processes[
                                      selectedProcess
                                    ].cycles[selectedCycle].documents[
                                      documentIndex
                                    ].file === 'string' &&
                                      `${niceFormat(
                                        document!.file!.updatedAt,
                                      )} ${getFilename(document!.file!)}`) ||
                                      (
                                        values.process.processes[
                                          selectedProcess
                                        ].cycles[selectedCycle].documents[
                                          documentIndex
                                        ].file! as File
                                      ).name)}
                                </Typography>
                                <IconButton
                                  onClick={event => {
                                    const documentValue =
                                      values.process.processes[selectedProcess]
                                        .cycles[selectedCycle].documents[
                                        documentIndex
                                      ].file;

                                    if (typeof documentValue === 'string') {
                                      openPdf(
                                        courseData.processes![selectedProcess]!
                                          .cycles![selectedCycle]!.documents![
                                          documentIndex
                                        ]!.file!.url,
                                      );
                                    } else if (documentValue !== null) {
                                      const url =
                                        window.URL.createObjectURL(
                                          documentValue,
                                        );
                                      window.open(url, 'Dokument');
                                    }
                                  }}
                                  data-testid="viewDocument"
                                  size="large"
                                >
                                  <ViewIcon />
                                </IconButton>
                                <IconButton
                                  onClick={event => {
                                    setFieldValue(
                                      `process.processes.${selectedProcess}.cycles.${selectedCycle}.documents.${documentIndex}.file`,
                                      null,
                                    );
                                    const inputField =
                                      event.currentTarget.parentElement!.parentElement!.querySelector(
                                        'input[type=file]',
                                      )! as HTMLInputElement;
                                    if (inputField) {
                                      inputField.value = '';
                                    }
                                  }}
                                  data-testid="deleteDocument"
                                  size="large"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </>
                            )}
                          </>
                        }
                      />
                    </ListItem>
                  ),
              )}
          </List>
        </div>
      )}
    </Paper>
  );
};

import { graphql } from 'gql/gql';

export const GET_PROVIDERS_QUERY = graphql(`
  query GetProviders {
    providers(limit: 500) {
      id
      name
      enabled
    }
  }
`);

export const CUSTOM_CREATE_PROVIDER_MUTATION = graphql(`
  mutation CustomCreateProvider(
    $provider: ProviderInput!
    $contacts: [ContactInput]
  ) {
    customCreateProvider(provider: $provider, contacts: $contacts) {
      provider {
        id
      }
    }
  }
`);

export const CUSTOM_UPDATE_PROVIDER_MUTATION = graphql(`
  mutation CustomUpdateProvider(
    $id: ID!
    $provider: editProviderInput
    $contacts: [editContactInput]
  ) {
    customUpdateProvider(id: $id, provider: $provider, contacts: $contacts) {
      provider {
        id
      }
    }
  }
`);

export const GET_PROVIDER_QUERY = graphql(`
  query GetProvider($id: ID!) {
    provider(id: $id) {
      id
      name
      street
      city
      zip
      homepage
      phone
      streetnumber
      enabled
      contacts {
        id
        firstname
        lastname
        email
        phone
        title
        gender
      }
    }
  }
`);

import { CircularProgress, Paper, Tab, Tabs, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useMemo, useState } from 'react';
import { ButtonLink } from '../../components/ButtonLink/ButtonLink';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { Documents } from './helper/Documents';
import { Progress } from './helper/Progress';
import { ProviderHeadline } from './helper/ProviderHeadline';

import { useAuth } from '../../components/Auth/Auth';
import { Base } from '../../components/Base/Base';
import { Comment } from '../../components/Comment/Comment';

import { History } from '../../components/History/History';
import { Protocol } from '../../components/Protocol/Protocol';
import { Evaluators } from './helper/Evaluators';
import { getCriteriaCycles, getEvaluatorsOfProcess } from './helper/utils';
import { GET_COURSE_QUERY } from './graphql';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  results: { margin: '50px 0 0 0' },
  protocolHeadline: { margin: '20px 0 0px 24px' },
  confirmationContainer: { marginTop: 40 },
  confirmationButton: { marginRight: 8 },
  tabBar: { background: '#eee' },
  protocol: { padding: 24 },
}));

export const CourseViewPage = () => {
  const classes = useStyles();

  const { id } = useParams();

  const { data, loading, error } = useQuery(GET_COURSE_QUERY, {
    // @ts-ignore
    variables: { id },
    skip: !id,
  });

  const [selectedProcess, selectProcess] = useState(
    data && data.course && data.course.processes
      ? data.course.processes.length - 1
      : 0,
  );

  const evaluators = useMemo(
    () =>
      data && data.course
        ? getEvaluatorsOfProcess(data.course.processes![selectedProcess]!)
        : [],
    [selectedProcess, data],
  );

  const auth = useAuth();

  useEffect(() => {
    if (!error && !loading && data && data.course) {
      selectProcess(data.course!.processes!.length - 1);
    } else {
      selectProcess(0);
    }
  }, [data, error, loading]);

  const criteriaCycles = useMemo(() => {
    if (data && data.course && data.criteria) {
      return getCriteriaCycles(
        data.course.processes![selectedProcess]!,
        data.criteria,
      );
    } else {
      return null;
    }
  }, [selectedProcess, data]);

  return (
    <Base>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <>
          <h1>ERROR</h1>
          <Typography>{error.message}</Typography>
        </>
      ) : (
        data &&
        data.course &&
        data.criteria &&
        criteriaCycles && (
          <>
            <PageHeader>Kurs anzeigen</PageHeader>

            <ProviderHeadline
              name={data.course.provider!.name}
              category={data.course.category!.name}
            />

            {data.course.comment && <Comment comment={data.course.comment} />}

            <Progress course={data.course} />

            <History courseId={data.course.id} />

            <Paper className={classes.results}>
              <Tabs
                className={classes.tabBar}
                value={selectedProcess}
                indicatorColor="primary"
                textColor="primary"
                onChange={(event, newProcess) => selectProcess(newProcess)}
              >
                {data.course.processes!.map(
                  (process, index) =>
                    process && (
                      <Tab
                        key={process.id}
                        id={process.id}
                        label={
                          process.certification
                            ? 'Zertifizierung'
                            : `${index}. Nachmeldung`
                        }
                        data-testid="cycleTab"
                      />
                    ),
                )}
              </Tabs>

              {data.course.processes![selectedProcess]!.cycles!.some(
                cycle => cycle!.published,
              ) ? (
                <>
                  <Typography
                    component="h2"
                    variant="h6"
                    className={classes.protocolHeadline}
                  >
                    Prüfer
                  </Typography>
                  <Evaluators evaluators={evaluators} />

                  <Typography
                    component="h2"
                    variant="h6"
                    className={classes.protocolHeadline}
                  >
                    Dokumente
                  </Typography>
                  <Documents
                    process={data.course.processes![selectedProcess]}
                    documenttypes={data.documenttypes}
                  />

                  <Typography
                    component="h2"
                    variant="h6"
                    className={classes.protocolHeadline}
                  >
                    Protokoll
                  </Typography>
                  <div className={classes.protocol}>
                    <Protocol
                      cycles={data.course.processes![selectedProcess]!.cycles!}
                      criteriaCycles={criteriaCycles}
                    />
                  </div>
                </>
              ) : // Show Documents of courses that have been created without any published cycles (pre existing courses before Certio)
              data.course.processes![selectedProcess].confirmedAt ? (
                <Documents
                  process={data.course.processes![selectedProcess]}
                  ignorePublished
                  documenttypes={data.documenttypes}
                />
              ) : (
                <Typography className={classes.protocol}>
                  Es wurde bisher noch keine Prüfrunde freigegeben.
                </Typography>
              )}
            </Paper>

            <div className={classes.confirmationContainer}>
              <ButtonLink
                variant="contained"
                to={'/course'}
                className={classes.confirmationButton}
              >
                Kursübersicht
              </ButtonLink>
              {auth.me.isAdministrator && (
                <ButtonLink
                  variant="contained"
                  to={`/course/edit/${id}`}
                  className={classes.confirmationButton}
                >
                  Kurs bearbeiten
                </ButtonLink>
              )}
            </div>
          </>
        )
      )}
    </Base>
  );
};

import { find } from 'ramda';
import {
  GetCourseQueryCriterion,
  GetCourseQueryCycle,
  GetCourseQueryCycleevaluation,
  GetCourseQueryEvaluator,
  GetCourseQueryProcess,
} from '../../../types/api';
import { CriterionCycle, CriterionCycles, CriterionEvaluation } from '../types';

export const prepareCycleEvaluationsForProtocolCriterion = (
  cycles: GetCourseQueryCycle[],
): CriterionCycle[] => {
  return cycles
    .filter(cycle => cycle.published)
    .map(cycle => ({
      id: cycle.id,
      criterionEvaluations: cycle.cycleevaluations
        .filter(cycleevaluation => cycleevaluation.score !== 0)
        .map(cycleevaluation => ({
          comment: cycleevaluation.comment!,
          score: cycleevaluation.score,
          evaluator: cycleevaluation.evaluator!,
        })),
    }));
};

/**
 * get all cycles for existing criteria
 */
export const getCriteriaCycles = (
  process: GetCourseQueryProcess,
  criteria: GetCourseQueryCriterion[],
  exludedCycleEvaluationId?: string,
) => {
  const criteriaCycles: CriterionCycles[] = [];
  let roundIndex = 0;
  process.cycles.forEach(cycle => {
    if (cycle.published && cycle!.cycleevaluations!.length > 0) {
      cycle.cycleevaluations!.forEach(cycleevaluation => {
        cycleevaluation!.criterionevaluations!.forEach(
          (criterionevaluation: CriterionEvaluation) => {
            let result = find(
              criterionResult =>
                criterionResult.criterion!.id === criterionevaluation.criterion,
              criteriaCycles,
            );
            if (!result) {
              result = {
                criterion: find(
                  criterion => criterion.id === criterionevaluation.criterion,
                  criteria,
                )!,
                cycles: [],
              };
              criteriaCycles.push(result);
            }
            if (!result.cycles[roundIndex]) {
              result.cycles[roundIndex] = {
                criterionEvaluations: [],
                id: cycle!.id,
              };
            }

            if (
              exludedCycleEvaluationId &&
              exludedCycleEvaluationId === cycleevaluation.id
            ) {
              return;
            }

            if (cycleevaluation.score !== 0) {
              result.cycles[roundIndex].criterionEvaluations.push({
                comment: criterionevaluation!.comment,
                score: criterionevaluation!.score,
                evaluator: cycleevaluation!
                  .evaluator! as GetCourseQueryEvaluator,
              });
            }
          },
        );
      });

      roundIndex++;
    }
  });

  return criteriaCycles;
};

export const getEvaluatorsOfProcess = (process: GetCourseQueryProcess) => {
  const evaluators: NonNullable<GetCourseQueryCycleevaluation['evaluator']>[] =
    [];

  process.cycles.forEach(cycle => {
    cycle!.cycleevaluations.forEach(cycleevaluation => {
      if (
        !evaluators.some(
          evaluator => evaluator!.id === cycleevaluation.evaluator!.id,
        )
      ) {
        evaluators.push(cycleevaluation.evaluator!);
      }
    });
  });

  return evaluators.sort((a, b) =>
    a!.user!.info!.lastname > b!.user!.info!.lastname ? 1 : -1,
  );
};

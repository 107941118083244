import { graphql } from 'gql/gql';

export const LOGIN_MUTATION = graphql(`
  mutation Login($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      jwt
      user {
        id
        email
        confirmed
        blocked
        username
        role {
          id
          name
          description
          type
        }
        info {
          id
          firstname
          lastname
          title
          gender
        }
        evaluator {
          id
          short
          phone
        }
      }
    }
  }
`);

export const GET_ME = graphql(`
  query GetMe {
    me {
      id
      username
      role {
        id
        name
        description
        type
      }
      info {
        firstname
        lastname
        title
        gender
      }
      evaluator {
        id
        short
        phone
      }
    }
  }
`);

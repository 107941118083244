import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Formik, FormikConfig, FormikProps } from 'formik';
import React from 'react';
import { ButtonLink } from '../../../components/ButtonLink/ButtonLink';
import { CourseFormValues, EditCourseFormInnerProps } from '../types';
import { EditCourseGeneral } from './EditCourseGeneral';
import { EditCourseProcesses } from './EditCourseProcesses';

const useStyles = makeStyles(theme => ({
  subHeadline: { margin: '50px 0 20px 0' },
  tabBar: { background: '#eee' },
  publishButton: { marginRight: 10, marginTop: 10 },
  tabContainer: { background: '#eee', display: 'flex' },
  tabLeft: { flex: 1 },
  tabRight: { alignSelf: 'center', marginRight: 4, marginLeft: 10 },
  process: { background: theme.palette.grey[50], padding: 20 },
  confirmationContainer: { marginTop: 40 },
  confirmationButton: { marginRight: 8 },
  courseViewButton: { float: 'right' },
}));

export const EditCourseForm = ({
  processes,
  course,
  evaluators,
  onCreateNewCycle,
  onCreateNewProcess,
  ...rest
}: EditCourseFormInnerProps & FormikConfig<CourseFormValues>) => {
  const classes = useStyles();

  return (
    <Formik {...rest}>
      {(formik: FormikProps<CourseFormValues>) => {
        return (
          <form noValidate autoComplete="off">
            <Typography
              component="h2"
              variant="h5"
              className={classes.subHeadline}
            >
              Allgemeine Einstellungen
            </Typography>
            <EditCourseGeneral
              formik={formik}
              courseData={course}
              evaluators={evaluators}
            />

            <Typography
              component="h2"
              variant="h5"
              className={classes.subHeadline}
            >
              Prozesse & Runden
            </Typography>
            <EditCourseProcesses
              onCreateNewCycle={onCreateNewCycle}
              onCreateNewProcess={onCreateNewProcess}
              formik={formik}
              courseData={course}
            />

            <div className={classes.confirmationContainer}>
              <Button
                variant="contained"
                onClick={() => formik.handleSubmit()}
                color="primary"
                className={classes.confirmationButton}
              >
                Speichern
              </Button>
              <ButtonLink
                variant="contained"
                to={'/course'}
                className={classes.confirmationButton}
              >
                Abbrechen
              </ButtonLink>
              <ButtonLink
                variant="contained"
                to={`/course/view/${course.id}`}
                className={classes.courseViewButton}
              >
                Kurs anzeigen
              </ButtonLink>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

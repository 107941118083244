import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { useMemo } from 'react';
import { InfoAvatar } from '../../../components/InfoAvatar/InfoAvatar';
import { GetCourseQueryFile } from '../../../types/api';
import { formatToName, niceFormat } from '../../../utils/formatter';
import { EvaluateDocumentsInnerProps } from '../types';

const useStyles = makeStyles(theme => ({
  active: {
    background: theme.palette.secondary.main,
  },
  table: {
    tableLayout: 'fixed',
    borderCollapse: 'separate',
    padding: 20,
  },
  tdName: {
    width: 300,
  },
  activeCell: {
    color: theme.palette.secondary.main,
  },
  yesCell: {
    color: theme.palette.yes.main,
  },
  noCell: {
    color: theme.palette.no.main,
  },
  outdated: {
    opacity: 0.4,
  },
  emptyMessage: { padding: 20 },
}));

interface CustomFile {
  [id: string]: GetCourseQueryFile & { latest: boolean };
}

export const Documents = ({
  process: processValue,
  ignorePublished = false,
  target,
  documenttypes,
}: EvaluateDocumentsInnerProps) => {
  const classes = useStyles();

  const currentPublishedCycles = useMemo(
    () =>
      processValue.cycles!.filter(cycle => cycle!.published || ignorePublished),
    [ignorePublished, processValue.cycles],
  );

  const { documentCycles, hasDocuments, filteredDocumentTypes } =
    useMemo(() => {
      const lastFilePerDocumenttype: CustomFile = {};
      let hasDocumentsValue = false; // check if there are any Documents
      const existingDocumentTypes: string[] = [];
      const cycles = currentPublishedCycles.map(cycle => {
        const files: CustomFile = {};
        cycle!
          .documents!.filter(
            document => document && document.file && document.documenttype,
          )
          .forEach(document => {
            hasDocumentsValue = true;
            const doc = { ...document.file!, latest: false };
            files[document!.documenttype!.id] = doc;
            lastFilePerDocumenttype[document!.documenttype!.id] = doc;
            existingDocumentTypes.push(document!.documenttype!.id);
          });

        return {
          id: cycle!.id,
          files,
        };
      });

      for (const prop in lastFilePerDocumenttype) {
        if (
          Object.prototype.hasOwnProperty.call(lastFilePerDocumenttype, prop)
        ) {
          lastFilePerDocumenttype[prop].latest = true;
        }
      }

      // make sure, that we have the same sort order everywhere
      const filteredDocumentTypesValue = documenttypes.filter(documenttype =>
        existingDocumentTypes.includes(documenttype.id),
      );

      return {
        documentCycles: cycles,
        hasDocuments: hasDocumentsValue,
        filteredDocumentTypes: filteredDocumentTypesValue,
      };
    }, [currentPublishedCycles, documenttypes]);

  return hasDocuments ? (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tdName}>Titel</TableCell>
          {currentPublishedCycles.map(cycle => (
            <TableCell
              component="th"
              scope="row"
              key={cycle.id}
              className={classnames({
                [classes.activeCell]: target && target.cycleId === cycle.id,
                [classes.yesCell]: cycle.score > 0,
                [classes.noCell]: cycle.score < 0,
              })}
              data-testid="date"
            >
              {niceFormat(cycle!.publishedAt!)}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredDocumentTypes.map(documenttype => (
          <TableRow key={documenttype.id} data-testid="documentRow">
            <TableCell>{documenttype.name}</TableCell>

            {documentCycles.map((cycle, index) => (
              <TableCell
                key={cycle.id}
                data-testid="documentButton"
                className={classnames({
                  // [classes.active]: cycle.id === currentCycleId && highlightCurrent,
                })}
              >
                {cycle.files[documenttype.id] && (
                  <Button
                    target="_blank"
                    href={`${process.env.REACT_APP_SERVER_URL}${cycle.files[
                      documenttype.id
                    ].url.substr(1)}`}
                    color={
                      target && target.cycleId === cycle.id
                        ? 'secondary'
                        : 'grey'
                    }
                    variant={
                      target && target.cycleId === cycle.id
                        ? 'contained'
                        : 'text'
                    }
                    className={classnames({
                      [classes.outdated]: !cycle.files[documenttype.id].latest,
                    })}
                  >
                    {index + 1}. Runde
                  </Button>
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell />
          {currentPublishedCycles.map(cycle => (
            <TableCell key={cycle!.id} data-testid="evaluators">
              {cycle!
                .cycleevaluations!.filter(
                  cycleevaluation => cycleevaluation.score !== 0,
                )
                .sort((first, next) =>
                  first.evaluator!.user!.info!.lastname <
                  next.evaluator!.user!.info!.lastname
                    ? -1
                    : 1,
                )
                .map(
                  cycleevaluation =>
                    cycleevaluation && (
                      <InfoAvatar
                        key={cycleevaluation.id}
                        data-testid="short"
                        status={
                          cycleevaluation.score > 0
                            ? 'yes'
                            : cycleevaluation.score < 0
                              ? 'no'
                              : 'open'
                        }
                        label={cycleevaluation.evaluator!.short}
                        tooltip={formatToName(
                          cycleevaluation.evaluator!.user!.info!,
                        )}
                      />
                    ),
                )}
            </TableCell>
          ))}
        </TableRow>
      </TableFooter>
    </Table>
  ) : (
    <Typography className={classes.emptyMessage}>
      Es sind keine Dokumente vorhanden
    </Typography>
  );
};

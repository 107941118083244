import { createTheme } from '@mui/material';
import '@fontsource/roboto';
import { grey } from '@mui/material/colors';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    grey: true;
  }
}

declare module '@mui/material' {
  interface Color {
    main: string;
    dark: string;
  }
}

export let theme = createTheme({
  palette: {
    primary: { main: '#01346a' },
    secondary: { main: '#ff9900' },
    no: { main: 'red' },
    yes: { main: 'green' },
    grey: {
      main: grey[600],
      dark: grey[700],
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 30,
          height: 30,
          fontSize: '1rem',
          fontWeight: 'bold',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'grey',
      },
      // necessary because "default" variant does not exist any more, so we use "grey"
      variants: [
        {
          props: { variant: 'contained', color: 'grey' },
          style: {
            color: theme.palette.getContrastText(theme.palette.grey[300]),
            background: theme.palette.grey[100],
            '&:hover': {
              background: theme.palette.grey[200],
            },
          },
        },
      ],
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiChip: {
      styleOverrides: {
        root: { height: 'auto' },
        label: { paddingLeft: 8, paddingRight: 8 },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        switchBase: {
          '&.Mui-checked.Mui-disabled': {},
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          paddingLeft: 10,
        },
        body: {
          padding: '4px 10px 4px 10px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: { fontSize: '2.8rem', fontWeight: 'normal' },
        h2: { fontSize: '2.2rem', fontWeight: 'normal' },
        h3: { fontSize: '1.8rem', fontWeight: 'normal' },
        h4: { fontSize: '1.5rem', fontWeight: 'normal' },
        h5: { fontSize: '1.3rem', fontWeight: 'normal' },
        h6: { fontSize: '1rem', fontWeight: 'normal' },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          width: '1em',
          height: '1em',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSelectField: {
      defaultProps: {
        variant: 'standard',
      },
    },
  },
});

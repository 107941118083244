import {
  Chip,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useMemo } from 'react';

import { addYears, isAfter, subDays } from 'date-fns';
import { drop } from 'ramda';
import { formatToName, niceFormat } from '../../../utils/formatter';
import { ProgressInnerProps } from '../types';
import { ProgressCycle } from './ProgressCycle';

const useStyles = makeStyles(theme => ({
  chip: {
    marginBottom: 10,
    padding: '6px 12px',
  },
  progressContainer: {
    padding: '24px',
  },

  addressContainer: {
    padding: '10px 15px',
    borderBottom: '2px solid#fff',
    background: '#f2f2f2',
  },
}));

export const Progress = ({ course }: ProgressInnerProps) => {
  const classes = useStyles();

  const contact = useMemo(() => {
    if (
      course &&
      course.contact &&
      course.contact.id &&
      course.provider &&
      course.provider!.contacts
    ) {
      return course.provider!.contacts.find(
        providerContact => providerContact.id === course.contact!.id,
      );
    }
    return null;
  }, [course]);

  return (
    <Paper className={classes.progressContainer}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={7}>
          {!course.enabled && (
            <Chip
              className={classes.chip}
              color="secondary"
              label="Kurs ist inaktiv"
            />
          )}
          <List disablePadding dense data-testid="progressList">
            <ListItem disableGutters>
              <ListItemText
                primary={`${niceFormat(
                  course.processes![0]!.createdAt,
                )} | Kurs angelegt`}
              />
            </ListItem>
            {course.processes![0]!.paidAt && (
              <ListItem disableGutters>
                <ListItemText
                  primary={`${niceFormat(
                    course.processes![0]!.paidAt,
                  )} | Kurs bezahlt`}
                />
              </ListItem>
            )}
            {course.processes![0]!.cycles!.map((cycle, index) => (
              <ProgressCycle
                key={cycle!.id}
                cycle={cycle!}
                round={`${index + 1}. Zertifizierungsrunde`}
              />
            ))}
            {course.processes![0]!.confirmedAt && (
              <ListItem disableGutters>
                <ListItemText
                  primary={`${niceFormat(
                    course.processes![0]!.confirmedAt,
                  )} | Kurs zertifiziert`}
                />
              </ListItem>
            )}
            {drop(1, course.processes!)!.map(
              process =>
                process && (
                  <React.Fragment key={process.id}>
                    <ListItem disableGutters>
                      <ListItemText
                        primary={`${niceFormat(
                          process.createdAt,
                        )} | Nachmeldung angelegt`}
                      />
                    </ListItem>
                    {process.paidAt && (
                      <ListItem disableGutters>
                        <ListItemText
                          primary={`${niceFormat(
                            process.paidAt,
                          )} | Nachmeldung bezahlt`}
                        />
                      </ListItem>
                    )}
                    {process.cycles &&
                      process.cycles!.map((cycle, cycleIndex) => (
                        <ProgressCycle
                          key={cycle!.id}
                          cycle={cycle!}
                          round={`${cycleIndex + 1}. Nachmeldungsrunde`}
                        />
                      ))}
                    {process.confirmedAt && (
                      <ListItem disableGutters>
                        <ListItemText
                          primary={`${niceFormat(
                            process.confirmedAt,
                          )} | Nachmeldung abgeschlossen`}
                        />
                      </ListItem>
                    )}
                  </React.Fragment>
                ),
            )}
            {course.processes![0]!.confirmedAt &&
              isAfter(
                new Date(),
                addYears(new Date(course.processes![0]!.confirmedAt), 3),
              ) && (
                <ListItem disableGutters>
                  <ListItemText
                    primary={`${niceFormat(
                      subDays(
                        addYears(
                          new Date(course.processes![0]!.confirmedAt),
                          3,
                        ),
                        1,
                      ).toISOString(),
                    )} | Kurs abgelaufen`}
                  />
                </ListItem>
              )}
          </List>
        </Grid>
        {course.provider && (
          <Grid item xs={12} md={5} data-testid="information">
            <div className={classes.addressContainer}>
              <Typography variant="h6">Adresse</Typography>
              <Typography>
                {course.provider.street} {course.provider.streetnumber}
                <br />
                {course.provider.zip} {course.provider.city}
                <br />
                {course.provider.homepage && (
                  <Link href={course.provider.homepage} target="_blank">
                    {course.provider.homepage}
                  </Link>
                )}
              </Typography>
            </div>
            {contact && (
              <div key={contact.id} className={classes.addressContainer}>
                <Typography variant="h6">Ansprechpartner</Typography>
                <Typography variant="body1">{formatToName(contact)}</Typography>
                {contact.phone && (
                  <Typography variant="body1">{contact.phone}</Typography>
                )}
                {contact.email && (
                  <Link variant="body1" href={contact.email}>
                    {contact.email}
                  </Link>
                )}
              </div>
            )}
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';

interface ConfirmDialogProps {
  title: string;
  content: string;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
}

export const ConfirmDialog = ({
  title,
  onConfirm,
  onCancel,
  content,
  open,
}: ConfirmDialogProps) => (
  <Dialog disableEscapeKeyDown maxWidth="xs" open={open}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{content}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onCancel()} color="primary">
        Abbrechen
      </Button>
      <Button variant="contained" onClick={() => onConfirm()} color="primary">
        Ok
      </Button>
    </DialogActions>
  </Dialog>
);

import { Fab, Grid, Paper, Tab, Tabs, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';

import { EditCourseProcessesProps } from '../types';
import { EditCourseCycles } from './EditCourseCycles';

const useStyles = makeStyles(theme => ({
  process: { padding: 20 },
  processGrid: { alignContent: 'flex-start' },
  tabContainer: { background: '#eee', display: 'flex' },
  tabLeft: { flex: 1 },
  tabRight: { alignSelf: 'center', marginRight: 4, marginLeft: 10 },
}));

export const EditCourseProcesses = ({
  courseData,
  formik,
  onCreateNewProcess,
  onCreateNewCycle,
}: EditCourseProcessesProps) => {
  const classes = useStyles();

  const [selectedProcess, selectProcess] = useState(
    courseData.processes!.length - 1,
  );
  const { values, handleChange, handleBlur } = formik;

  return (
    <Paper>
      <div className={classes.tabContainer}>
        <div className={classes.tabLeft}>
          <Tabs
            value={selectedProcess}
            indicatorColor="primary"
            textColor="primary"
            onChange={(event, newProcess) => selectProcess(newProcess)}
          >
            {courseData.processes!.map((process, index) => (
              <Tab
                key={process!.id}
                label={
                  process!.certification
                    ? 'Zertifizierung'
                    : `${index}. Nachmeldung`
                }
                data-testid="processTab"
              />
            ))}
          </Tabs>
        </div>
        <Fab
          data-testid="addProcess"
          className={classes.tabRight}
          onClick={() => onCreateNewProcess()}
          size="small"
          color="primary"
        >
          <AddIcon />
        </Fab>
      </div>
      {courseData.processes!.map(
        (process, processIndex) =>
          selectedProcess === processIndex &&
          process && (
            <Grid
              container={true}
              className={classes.process}
              spacing={3}
              key={process.id}
              data-testid="processContent"
            >
              <Grid
                item={true}
                container={true}
                xs={12}
                md={3}
                className={classes.processGrid}
                direction="column"
                wrap="nowrap"
              >
                <TextField
                  id={`form-paidAt_${processIndex}`}
                  margin="normal"
                  name={`process.processes.${processIndex}.paidAt`}
                  value={values.process.processes[processIndex].paidAt || ''}
                  type="date"
                  label={
                    process.certification
                      ? 'Zertifizierung bezahlt'
                      : 'Nachmeldung bezahlt'
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <TextField
                  id={`form-confirmedAt_${processIndex}`}
                  margin="normal"
                  name={`process.processes.${processIndex}.confirmedAt`}
                  value={
                    values.process.processes[processIndex].confirmedAt || ''
                  }
                  type="date"
                  label={
                    process.certification
                      ? 'Zertifizierung abgeschlossen'
                      : 'Nachmeldung abgeschlossen'
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item={true} xs={12} md={9}>
                <EditCourseCycles
                  processIndex={selectedProcess}
                  onCreateNewCycle={onCreateNewCycle}
                  formik={formik}
                  courseData={courseData}
                />
              </Grid>
            </Grid>
          ),
      )}
    </Paper>
  );
};

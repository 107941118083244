import * as Yup from 'yup';
import { commentRequired, mandatory } from '../../../utils/formatter';

export const CreateCourseFormSchema = Yup.object().shape({
  provider: Yup.string().required(mandatory),
  category: Yup.string().required(mandatory),
});

export const EvaluateCourseFormSchema = Yup.object().shape({
  comment: Yup.string().when('score', {
    is: -1,
    then: schema => schema.required(commentRequired),
    otherwise: schema => schema,
  }),
});
